import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useBookingClient } from "hooks/useHttpClient";
import {
  BookingIdentifierwithEmail,
  BookingItemModel,
  SendConfirmTicketResponse,
} from "WebApiClient";
import { toast } from "react-toastify";

//mui
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";

interface ImportBookingModalProps {
  Booking: BookingItemModel | any;
  Toggle: () => void;
  IsOpen: boolean;
  IsPopUp: boolean;
  // data: BaseApiResponseMetaData | undefined
}

const ConfirmDetailSendModal: React.FC<ImportBookingModalProps> = (props) => {
  const bookingClient = useBookingClient();
  const data = props.Booking.latestVersion!;
  const [multiEmail, setMultiEmail] = useState<any>([]);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const [checkedItems2, setCheckedItems2] = useState<any>({});
  const [checkedItems3, setCheckedItems3] = useState<any>({});
  const [checkedItems4, setCheckedItems4] = useState<any>({});
  const [selectedEmails, setSelectedEmails] = useState<string>("");
  const [selectedEmails2, setSelectedEmails2] = useState<string>("");
  const [selectedEmails3, setSelectedEmails3] = useState<string>("");
  const [EmailTemplateName, setEmailTemplateName] = useState<string>("");
  const [textAreaDesc, setTextAreaDesc] = useState<string>("");

  useEffect(() => {
    if (data != undefined) {
      getEmailAddress(props.Booking?.latestVersion?.flightDetailsInfo?.enhancedRemarks ?? []);
    } else {
      getEmailAddress(props.Booking.bookings[0]?.bookingResponse?.pnr?.enhancedRemarks ?? [])
    }

  }, [props.IsOpen]);

  useEffect(() => {
    setCheckedItems({});
    setCheckedItems2({});
    setCheckedItems3({});
    setSelectedEmails2("");
    setSelectedEmails("");
    setSelectedEmails3("");
  }, []);

  function getEmailAddress(array: { remark: string }[]) {
    const emailRegex = /\S+@\S+\.\S+/; // A simple email regex pattern
    let foundEmails: { [key: string]: string }[] = [];

    // Utility function to extract emails from remarks
    const extractEmailsFromRemarks = (item: { remark: string }) => {
      const words = item.remark;
      // Check for type codes and extract only the email from remark if present
      if ((words.includes("EM") || words.includes("HE")) && item.remark) {
        const emailMatch = item.remark.match(emailRegex); // Extract email using regex
        if (emailMatch) {
          // Add extracted email based on the type code
          foundEmails.push({ [words]: emailMatch[0] });
        }
      }
    };

    // Extract emails from the initial array
    array.forEach(extractEmailsFromRemarks);

    // Helper function to add emergency contact emails
    const addEmergencyEmails = (phoneNumbers: { number: string }[]) => {
      phoneNumbers.forEach((phoneNumber) => {
        const emergencyContactEmail: string = phoneNumber.number;
        if (emergencyContactEmail?.includes("@")) {
          foundEmails.push({ emergencyContact: emergencyContactEmail });
        }
      });
    };

    // Check if there are invoice phone numbers available
    const { Booking } = props;
    const latestVersion = Booking?.latestVersion;

    if (latestVersion?.flightDetailsInfo?.invoice?.phoneNumbers?.length) {
      const invoicePhoneNumbers = latestVersion.flightDetailsInfo.invoice.phoneNumbers;
      addEmergencyEmails(invoicePhoneNumbers);

      const deliveryPhoneNumbers = latestVersion.flightDetailsInfo.delivery?.phoneNumbers || [];
      addEmergencyEmails(deliveryPhoneNumbers);
    } else if (Array.isArray(Booking?.bookings) && Booking.bookings.length > 0 && Booking.bookings[0]?.bookingResponse?.pnr?.contacts?.length) {
      const contactNumbers = Booking.bookings[0].bookingResponse.pnr.contacts;
      addEmergencyEmails(contactNumbers);
    }

    // Create a Set to store seen values and filter duplicates
    const uniqueValues: { [key: string]: boolean } = {};
    const uniqueArray = foundEmails.filter((obj) => {
      const value = Object.values(obj)[0]; // Get the value of the object
      if (!uniqueValues[value]) {
        uniqueValues[value] = true; // Mark the value as encountered
        return true; // Include the object in the result
      }
      return false; // Exclude the object from the result
    });

    // Set the multi-email array
    setMultiEmail(uniqueArray);
    // Return the filtered emails (if any)
    return foundEmails.length > 0 ? foundEmails : null;
  }

  //TO email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray = Object.keys(checkedItems).filter(
      (email) => checkedItems[email]
    );
    setSelectedEmails(selectedEmailArray.join(";"));
  }, [checkedItems]);

  const handleCheckboxChange = (email: any) => {
    // setCheckedItems2(removeKeyFromObject(checkedItems2, email));
    // setCheckedItems3(removeKeyFromObject(checkedItems3, email));
    setCheckedItems({
      ...checkedItems,
      [email]: !checkedItems[email],
    });
  };
  //cc email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray2 = Object.keys(checkedItems2).filter(
      (email) => checkedItems2[email]
    );
    setSelectedEmails2(selectedEmailArray2.join(";"));
  }, [checkedItems2]);

  const handleCheckboxChange2 = (email: any) => {
    setCheckedItems2((prevCheckedItems2: any) => {
      // If the email is already present, remove it; otherwise, add it
      if (prevCheckedItems2[email]) {
        const { [email]: _, ...rest } = prevCheckedItems2;
        return rest;
      } else {
        return {
          ...prevCheckedItems2,
          [email]: true,
        };
      }
    });
  };

  //Bcc email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray3 = Object.keys(checkedItems3).filter((email) => checkedItems3[email]);
    setSelectedEmails3(selectedEmailArray3.join(";"));
  }, [checkedItems3]);

  const handleCheckboxChange3 = (email: any) => {
    setCheckedItems3({
      ...checkedItems3,
      [email]: !checkedItems3[email],
    });
  };
  const handleCheckboxChange4 = (email: any) => {
    setCheckedItems4({
      ...checkedItems4,
      [email]: !checkedItems4[email],
    });
  };

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  let fieldContainer: string[];

  if (settingArr[7]) {
    try {
      fieldContainer = JSON.parse(settingArr[7])
        .Container.split("#")
        .filter((item: any) => item !== "");
    } catch (error) {
      // Provide a default value or error indicator
      fieldContainer = [];
    }
  } else {
    // Provide a default value or error indicator
    fieldContainer = [];
  }

  useEffect(() => {
    try {
      const isTemplate = fieldContainer.find((item) =>
        item.includes("email.html")
      );
      if (isTemplate) {
        const regex = /=(.*)/;
        const templateData = isTemplate.match(regex);
        if (templateData && templateData[1]) {
          let template = templateData[1];
          setEmailTemplateName(template);
        } else {
          setEmailTemplateName(isTemplate);
        }
      }
    } catch (error) {
      setEmailTemplateName(""); // Handle parsing error
    }
  }, [fieldContainer]);

  async function sendConfirmTicket() {
    let ToMail = Object.entries(checkedItems)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let CcMail = Object.entries(checkedItems2)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let BccMail = Object.entries(checkedItems3)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let replay = Array.from(new Set(replayAddress.filter(email => email)));
    console.log("replay----", replay);

    let finalMail = {
      To: ToMail.length > 0 ? ToMail : null,
      Cc: CcMail.length > 0 ? CcMail : null,
      Bcc: BccMail.length > 0 ? BccMail : null,
      replayAddress: replay.length > 0 ? replay : null,
      default: defaultAddress
    };

    let Description = textAreaDesc.length > 0 ? textAreaDesc : null;
    if (finalMail.Bcc == null && finalMail.To == null && finalMail.Cc == null) {
      toast.error("Please add at least one email.");
    } else {
      try {
        // const jsonString: any = await ConvertXMLToJSON();
        // console.log("json", jsonString);
        var Ids: any;
        if (data?.bookingIdentifier != undefined) {
          Ids = data.bookingIdentifier;
        } else {
          Ids = {
            cartId: props.Booking.shoppingCartID,
            bookingId: props.Booking.bookings[0].bookingResponse.bookingItemID,
          };
        }

        const Obj: BookingIdentifierwithEmail = {
          cartId: Ids.cartId,
          bookingId: Ids.bookingId,
          emailIds: finalMail,
          Description: Description,
          TemplateName: EmailTemplateName,
        };

        const res: string | any = await bookingClient.SendConfirmTicket(Obj);
        const response: SendConfirmTicketResponse = JSON.parse(res)
        if (response.status) {
          toast.success("Email successfully sent");
        } else {
          toast.error("An error occurred while sending the confirmation email.");
        }
        setCheckedItems({});
        setCheckedItems2({});
        setCheckedItems3({});
        setSelectedEmails2("");
        setSelectedEmails("");
        setSelectedEmails3("");
        props.Toggle();
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while sending the confirmation email.");
        props.Toggle();
      }
    }
  }

  //if no email found then u can add one
  const [customEmail, setCustomEmail] = useState<string>("");
  const [checkDuplicate, setCheckDuplicate] = useState<string>("0");
  const [replayAddress, setReplayAddress] = useState<string[]>([]);
  const [defaultAddress, setDefaultAddress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleCustomEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomEmail(e.target.value);
    setCheckDuplicate("0");
  };

  const addNewMail = () => {
    let newMailObj: any = {};
    if (customEmail === "") {
      setCheckDuplicate("2");
    } else {
      newMailObj = {
        [`NewMail${multiEmail?.length + 1}`]: customEmail,
      };
      // Check if the searchString is present in any of the object properties
      const isStringInArray = multiEmail.some((obj: any) => {
        for (const key in obj) {
          if (obj[key] === customEmail) {
            return true; // If the string is found, exit the loop and return true
          }
        }
        return false;
      });
      if (!isStringInArray) {
        setMultiEmail([...multiEmail, newMailObj]);
        setCustomEmail("");
      } else {
        setCheckDuplicate("1");
      }
    }
  };

  //remove mail from list
  function removeMailFromList(email: string) {
    const filteredArray = multiEmail.filter((obj: any) => {
      let keepObject = true;
      for (const key in obj) {
        if (obj[key] == email) {
          keepObject = false;
          break;
        }
      }

      return keepObject;
    });
    setMultiEmail(filteredArray);
  }

  //textArea
  const handleTextAreaChange = (e: any) => {
    setTextAreaDesc(e.target.value);
  };

  const handleAddEmails = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // Basic email validation regex

    const newEmails = e.target.value
      .split(",")
      .map(email => email.trim())
      .filter(email => email); // Split, trim, and filter out empty values

    // Separate valid emails and invalid ones
    const validEmails = newEmails.filter(email => emailRegex.test(email));
    const invalidEmails = newEmails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setError(`Invalid emails: ${invalidEmails.join(", ")}`); // Show error for invalid emails
    } else {
      setError(null); // Clear error if all emails are valid
    }

    // Filter out duplicates in valid emails
    const uniqueEmails = validEmails.filter(email => !replayAddress.includes(email));

    setReplayAddress([...replayAddress, ...uniqueEmails]); // Add only unique and valid emails to array
    e.target.value = ""; // Clear the textarea after adding
  };


  return (
    <>
      {!props.IsPopUp ? (
        <Modal size="lg" centered toggle={props.Toggle} isOpen={props.IsOpen}>
          <ModalHeader toggle={props.Toggle}>
            Send confirm details for{" "}
            <strong>{props.Booking.latestVersion.generalInfo.filekey}</strong>
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <input
                    value={customEmail}
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Enter a valid email address"
                    onChange={(e) => handleCustomEmailChange(e)}
                    autoComplete="off"
                    style={{
                      width: "250px",
                      marginRight: "8px",
                      height: "32px",
                    }}
                  />
                  {checkDuplicate == "1" && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Mail is already present
                    </p>
                  )}
                  {checkDuplicate == "2" && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Please enter the valid mail
                    </p>
                  )}
                </div>
                <Button
                  color="primary"
                  onClick={addNewMail}
                  style={{
                    height: "34px",
                    paddingBottom: "2px",
                    paddingTop: "2px",
                  }}>
                  +
                </Button>
              </div>
              <div style={{ marginLeft: "60px", width: "40%" }}>
                <TextareaAutosize
                  onChange={(e) => handleTextAreaChange(e)}
                  style={{ width: "100%" }}
                  aria-label="empty textarea"
                  minRows={3}
                  maxRows={4}
                  placeholder="Enter text here"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row my-4 p-4">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}> Mail</th>
                    <th>To</th>
                    <th>Cc</th>
                    <th>Bcc</th>
                    {/* <th style={{ width: "15%" }}>Reply To</th> */}
                  </tr>
                </thead>
                <tbody>
                  {multiEmail?.map((data: any, index: any) => {
                    const email: any = Object.values(data)[0]; // Get the email address
                    const HE = Object.keys(data)[0];
                    return (
                      <tr key={index} style={{ height: "40px" }}>
                        <td>{email as string}</td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems[email]}
                              onChange={() => handleCheckboxChange(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems2[email]}
                              onChange={() => handleCheckboxChange2(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems3[email]}
                              onChange={() => handleCheckboxChange3(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        {/* <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems4[email]}
                              onChange={() => handleCheckboxChange4(email)}
                              name={email}
                            />
                          </label>
                        </td> */}
                        {Object.keys(data)[0].startsWith("NewMail") && (
                          <td>
                            <Button
                              onClick={() => removeMailFromList(email)}
                              style={{
                                padding: "0",
                                borderRadius: "50%",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderColor: "#fff",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="sm"
                                style={{
                                  color: "red",
                                  height: "24px",
                                }}
                              />
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="text-start mx-3">
              <strong>Reply address</strong>
              <div className="input-group mb-3 w-50">
                <textarea
                  className="form-control"
                  id="replyEmail"
                  placeholder="Enter multiple emails separated by commas"
                  aria-describedby="basic-addon1"
                  onBlur={handleAddEmails} // Update onBlur to save changes when the user leaves
                  value={replayAddress.join(", ")} // Display emails as a comma-separated list
                  onChange={(e) => setReplayAddress(e.target.value.split(",").map(email => email.trim()))} // Keep textarea in sync
                />
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>} {/* Display error message */}
            </div>
            <div
              style={{ paddingRight: "60px" }}
              className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                type="button"
                onClick={sendConfirmTicket}>
                Send
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div>
                <input
                  value={customEmail}
                  type="text"
                  name="email"
                  placeholder="Enter a valid email address"
                  onChange={(e) => handleCustomEmailChange(e)}
                  autoComplete="off"
                  style={{
                    width: "250px",
                    marginRight: "8px",
                    height: "32px",
                  }}
                />
                {checkDuplicate == "1" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Mail is already present
                  </p>
                )}
                {checkDuplicate == "2" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Please enter the valid mail
                  </p>
                )}
              </div>
              <Button
                color="primary"
                onClick={addNewMail}
                style={{
                  height: "34px",
                  paddingBottom: "2px",
                  paddingTop: "2px",
                }}
              >
                +
              </Button>
            </div>
            <div style={{ marginLeft: "60px", width: "40%" }}>
              <TextareaAutosize
                onChange={(e) => handleTextAreaChange(e)}
                style={{ width: "100%" }}
                aria-label="empty textarea"
                minRows={3}
                maxRows={4}
                placeholder="Enter text here"
              />
            </div>
          </div>
          <div className="row my-4 p-4">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "50%" }} >Mail</th>
                  <th>To</th>
                  <th>Cc</th>
                  <th>Bcc</th>
                  <th style={{ width: "15%" }}>Reply To</th>
                </tr>
              </thead>
              <tbody>
                {multiEmail?.map((data: any, index: any) => {
                  const email: any = Object.values(data)[0]; // Get the email address
                  const HE = Object.keys(data)[0];
                  return (
                    <tr key={index} style={{ height: "40px" }}>
                      <td>{email as string}</td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems[email]}
                            onChange={() => handleCheckboxChange(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems2[email]}
                            onChange={() => handleCheckboxChange2(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems3[email]}
                            onChange={() => handleCheckboxChange3(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems4[email]}
                            onChange={() => handleCheckboxChange4(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      {Object.keys(data)[0].startsWith("NewMail") && (
                        <td>
                          <Button
                            onClick={() => removeMailFromList(email)}
                            style={{
                              padding: "0",
                              borderRadius: "50%",
                              backgroundColor: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderColor: "#fff",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              size="sm"
                              style={{
                                color: "red",
                                height: "24px",
                              }}
                            />
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            style={{ paddingRight: "60px" }}
            className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={sendConfirmTicket}>
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmDetailSendModal;
