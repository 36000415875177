import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React, { useState } from "react";
import { PnrResponseStoredFareModel } from "WebApiClient";

export const PnrStoredFaresCard: React.FC<{ data: PnrResponseStoredFareModel[] }> = ({ data }) => {

    const TabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: data.map((e, i) => {
            return {
                Component: <StoredFareItem data={e} key={"storedI" + i} storedFareInde={i} />,
                Title: `#${i + 1}`,
                Icon: faInfoCircle,
                AmountIndicator: undefined
            }
        })

    }

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Stored fares <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <TabbedContent {...TabContent} componentName="PNRStoredFares"/>

                {/* <div className="row">
                    {data.map((storedFare, index) =>
                        <div className="col-12" key={index}>
                            <StoredFareItem data={storedFare} storedFareInde={index} />
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );


}

const StoredFareItem: React.FC<{ data: PnrResponseStoredFareModel, storedFareInde: number }> = ({ data, storedFareInde }) => {
    const { fareDetails,
        lastTicketingDate,
        lastTicketingDateSpecified,
        number,
        numberSpecified,
        passengerNumber,
        status,
        storedFareCreationDate,
        storedFareCreationDateSpecified,
        taxes, ticketingDetails, ticketingSegments } = data;

    return (
        <div className="card">
            {/* <div className="card-header">Stored fare #{storedFareInde + 1}</div> */}
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="card">
                            <div className="card-header card-header-primary">General</div>
                            <div className="card-body">
                                <table className="table table-sm table-striped table-hover table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Status</td>
                                            <td>{status}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Creation date</td>
                                            <td>{storedFareCreationDate ? <DateTimeDisplay date={storedFareCreationDate} /> : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">TTL</td>
                                            <td>{lastTicketingDate ? <DateTimeDisplay date={lastTicketingDate} /> : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Number</td>
                                            <td>{numberSpecified ? number.toString() : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Passenger number</td>
                                            <td>{passengerNumber.toString()}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    {fareDetails &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Details</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">Issue ID</td>
                                                <td>{fareDetails.fareIssueID}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Base fare</td>
                                                <td><SharedPriceDisplay price={fareDetails.baseFare} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Equiv. Base fare</td>
                                                <td><SharedPriceDisplay price={fareDetails.equivalentBaseFare} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Grand total</td>
                                                <td><SharedPriceDisplay price={fareDetails.grandTotal} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Total amount</td>
                                                <td><SharedPriceDisplay price={fareDetails.totalAmount} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Total fee</td>
                                                <td><SharedPriceDisplay price={fareDetails.totalFee} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Total tax</td>
                                                <td><SharedPriceDisplay price={fareDetails.totalTax} /></td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Calculation</td>
                                                <td>{fareDetails.fareCalculation}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {taxes.length > 0 &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Taxes</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Action</th>
                                                <th className="font-weight-bold">Price</th>
                                                <th className="font-weight-bold">Status</th>
                                                <th className="font-weight-bold">Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taxes.map((tax, index) =>
                                                <tr key={"taxI_"+index}>
                                                    <td>{tax.action}</td>
                                                    <td><SharedPriceDisplay price={tax.price} /></td>
                                                    <td>{tax.status}</td>
                                                    <td>{tax.type}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {ticketingSegments.length > 0 &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Ticketing segments</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Designator</th>
                                                <th className="font-weight-bold">Farebase</th>
                                                <th className="font-weight-bold">Free baggage allowance</th>
                                                <th className="font-weight-bold">Number</th>
                                                <th className="font-weight-bold">NVA</th>
                                                <th className="font-weight-bold">NVB</th>
                                                <th className="font-weight-bold">Orig. number</th>
                                                <th className="font-weight-bold">Stop indicator</th>
                                                <th className="font-weight-bold">Ticket status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketingSegments.map((tickSeg, index) =>
                                                <tr key={"ticktSeg_"+index}>
                                                    <td>{tickSeg.designator}</td>
                                                    <td>{tickSeg.fareBase}</td>
                                                    <td>{tickSeg.freeBaggageAllowance}</td>
                                                    <td>{tickSeg.numberSpecified ? tickSeg.number.toString() : ""}</td>
                                                    <td>{tickSeg.nva}</td>
                                                    <td>{tickSeg.nvb}</td>
                                                    <td>{tickSeg.originalNumberSpecified ? tickSeg.originalNumber.toString() : ""}</td>
                                                    <td>{tickSeg.stopIndicator}</td>
                                                    <td>{tickSeg.ticketStatus}</td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {ticketingDetails &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Ticketing details</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">Commission</td>
                                                <td>{ticketingDetails.commission}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Endorsement</td>
                                                <td>{ticketingDetails.endorsment}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Form of payment</td>
                                                <td>{ticketingDetails.formOfPayment}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Orig. issued  details</td>
                                                <td>{ticketingDetails.originalIssuedDetails}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Tour code</td>
                                                <td>{ticketingDetails.tourcode}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">Validating carrier</td>
                                                <td>{ticketingDetails.validatingCarrier}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    );


}


