import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import { PriceDisplay } from "components/Shared/PriceDisplay";
import React, { useEffect, useRef, useState } from "react";
import { Col, Popover, PopoverBody, Row } from "reactstrap";
import { Airline, AvailableFareModel, FareHeaderView } from "WebApiClient";
import { ServiceAddObj } from "./AncillariesModal";
import { CabinClasses } from "./Fare.Header.CabinClasses";
import FareHeaderIncludedServices from "./Fare.Header.IncludedServices";
import { GetTotalFarePrice } from "./FarePriceOverview";
interface HeaderFareProps {
  OriginalFareIndex: number;
  Data: FareHeaderView;
  PlatingCarriers: Airline[];
  ToggleFare: (fareIndexSingle:number,legBoolString:string) => void;
  IsExpanded: boolean;
  TotalPrice: number;
  TotalTax: number;
  Currency: string;
  Services: ServiceAddObj[];
  myRefs?: any;
  FareIndex?: any;
  fareData: AvailableFareModel;
}

const FareHeader: React.FC<HeaderFareProps> = (props) => {
  const platingCarriersAmount: number = props.PlatingCarriers.length;
  const showPlatingCarriers: boolean = platingCarriersAmount < 2 || !props.IsExpanded;
  const allServices = props.Services.flat();
  const servicesAmount = allServices.length;
  const [isWL, setWL] = useState<boolean>(false);

  useEffect(() => {
    props.fareData.legs.map((leg) => {
      leg.connections.map((connection) => {
        connection.segments.map((segment) => {
          if (segment.freeSeats == 0) {
            setWL(true);
            return true;
          }
        });
      });
    });
  }, []);

  const elemRef = useRef<HTMLImageElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="row bb-1 fareRow" onClick={()=>props.ToggleFare(props.FareIndex,"showLeg")}>
      {props.myRefs?.current ? (
        <div
          ref={(el) => (props.myRefs.current[props.FareIndex] = el)}
          className="col-xs-12 col-md-4 fare__carrier_price toggleFareDetails"
        >
          <div className="price">
            <div className="d-flex justify-content-between ">
              <PriceDisplay
                price={GetTotalFarePrice(props.TotalPrice, props.Services)}
                currency={props.Currency}
              />
              <Popover
                target={elemRef}
                isOpen={isHovered} // Popover is open when isHovered is true
                toggle={() => setIsHovered(!isHovered)}
                className="operatedByCss"
              >
                <PopoverBody>This flight is under Waitlist</PopoverBody>
              </Popover>
              {/* Render span only if isWL is false */}
              {isWL && (
                <span
                  ref={elemRef}
                  className="WL cursor-pointer"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  WL
                </span>
              )}
              <span
                className="float-end text-muted"
                style={{ fontSize: "0.5em" }}
              >
                #{props.OriginalFareIndex + 1}
              </span>
            </div>
          </div>

          <div className="tax">
            Total price incl.{" "}
            <span className="text-nowrap">
              <PriceDisplay price={props.TotalTax} currency={props.Currency} />{" "}
              Taxes
            </span>
          </div>
          {servicesAmount > 0 && (
            <div className="border">
              {" "}
              +{servicesAmount.toString()} <FontAwesomeIcon icon={faStar} />
              <span className="float-end"></span>
            </div>
          )}
        </div>
      ) : (
        <div className="col-xs-12 col-md-4 fare__carrier_price toggleFareDetails">
          <div className="price">
            <>
              <PriceDisplay
                price={GetTotalFarePrice(props.TotalPrice, props.Services)}
                currency={props.Currency}
              />
            </>
            <span
              className="float-end text-muted"
              style={{ fontSize: "0.5em" }}
            >
              #{props.OriginalFareIndex + 1}
            </span>
          </div>
          <div className="tax">
            Total price incl.{" "}
            <span className="text-nowrap">
              <PriceDisplay price={props.TotalTax} currency={props.Currency} />{" "}
              Taxes
            </span>
          </div>
          {servicesAmount > 0 && (
            <div className="border">
              {" "}
              +{servicesAmount.toString()} <FontAwesomeIcon icon={faStar} />
              <span className="float-end"></span>
            </div>
          )}
        </div>
      )}
      <div className="col-xs-12 col-md-3 fare__carrier_class text-left mr-0">
        <Row>
          <Col xs="auto" className="ml-0 pl-0">
            {/* <CabinClasses CabinClasses={props.Data.cabinClassCombined!} /> */}
            <CabinClasses
              CabinClasses={props.Data.cabinClassCombined!}
              BrandName={props.fareData.legs}
            />
            <FareHeaderIncludedServices
              Data={props.Data}
              FareIndex={props.OriginalFareIndex}
            />
          </Col>
          {/* <Col className="toggleFareDetails" onClick={props.ToggleFare}>
                    </Col> */}
        </Row>
      </div>
      <div className="col-xs-12 col-md-5 text-end toggleFareDetails">
        <div className="fairDetails">
          {showPlatingCarriers &&
            props.PlatingCarriers.map((airline, index) => (
              <AirlineLogo
                IsAlliance={false}
                className="col-2 fare__carrier_logo align-self-center"
                PlatingCarrier={airline.code!}
                PlatingCarrierName={airline.hint!}
                key={`fd_f${props.OriginalFareIndex}_airHeader_${index}`}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FareHeader;