import { faClock, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay } from "components/Shared/DateDisplay";
import { TariffTypeEnumDisplay } from "components/Shared/TariffTypeEnumDisplay";
import React, { useEffect, useState } from "react";
import {
  AvailableFareConnectionModel,
  AvailableFareLegModel,
  FareInfoBoxView,
  TariffTypeEnum,
  TravelTimeView,
} from "WebApiClient";
import { FareInfoBoxModal } from "./Fare.InfoBoxModal";
import _ from "lodash";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface FareInfoBoxProps {
  Info: FareInfoBoxView;
  ShowDetails: boolean;
  ToggleFare: (fareIndexSingle: number, legBoolString: string) => void;
  FareIndex: number;
  SelectedConnections: number[];
  Legs: AvailableFareLegModel[];
  Status: boolean;
}

const FareInfoBox: React.FC<FareInfoBoxProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const TotalFlightTime: TravelTimeView = GetTotalFlightTime();
  const TotalTravelStops: number = GetTotalTravelStops();
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  let salesPreferenceDisplayNameCode: string | undefined = "";
  let salesPreferenceDisplayNameHtml: string | undefined = "";

  const ToggleExpiredModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation(); // Stops the click event from propagating
    }
    setExpiredModalOpen(!expiredModalOpen); // Toggle modal visibility
  };



  // function GetTotalFlightTime(): TravelTimeView {
  //   let result: TravelTimeView = {
  //     minutes: 0,
  //     hours: 0,
  //     addHours: 0,
  //     daysStayOver: 0,
  //   };

  //   props.SelectedConnections.forEach((c, i) => {
  //     let legTime: TravelTimeView | undefined;
  //     if (props.Status) {
  //       legTime = props.Legs[i]?.connections.find(
  //         (connection: AvailableFareConnectionModel) => connection.index === c
  //       )?.connectionHeader?.legTravelTime;
  //     } else {
  //       legTime =
  //         props.Legs[i]?.connections[c]?.connectionHeader?.legTravelTime;
  //     }

  //     if (legTime) {
  //       result.minutes += legTime.minutes;
  //       result.hours += legTime.hours;
  //       result.addHours += legTime.addHours;
  //       result.daysStayOver += legTime.daysStayOver;
  //     }
  //   });

  //   // Adjust hours if minutes exceed 60
  //   if (result.minutes >= 60) {
  //     const additionalHours = Math.floor(result.minutes / 60);
  //     result.hours += additionalHours;
  //     result.minutes %= 60; // Keep the remainder as minutes
  //   }

  //   // Adjust daysStayOver if hours exceed 24
  //   if (result.hours >= 24) {
  //     result.daysStayOver += Math.floor(result.hours / 24);
  //     result.hours %= 24; // Keep the remainder as hours
  //   }
  //   return result;
  // }

  function GetTotalFlightTime(): TravelTimeView {
    let result: TravelTimeView = {
      daysStayOver: 0, // No recalculation, just keeps original format
      minutes: 0,
      addHours: 0,
      hours: 0,
    };

    props.SelectedConnections.forEach((c, i) => {
      let legTime: TravelTimeView | undefined;
      if (props.Status) {
        legTime = props.Legs[i]?.connections.find(
          (connection: AvailableFareConnectionModel) => connection.index === c
        )?.connectionHeader?.legTravelTime;
      } else {
        legTime =
          props.Legs[i]?.connections[c]?.connectionHeader?.legTravelTime;
      }
      console.log(legTime);
      if (legTime) {
        result.minutes += legTime.minutes;
        result.addHours += legTime.addHours; // Already includes rolled-over hours
      }
    });

    // Adjust minutes to addHours and roll over
    if (result.minutes >= 60) {
      result.addHours += Math.floor(result.minutes / 60);
      result.minutes %= 60;
    }

    // Compute hours and daysStayOver
    result.daysStayOver = Math.floor(result.addHours / 24);
    result.hours = result.addHours % 24;

    return result;
  }






  useEffect(() => {
    GetTotalFlightTime();
  }, [props.SelectedConnections, props.Status]);

  function GetTotalTravelStops(): number {
    let result: number = 0;
    props.SelectedConnections.forEach((c, i) => {
      const legstops: number | undefined =
        props.Legs[i]?.connections[c]?.connectionHeader?.travelStopps;
      if (legstops) {
        result += legstops;
      }
    });

    return result;
  }

  function GetTariffTypes(): TariffTypeEnum[] {
    let result: TariffTypeEnum[] = [];
    props.Legs.forEach((l) => {
      const tarifftype = l.legFareInfo?.tariffType;
      if (tarifftype) {
        if (!result.some((e) => e === tarifftype)) {
          if (tarifftype !== TariffTypeEnum.None) {
            result.push(tarifftype);
          }
        }
      }
    });
    return result;
  }

  function GetFareTypes(): string[] {
    let result: string[] = [];
    props.Legs.forEach((l) => {
      const ft = l.fareTypeInformation.salesPreferenceDisplayName
        ? l.fareTypeInformation.salesPreferenceDisplayName
        : l.fareTypeInformation.originalFareType;

      // Call splitContent after retrieving the fare type
      splitContent(ft);
      // Add to the result array if it's not already present
      if (!result.includes(ft)) {
        result.push(ft);
      }
    });
    return result;
  }

  const fareTypes = GetFareTypes();
  const tariffTypes = GetTariffTypes();
  function splitContent(str: string | undefined) {
    if (str) {
      // Check if the string contains a pipe "|"
      if (str.includes('|')) {
        let [leftContent, rightContent] = str.split('|');
        salesPreferenceDisplayNameHtml = leftContent;
        salesPreferenceDisplayNameCode = rightContent;
      } else {
        // If no pipe is found, assign the whole string to salesPreferenceDisplayNameCode
        salesPreferenceDisplayNameCode = str;
      }
    }
  }

  return (
    <div className="col-12 px-0 px-md-2 col-md-3" onClick={() => props.ToggleFare(props.FareIndex, "showLeg")}>
      <div className={`fare__single_fare_infobox bg-white mb-2 ${props.ShowDetails ? "boxShadow-light mb-md-3" : ""
        }`}
      >
        <div className="row">
          <div className="col-12 mb-2 mt-2 mt-md-0">
            <span
              style={{ color: "black" }}
              className="float-end salespref badge clickme"
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(!modalOpen);
              }}
            >
              <span>
                {props.Info.salesPreferencePriority !== null &&
                  props.Info.salesPreferencePriority !== undefined &&
                  props.Info.salesPreferencePriority !== 0 && (
                    <span>{props.Info.salesPreferencePriority} </span>
                  )}
                <span>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </span>
            </span>

            <div className="">
              {" "}
              <FontAwesomeIcon icon={faClock} /> {TotalFlightTime.addHours}h{" "}
              {TotalFlightTime.minutes}m
            </div>
            <div className="infobox_value">
              <span className="infobox_item"> FARE TYPE:</span>{" "}
              {/* {fareTypes.join(",")} */}
              <span className="outerSpan" dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} onClick={() => ToggleExpiredModal} />

              {/* Conditionally render the modal */}
              {salesPreferenceDisplayNameHtml?.length > 0 && (
                <Modal
                  className="confirmationError"
                  centered
                  isOpen={expiredModalOpen}
                  toggle={ToggleExpiredModal} // No need to pass event here
                >
                  <ModalHeader toggle={ToggleExpiredModal}>
                    {/* Render salesPreferenceDisplayNameCode as HTML */}
                    <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                  </ModalHeader>
                  <ModalBody>
                    {/* Render salesPreferenceDisplayNameHtml as HTML */}
                    <div dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameHtml }} />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        ToggleExpiredModal(e);
                      }}
                      color="primary"
                    >
                      Ok
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

              {/* Render tariff types */}
              {tariffTypes.length > 0 && tariffTypes.map((e) => TariffTypeEnumDisplay(e)).join(",")}
            </div>
          </div>
          {props.ShowDetails && (
            <React.Fragment>
              <div className="col-12 mb-2">
                <div className="infobox_item">QUERY | BOOKING GDS</div>
                <div className="infobox_value">
                  {props.Info.queryGDS_BookingGDSDisplayResult}
                </div>
              </div>
              <div className="col-12">
                <div className="infobox_item">QUERY | BOOKING PCC</div>
                <div className="infobox_value">
                  {props.Info.queryPccs?.join(",")} |{" "}
                  {props.Info.bookingPccs?.join(",")}
                </div>
              </div>
              <div className="col-12">
                <div className="infobox_item">LAST TICKETING DATE:</div>
                <div className="infobox_value">
                  <DateDisplay date={props.Info.lastTicketingDate} />
                </div>
              </div>
              <div className="col-12">
                <div className="infobox_item">Source:</div>
                <div className="infobox_value">
                  {props.Info.sources?.join(",")}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {modalOpen && (
        <FareInfoBoxModal
          IsOpen={modalOpen}
          Toggle={() => {
            setModalOpen(!modalOpen);
          }}
          Info={props.Info}
          Legs={props.Legs}
          SelectedConnections={props.SelectedConnections}
        ></FareInfoBoxModal>
      )}


    </div>


  );
};
export default FareInfoBox;
