import _ from "lodash";
import React from "react";

interface CabinclassProps {
    readonly CabinClasses: string[];
    BrandName: any;
}
export const CabinClasses: React.FC<CabinclassProps> = props => {
    const uniqueBrandDetails = _.chain(props.BrandName).flatMap("connections").flatMap("segments").map(({ brandName, brandCode }) => ({ brandName, brandCode })).uniqBy("brandName").value();
    const output = _.map(uniqueBrandDetails, (brand: any) => ({
        brandName: _.toUpper(brand.brandName),
        brandCode: _.toUpper(brand.brandCode)
    }));


    return (
        <>
            {
                <>
                    {
                        output.map((brand: any, index: number) => (
                            <span
                                key={index} // Add a unique key prop for each element
                                style={{
                                    color: "#fff",
                                    backgroundColor: "#eabd18",
                                    padding: "0px 6px",
                                    textAlign: "left",
                                    marginBottom: "1px",
                                    width: "fit-content",
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: ".8rem",
                                }}>
                                {brand.brandCode !== ""
                                    ? brand.brandName.trim().includes(brand.brandCode)
                                        ? brand.brandCode
                                        : brand.brandName
                                    : brand.brandName}
                            </span>
                        ))
                    }
                </>
            }

            <span className="">
                {props.CabinClasses.map((cabinclass, index) => {
                    return (
                        <CabinClassIcon
                            CabinClass={cabinclass}
                            key={"props.CabinClasses" + index}
                        />
                    );
                })}
            </span>
        </>


    );
};

interface CabinClasIconProps {
    CabinClass: string;
}

const CabinClassIcon: React.FC<CabinClasIconProps> = (props) => {

    switch (props.CabinClass) {
        case "economy":
            return (
                <span className="class_name class_name_y">
                    ECONOMY
                </span>
            );
        case "premiumeconomy":
            return (
                <span className="class_name class_name_p">
                    PREMIUM
                </span>
            );
        case "business":
            return (
                <span className="class_name class_name_c">
                    BUSINESS
                </span>
            );
        case "first":
            return (
                <span className="class_name class_name_f">
                    FIRST
                </span>
            );
        case "economy, business":
            return (
                <React.Fragment><span className="class_name class_name_y mx-1">ECONOMY</span><span> | </span><span className="class_name class_name_c mx-1">BUSINESS</span></React.Fragment>
            );
        default:
            return (
                <span className="class_name bg-white text-dark">
                    UNKNOWN
                </span>
            );
    }
};




