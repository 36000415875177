import { BookingAddressBehavior } from "WebApiClient";
import { AvailableFareModel } from "./../../../WebApiClient";
import { useEffect, useState } from "react";

export interface BookOtherValidationResult {
  Valid: boolean;
  WebfarePasswordRequired: boolean;
  WebfarePassword: boolean;
  EmergencyPhone: boolean;
  paymentBOFDetails: any;
}

export const DefaultBookOtherValidationResult: BookOtherValidationResult = {
  Valid: false,
  WebfarePasswordRequired: false,
  WebfarePassword: false,
  EmergencyPhone: false,
  paymentBOFDetails: [],
};

export function OtherValid(
  fare: AvailableFareModel,
  webfarePassword: string,
  emergencyPhone: string,
  webfarePasswordbehavior: BookingAddressBehavior,
  emergencyPhoneBehavior: BookingAddressBehavior,
  paymentBOFDetails: any,
  paymentDataBackend: any
): BookOtherValidationResult {
  let otherValid = true;
  const [paymentCheckValidation, setPaymentCheckValidation] = useState({ all: true, passengerSelection1: true, general: true });
  const apisRequirenments = fare.apisRequirenments!;
  const webfarePasswordRequired =
    webfarePasswordbehavior !== BookingAddressBehavior.UseBranchAdress &&
    apisRequirenments.password;
  const webFarePasswordValid: boolean = webfarePasswordRequired
    ? webfarePassword !== null && webfarePassword !== ""
    : true;

  if (!webFarePasswordValid) {
    otherValid = false;
  }

  const emergencyPhoneRequired =
    emergencyPhoneBehavior !== BookingAddressBehavior.UseBranchAdress;
  const emergencyPhoneValid: boolean = emergencyPhoneRequired
    ? emergencyPhone !== null && emergencyPhone !== ""
    : true;

  if (!emergencyPhoneValid) {
    otherValid = false;
  }

  const [paymentDataHolder, setPaymentDataHolder] = useState({ paymentDataBackend: paymentDataBackend, paymentBOFDetails: paymentBOFDetails })

  useEffect(() => {
    if (paymentDataBackend.length > 0 && paymentBOFDetails.length > 0) {
      setPaymentCheckValidation(validateInputValues(paymentDataHolder.paymentDataBackend, paymentDataHolder.paymentBOFDetails));
    }
    setPaymentDataHolder({ paymentDataBackend: paymentDataBackend, paymentBOFDetails: paymentBOFDetails })
  }, [paymentBOFDetails, paymentDataBackend, paymentDataHolder.paymentDataBackend.length, paymentDataHolder.paymentBOFDetails]);


  function validateInputValues(rules: any[], values: any[]) {
    let validationResults: any = {}; // To store the final output
    // Iterate through each value object
    if (rules?.length > 0 && values?.length > 0) {
      values.forEach((valueObj) => {
        // console.log('pervalues- ',valueObj,values)
        let passengerKey = valueObj?.passengerSelection !== null
          ? `passengerSelection${valueObj.passengerSelection}`
          : "general";
        let allValid = true; // Assume the value object is valid initially

        // Iterate through each rule object and validate against the current value object
        for (const rule of rules) {
          for (const key in rule) {
            if (rule.hasOwnProperty(key) && key.startsWith("Code")) {
              const index = key.replace("Code", "");
              const code = rule[`Code${index}`];
              const minLen = rule[`Minlen${index}`];
              const maxLen = rule[`MaxLen${index}`];
              const visible = rule[`Visible${index}`];

              // Skip validation if the field is not visible
              if (visible === "0") continue;

              // Check if the code exists in the current value object
              const fieldValue = valueObj[code];

              // If the field is present, validate the length
              if (fieldValue !== undefined) {
                // Perform length validation if `minLen` and `maxLen` are defined
                if (minLen !== null && maxLen !== null) {
                  if (fieldValue.length < minLen || fieldValue.length > maxLen) {
                    allValid = false; // Length validation failed
                  }
                }
              }
              // If validation already failed, stop further checks for this value
              if (!allValid) break;
            }
          }

          // If validation failed for any rule, break the outer loop
          if (!allValid) break;
        }

        // Check if all validation results are true
        const allTrue = Object.values(validationResults).every((val: any) => val == true);

        // Add the 'allTrue' key to the validationResults
        validationResults['all'] = allTrue;
        // Add the validation result for this value object to the final result
        validationResults[passengerKey] = allValid;
      })
      return validationResults; // Return the final validation results
    }
    return {}
  }

  const result: BookOtherValidationResult = {
    Valid: otherValid,
    WebfarePassword: webFarePasswordValid,
    EmergencyPhone: emergencyPhoneValid,
    WebfarePasswordRequired: webfarePasswordRequired,
    paymentBOFDetails: paymentCheckValidation,
  };
  return result;
}
