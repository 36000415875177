import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay, TimeDisplay } from "components/Shared/DateDisplay";
import IncServices, { GetIcon } from "components/Shared/IncServices";
import React, { useState, CSSProperties, useRef } from "react";
import { AvailableFareConnectionModel, AvailableFareModel, InludedServiceMainGroup } from "WebApiClient";
import { AllotmentModal } from "./Fare.AllotmentInfo";
import moment from "moment";
import _ from "lodash";
import { Popover, PopoverBody } from "reactstrap";
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import { capitalizeWholeHint, getFirstTwoLettersCapitalized, processBrandNames } from "components/Shared/Helpers/TextCapitalizer";
import { Tooltip } from "@mui/material";

moment().format();

interface OwnProps {
  Data: AvailableFareConnectionModel;
  SelectedConnection: any;
  GetSelectedConnectionIndexByLegIndex: any;
  LegIndex: number;
  ConnectionIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  IsWebFare: boolean;
  connectionAppliesToFilter: boolean;
  Fare: AvailableFareModel;
  connection: any;
  cabinIsPresentAll: boolean | undefined;
  headerOffVisible: boolean;
}


const FareLegConnectionTableRow: React.FC<OwnProps> = (props) => {
  const [AllotmentModalOpen, setAllotmentModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseEnter = (index: any) => {
    setOpen(true);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setActiveIndex(null);
  };

  const toggle = (index: any) => {
    setActiveIndex(open ? null : index);
  };
  const elemRef = useRef<HTMLImageElement>(null);
  const elemRef1 = useRef<HTMLImageElement>(null);

  // const baseKey = `f${props.OriginalFareIndex}_l${props.LegIndex}_c${props.ConnectionIndex}`

  function SegmentHasAllotment() {
    let result = false;
    props.Data.segments.forEach((seg) => {
      if (seg.allotmentInformation) {
        result = true;
      }
    });
    return result;
  }

  function ToggleAllotmentModal() {
    setAllotmentModalOpen(!AllotmentModalOpen);
  }

  function OnConnectionSelection() {
    if (props.connectionAppliesToFilter) {
      props.SelectConnection(props.LegIndex, props.ConnectionIndex);
      props.GetSelectedConnectionIndexByLegIndex(props.ConnectionIndex);
      props.GetSelectedConnectionIndexByLegIndex(props.LegIndex);
    }
  }

  // Function to check equality for all objects in the array
  const areAllIncludedServicesEqual = (
    dataArray: any,
    stringToSearch: string
  ) => {
    const firstObject = dataArray[0];
    if (dataArray.length < 2) {
      const segments1 = _.get(firstObject, "segments", []);
      for (let j = 0; j < segments1.length; j++) {
        const singleCellDoubleBaggage = areIncludedServicesEqual(
          segments1[j],
          segments1[j + 1],
          stringToSearch
        );
        if (singleCellDoubleBaggage) {
          return true;
        }
      }
    }
    if (dataArray.length > 1) {
      for (let i = 1; i < dataArray.length; i++) {
        const currentObject = dataArray[i];

        // Get the segments array from each object
        const segments1 = _.get(firstObject, "segments", []);
        const segments2 = _.get(currentObject, "segments", []);

        // Check if the lengths of segments arrays are equal
        if (segments1.length !== segments2.length) {
          return false;
        }

        // Check the equality of includedServices for each segment
        for (let j = 0; j < segments1.length; j++) {
          const includedServicesEqual = areIncludedServicesEqual(
            segments1[j],
            segments2[j],
            stringToSearch
          );
          if (!includedServicesEqual) {
            return false;
          }
        }
      }
      return true;
    }
  };

  // Function to check if the arrays of includedServices are equal
  const areIncludedServicesEqual = (
    obj1: any,
    obj2: any,
    searchString: string
  ) => {
    const services1 = _.get(obj1, searchString, []);
    const services2 = _.get(obj2, searchString, []);
    if (
      (typeof services1 !== "string" || typeof services2 !== "string") &&
      (services1.length == 0 || services2.length == 0)
    ) {
      return true;
    }
    if (
      (typeof services1 === "string" || typeof services2 === "string") &&
      (services1.length == "" || services2.length == "")
    ) {
      return true;
    }
    return _.isEqual(services1, services2);
  };

  const cabinIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "cabinClassName"
  );

  const isPremiumPresent = _.some(props.Data.segments, {
    cabinClassName: "PremiumEconomy",
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleMouseEnter1 = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverMouseEnter = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  const handleMouseLeave1 = () => {
    setIsPopoverOpen(false);
  };

  const getUniqueBrandNames = (array: any) => {
    const uniqueBrandNames = new Set();
    const uniqueBrandNameObjects: any = [];

    array.forEach((item: any) => {
      if (!uniqueBrandNames.has(item.brandName)) {
        uniqueBrandNames.add(item.brandName);
        uniqueBrandNameObjects.push(item);
      }
    });

    return uniqueBrandNameObjects;
  };


  const getUniqueIncludedServices = (data: any) => {
    const uniqueServicesMap = new Map();

    data.forEach((item: any) => {
      item.includedServices.forEach((service: any) => {
        const serviceString = JSON.stringify(service);
        if (!uniqueServicesMap.has(serviceString)) {
          uniqueServicesMap.set(serviceString, service);
        }
      });
    });

    return Array.from(uniqueServicesMap.values());
  };


  //remove duplicate services of included services 
  const removeDuplicateServices = (dataArray: InludedServiceMainGroup[]) => {
    return dataArray.map(item => {
      const uniqueServices = _.uniqWith(item.services, (a, b) =>
        a.type === b.type && a.subType === b.subType && a.hint === b.hint
      );

      return {
        ...item,
        services: uniqueServices
      };
    });
  };

  const segments = props.Data.segments;
  const isEconomyGreen = segments.some((segment: any) => segment?.brandName === "Economy Green");


  return (
    <React.Fragment>
      {props.headerOffVisible != false &&
        <tr className={props.connectionAppliesToFilter ? "" : "table-inactive"}>
          <>
            <td style={{ width: "1%" }}
              className="text-center"
              onClick={OnConnectionSelection}
            >
              {/* SELECT  */}
              {props.connectionAppliesToFilter && (
                <div className="custom-control custom-radio">
                  <input
                    onChange={() => { }}
                    className="form-check-input rbConnectionSelect"
                    type="radio"
                    checked={props.ConnectionIndex === props.SelectedConnection}
                  />
                  <label className="form-check-label">&zwnj;</label>
                </div>
              )}
            </td>
            <td className="text-truncate" onClick={OnConnectionSelection}>
              {" "}
              {/* ROUTING  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments" + index}>
                  <span>{segment.departureAirport!.iata}</span>-
                  <span>{segment.arrivalAirport!.iata}</span>
                  <br />
                </span>
              ))}
            </td>
          </>

          <td className="text-truncate" onClick={OnConnectionSelection}>
            {/* FLIGHT */}
            {props.Data.segments!.map((segment, index) => {
              const isOpen = open && activeIndex === index;
              //  const elemRef:any = React.createRef();

              return (
                <>
                  <span
                    key={"props.Data.segments1" + index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <span ref={elemRef} id={`popover-${index}`}>
                      {segment.carrier.code}
                      {segment.flightNumber}
                    </span>
                    <span className="starIcon">{segment.codeShare ? "*" : ""}</span>
                    <br />
                  </span>
                  <Popover
                    target={elemRef}
                    isOpen={isOpen}
                    toggle={() => toggle(index)}
                    className="operatedByCss"
                  >
                    <PopoverBody>
                      Operated by:{" "}
                      {segment.codeShare != null
                        ? segment.codeShare.code
                        : segment.carrier.code + segment.flightNumber}
                    </PopoverBody>
                  </Popover>
                </>
              );
            })}
          </td>


          <>
            <td className="text-truncate" onClick={OnConnectionSelection}>
              {" "}
              {/* TYPE  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments2" + index}>
                  <span>{segment.equipmentCode}</span>
                  <br />
                </span>
              ))}
            </td>

            <td className="text-truncate" onClick={OnConnectionSelection}>
              {" "}
              {/* DATES  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments3" + index}>
                  <span>
                    <DateDisplay date={segment.departureDate} />
                  </span>
                  <br />
                </span>
              ))}
            </td>

            <td className="text-truncate" onClick={OnConnectionSelection}>
              {" "}
              {/* TIMES  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments4" + index}>
                  <span>
                    <span>
                      <TimeDisplay date={segment.departureDate} /> -{" "}
                    </span>
                    <span>
                      <TimeDisplay date={segment.arrivalDate} />
                      <span className="nextday">
                        {" "}
                        <sup>{segment.dayIndicator}</sup>
                      </span>
                    </span>
                  </span>
                  <br />
                </span>
              ))}
            </td>

            <td className="text-truncate" onClick={OnConnectionSelection}>
              {/* Render "WL" span and Popover for each segment */}
              {props.Data.segments!.map((segment, index) => (
                <div key={`segment-${index}`}>
                  <div className="d-flex align-items-center pb-1">
                    <div className="w-25">{segment.bookingClass}</div>
                    <div>
                      {segment.freeSeats == 0 && (
                        <>
                          <span
                            className="WL cursor-pointer"
                            key={"WL" + index}
                            onMouseEnter={handleMouseEnter1}
                            onMouseLeave={handleMouseLeave1}
                            ref={elemRef1}
                          >
                            WL
                          </span>
                          {isPopoverOpen && (
                            <Popover
                              target={elemRef1.current || ""}
                              isOpen={true}
                              className="operatedByCss"
                              onMouseEnter={handlePopoverMouseEnter}
                              onMouseLeave={handlePopoverMouseLeave}
                            >
                              <PopoverBody>
                                This flight is under Waitlist
                              </PopoverBody>
                            </Popover>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </td>
          </>




          <td className="text-truncate" onClick={OnConnectionSelection}>
            {" "}
            {/* BAGGAGE */}
            {props.Data.segments!.map((segment, index) => (
              <span key={"props.Data.segments6" + index}>
                <span>
                  {!segment.hasFreeBaggage ? (
                    <>
                      <FontAwesomeIcon
                        style={{
                          opacity: !segment.hasFreeBaggage ? 0.3 : 1,
                        }}
                        title="This fare has no free baggage allowance."
                        icon={faLuggageCart}
                      />
                      <FontAwesomeIcon
                        style={
                          {
                            "--fa-secondary-opacity": "0.7",
                            "--fa-primary-color": "black",
                            color: `${!segment.hasFreeBaggage ? "red" : "green"
                              }`,
                          } as CSSProperties
                        }
                        icon={
                          !segment.hasFreeBaggage
                            ? faExclamationTriangle
                            : faCheckCircle
                        }
                        transform="shrink-7 down-4 left-9"
                      />
                    </>
                  ) : (
                    <>
                      {/* <h1>hello</h1> */}
                      {segment.freeBaggageAllowance}
                    </>
                  )}
                </span>
                <br />
              </span>
            ))}
          </td>

          <td>
            {" "}
            {/* INCLUDEDSERVICES  */}
            <span className="ancillaries text-s">
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments7" + index}>
                  {
                    (() => {
                      // Define `combinedHints` inside an IIFE so that it can be immediately invoked and used
                      const combinedHints = Array.from(
                        new Map(
                          segment.includedServices!
                            .flatMap((item) => item.services!.map((service) => ({ hint: service.hint, type: service.type, subType: service.subType })))
                            .map((obj) => [obj.hint, obj])
                        ).values()
                      );

                      return (
                        <Tooltip
                          key={"tooltip_" + index}
                          title={
                            <React.Fragment>
                              <ul style={{listStyleType: 'none', padding: 0, margin: 0 }}>
                                {combinedHints.length > 0 &&
                                  combinedHints.map((sub: any, subIndex: any) => (
                                    <li key={`mainSer_${subIndex}`}>
                                      <FontAwesomeIcon icon={GetIcon(sub)} /> {sub.hint}
                                    </li>
                                  ))}
                              </ul>
                            </React.Fragment>
                          }
                        >
                          <span className="parentIconsClass">
                            {
                              segment.includedServices.map((mainGroup, mainGroupIndex) => (
                                <>
                                  <IncServices key={mainGroupIndex} data={mainGroup} combinedHints={combinedHints} />
                                </>
                              ))
                            }
                          </span>
                        </Tooltip>
                      );
                    })()
                  }
                  <br />
                </span>

              ))}
            </span>
          </td>


          {SegmentHasAllotment() && (
            <td className="text-center align-middle clickme">
              {" "}
              {/* DETAILS  */}
              <button onClick={ToggleAllotmentModal} className="btn btn-sm">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </td>
          )}
        </tr>
      }
      {
        props.headerOffVisible == false && (
          <div className="upSellContent">
            <div className="upSellHeader" style={{
              background: isEconomyGreen
                ? "#4CAF50"
                : ""
            }}>
              <p>{/* BrandName */}
                <span className="ancillaries text-s">
                  {processBrandNames(getUniqueBrandNames(props.Data.segments)).map((segment: any, index: any) => (
                    <span key={"props.Data.segments7" + index}>
                      <span>{segment?.brandName}</span>
                    </span>
                  ))}
                </span></p>
              <p>{/* price upsale */}
                <span className="ancillaries text-s">
                  {props.Fare.totalPrice.toFixed(2)}
                  {currencyFormatter(props.Fare.currency)}
                </span>
                <span className="ancillaries text-s" style={{ fontSize: '10px', fontWeight: '500', float: 'right', marginTop: '5px' }}>
                  {getFirstTwoLettersCapitalized(props.Fare.fareInfoBox.queryGDS)}
                </span>
              </p>
            </div>
            <div className="hoverUpsellIcon">
              {/* INCLUDEDSERVICES */}
              <span>
                <React.Fragment>
                  {removeDuplicateServices(getUniqueIncludedServices(props.Data.segments)).map(
                    (mainGroup: any, mainGroupIndex) => (
                      <span style={{ display: 'flex', alignItems: 'self-start' }}>
                        <span style={{ minWidth: '16px', marginRight: '4px', display: 'flex', alignItems: 'start', paddingTop: '4px' }}>
                          <FontAwesomeIcon
                            className="upsellIcon"
                            icon={GetIcon(mainGroup)}
                          />
                        </span>
                        <div style={{ lineHeight: '16px' }}>
                          {
                            capitalizeWholeHint(mainGroup.services)!.map((sub: any, subIndex: any) => <span key={"mainSer_" + subIndex}>
                              <span style={{ fontSize: '10px' }}>
                                {sub.hint}
                              </span>
                              <br />
                            </span>
                            )}
                        </div>
                      </span>
                    )
                  )}
                </React.Fragment>
              </span>
            </div>
          </div>
        )
      }

      <AllotmentModal
        connection={props.Data}
        isopen={AllotmentModalOpen}
        toggle={ToggleAllotmentModal}
      />
    </React.Fragment>
  );
};

export default FareLegConnectionTableRow;

