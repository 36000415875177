import BookingManager from "components/BookingManager/component/BookingManager";
import { DefaultBookingManagerFilter } from "components/BookingManager/types/BookingManagerTypes";
import { useSessionHandler } from "hooks/useSessionHandler";
import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NoMatch from "routes/NoMatch";
import { BookingMangerFilterRequestData } from "WebApiClient";
import BookingdetailsPage from "./BookingDetailsPage";
import { useUserInformation } from "hooks/various";

const BookingManagerPage: React.FC<{}> = (props) => {
  const userInfo = useUserInformation();
  const [CurrentFilter, SetCurrentFilter] = useState<BookingMangerFilterRequestData>(DefaultBookingManagerFilter);

  const DEBUG = false;
  const sessionHandler = useSessionHandler();
  useEffect(() => {
    document.title = `Bookingmanager`;
    if (DEBUG) {
      console.log(`Booking manager Page initialized.`);
    }
    sessionHandler.RefreshSession();
    // CurrentFilter.branch = userInfo.BranchName
    // CurrentFilter.branchGroup = userInfo.BranchgroupName
    // CurrentFilter.web = userInfo.WebName
  }, []);
  
  return (
    <Routes>
      <Route
        path="/"
        element={
          <BookingManager
            CurrentFilter={CurrentFilter}
            OnFilterUpdate={(filter) => {
              SetCurrentFilter(filter);
            }}
          />
        }
      ></Route>
      <Route
        path="/details/:cartid/:bookingid"
        element={<BookingdetailsPage />}
      ></Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default BookingManagerPage;