import { faMinusCircle, faPlane, faPlusCircle, faSearch, faText, faUser, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BookingMangerPassengersTableCell } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AddElementsToBookingRequestModel, AddOSIRequest, Airline, BaseApiResponse, BookingIdentifier, BookingItemElementsManagementModel, PnrResponseOSIModel, PnrResponsePassengerModel, RemoveElementsFromBookingRequestModel } from "WebApiClient";

// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";



export const BookingMangerManageOSIElements: React.FC<{ model: BookingItemElementsManagementModel, OnRefresh: () => void, BookingIdentifier: BookingIdentifier }> = props => {
    const { passengers, bookingOSIElements, segments, airlineOptions } = props.model;
    const [addQueue, setAddQueue] = useState<AddOSIRequest[]>([])
    const defaultOSIAdd: AddOSIRequest = { carrierCode: "", freeText: "", passengerSelection: [] }
    const [deleteQueue, setDeleteQueue] = useState<number[]>([])

    const [newOsi, setNewOSI] = useState<AddOSIRequest>(defaultOSIAdd);
    const bookClient = useBookingClient();
    const [fetching, setFetching] = useState(false);
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const [errorOccured, setErrorOccured] = useState(false);
    const dispatch = useDispatch();
    function OnRefresh() {
        setAddQueue([]);
        setResponse(undefined);
        props.OnRefresh();
    }
    function ToggleDeleteIndex(index: number) {
        let arrC = [...deleteQueue];
        const iOf = arrC.indexOf(index);
        if (iOf === -1) {
            arrC.push(index);
        }
        else {
            arrC.splice(iOf, 1);
        }
        setDeleteQueue(arrC);
    }
    function OnConfirmDelete() {
        const deleteRemarks: AddOSIRequest[] = [];
        deleteQueue.forEach(index => {
            const allElemAt = allOSI[index];
            deleteRemarks.push(allElemAt)
        })
        const request: RemoveElementsFromBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: deleteRemarks, remarks: undefined, services: undefined, ssrServices: undefined }
        setFetching(true);
        bookClient.removeElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                    toast.success("OSI elements successfully modified.");
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
                OnRefresh();
            })
    }
    function OnSubmitAdd() {
        const request: AddElementsToBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: addQueue, remarks: undefined, services: undefined, ssrServices: undefined, passengerAPIS: undefined }
        setFetching(true);
        bookClient.addElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                    toast.success("OSI elements successfully modified.");
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
                OnRefresh();
            })


    }

    function OnUpdateQueueItem(data: AddOSIRequest | undefined, index: number) {
        if (data) {
            let arrC = [...addQueue];
            arrC[index] = data;
            setAddQueue(arrC);
        }
        else {
            let arrC = [...addQueue];
            arrC.splice(index, 1);
            setAddQueue(arrC);
        }
    }
    function OnAddQueueItem() {
        let arrC = [...addQueue];
        arrC.push(newOsi);
        setAddQueue(arrC);
        setNewOSI(defaultOSIAdd);
    }

    function SubmitButtonDisabled(): boolean {
        let result = false;
        if (InvalidElements() || addQueue.length === 0 || fetching) {
            result = true;
        }
        return result;
    }
    function InvalidElements(): boolean {
        let result = false;
        if (addQueue.filter(e => e.freeText.length === 0).length > 0) {
            result = true;
        }
        return result;
    }
    function MapResponseOSI(data: PnrResponseOSIModel, passenger?: number): AddOSIRequest {
        const result: AddOSIRequest = { carrierCode: data.carrier, freeText: data.text, passengerSelection: passenger ? [passenger] : [] };
        return result;
    }

    function GetAllOSI(): AddOSIRequest[] {
        let result: AddOSIRequest[] = [];
        result = result.concat(bookingOSIElements.map(o => MapResponseOSI(o)));
        passengers.forEach(p => {
            result = result.concat(p.osi.map(o => MapResponseOSI(o, p.passengerNumber)))
        })
        return result;
    }
    const allOSI = GetAllOSI();

    return <div className="row">
        {fetching &&
            <div className="col-12">
                <WaitingCard />
            </div>
        }

        {!fetching &&
            <Fragment>
                {errorOccured &&
                    <div className="col-12 mb-2">
                        <ErrorDisplay data={response?.responseMetaData} />
                    </div>
                }
                {(response && !errorOccured) ?
                    <div className="col-12 mb-2">
                        <Alert color="success manageElementAlert">
                            <h4 className="alert-heading">OSI elements successfully modified.</h4>
                            <button className="btn btn-primary" onClick={OnRefresh}>Refresh</button>
                        </Alert>
                    </div> :
                    <Fragment>
                        <div className="col-12 mb-2">
                            <div className="card card-primary">
                                <div className="card-header card-header-primary">Add OSI elements</div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">

                                        <OSIAddComponent airlineOptions={airlineOptions} allPassengers={passengers} value={newOsi} OnUpdate={(update) => { setNewOSI(update) }} OnAdd={OnAddQueueItem} />
                                        {addQueue.length > 0 &&
                                            <Fragment>

                                                <div className="col-12">
                                                    <div className="content-divider-dark"></div>
                                                </div>
                                                <div className="col-12">
                                                    <p>{addQueue.length} OSI will be added to booking</p>
                                                </div>
                                                {addQueue.map((item, index) =>
                                                    <OSIAddComponent airlineOptions={airlineOptions} key={"addQueue"+index} allPassengers={passengers} value={item} OnUpdate={(update) => { OnUpdateQueueItem(update, index) }} OnRemove={() => { OnUpdateQueueItem(undefined, index) }} />

                                                )}
                                            </Fragment>

                                        }


                                        <div className="col-12 mb-2">
                                            <div className="d-grid">

                                                <button disabled={SubmitButtonDisabled()} onClick={OnSubmitAdd} className="btn btn-success text-white">Confirm and add OSI elements to booking</button>
                                            </div>
                                            {InvalidElements() &&
                                                <Alert color="danger">
                                                    Invalid elements. Please correct your input to continue.
                                                </Alert>

                                            }
                                        </div>
                                    </div>
                                </div>



                            </div>



                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header card-header-primary">
                                    OSI Elements in Booking
                                </div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">
                                        <div className="col-12">
                                            <button disabled={deleteQueue.length === 0} className="btn btn-primary" onClick={OnConfirmDelete}>Remove selection from booking</button>
                                        </div>
                                        <div className="col-12">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Text</th>
                                                        <th>Carrier</th>
                                                        <th>Passengers</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allOSI.map((e, i) =>
                                                        <tr key={"allOsi_"+i}>
                                                            <td><input type="checkbox" checked={deleteQueue.includes(i)} onChange={() => { ToggleDeleteIndex(i) }}></input></td>
                                                            <td>{e.freeText}</td>
                                                            <td>{e.carrierCode}</td>
                                                            <BookingMangerPassengersTableCell numbers={e.passengerSelection} pnrPassengers={passengers} />

                                                        </tr>
                                                    )}


                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </Fragment>
                }

            </Fragment>
        }

    </div>

}

const OSIAddComponent: React.FC<{ value: AddOSIRequest, allPassengers: PnrResponsePassengerModel[], airlineOptions: Airline[], OnUpdate: (update: AddOSIRequest) => void, OnRemove?: () => void, OnAdd?: () => void }> = props => {
    const { OnUpdate, value, OnAdd, OnRemove, airlineOptions } = props;
    const { carrierCode, freeText, passengerSelection } = value;
    const [showSegmentsSelection, setShowSegmentsSelection] = useState(false);
    const [showPassengerSelection, setShowPassengerSelection] = useState(false);
    const [showExtended, setShowExtended] = useState(false);

    const freeTextValid = freeText.length > 0;




    function TogglePassengerSelection(id: number) {
        const indexOf = passengerSelection.indexOf(id);
        let arrC = [...passengerSelection];
        if (indexOf === -1) {
            arrC.push(id);
        }
        else {
            arrC.splice(indexOf, 1);
        }
        OnUpdate({ ...value, passengerSelection: arrC });
    }


    return <div className="col-12 mb-2 p-1 ">
        <div className="card">
            <div className="card-body card-body-primary">
                <div className="row">

                    <div className="col-5 mb-2">
                        <div className="input-group">
                            <span className="input-group-text" title="Free text">
                                <FontAwesomeIcon icon={faText} />
                            </span>


                            <input value={freeText} type="text" className={`form-control ${freeTextValid ? "is-valid" : "is-invalid"}`} onChange={(e) => { OnUpdate({ ...value, freeText: e.target.value }) }}></input>
                            {!freeTextValid &&
                                <div className="invalid-feedback">
                                    Please enter a message.
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-5 mb-2">
                        <div className="input-group">
                            <span className="input-group-text" title="Airline">
                                <FontAwesomeIcon icon={faPlane} />
                            </span>

                            <select value={carrierCode} onChange={e => { OnUpdate({ ...value, carrierCode: e.target.value }) }} className={`form-select`}>
                                <option value={""}>Select airline...</option>
                                {airlineOptions.map((o, i) =>
                                    <option key={"airOpt_"+i} value={o.code}>{o.code} - {o.hint}</option>
                                )}
                            </select>
                            {/* <input value={carrierCode} type="text" className="form-control" onChange={(e) => { OnUpdate({ ...value, carrierCode: e.target.value }) }}></input> */}
                        </div>
                    </div>


                    <div className="col-2 mb-2">
                        {OnAdd &&
                            <div className="d-grid">

                                <button onClick={OnAdd} className="btn btn-primary"><FontAwesomeIcon icon={faPlusCircle} /> add</button>
                            </div>
                        }
                        {OnRemove &&
                            <div className="d-grid">
                                <button onClick={OnRemove} className="btn btn-danger"><FontAwesomeIcon icon={faMinusCircle} /> remove</button>

                            </div>
                        }

                    </div>
                    <div className="col-12 mb-2">
                        <div className="d-grid">

                            <button className="btn btn-sm btn-secondary" onClick={() => { setShowExtended(!showExtended) }}><FontAwesomeIcon icon={faSearch} /> {showExtended ? "Hide" : "Show"} detailed options for element</button>
                        </div>
                    </div>
                    {showExtended &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body row">
                                    <div className="col-4 mb-2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <span className="input-group-text" title="Passengers">
                                                        <FontAwesomeIcon icon={faUserFriends} />
                                                    </span>


                                                    <input readOnly value={passengerSelection.length === 0 ? `All passengers (default)` : `${passengerSelection.length} / ${props.allPassengers.length}`} type="text" className="form-control" ></input>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <p>Select only...</p>
                                                {props.allPassengers.map((pOption, poI) =>
                                                    <div key={"apass_"+poI} onClick={() => { TogglePassengerSelection(pOption.passengerNumber) }} className="form-check">
                                                        <input onChange={() => { }} checked={passengerSelection.includes(pOption.passengerNumber)} type="checkbox" className="form-check-input" />
                                                        <label className="form-check-label" ><FontAwesomeIcon icon={faUser} /> {pOption.name}, {pOption.firstName}</label>
                                                    </div>

                                                )}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>



                        </div>
                    }


                </div>
            </div>
        </div>

    </div>
}

