import { faPlaneArrival, faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { ConnectionHeaderView, Flight } from "WebApiClient";
import Tooltip from "@mui/material/Tooltip";


interface LegProps {
    Data: ConnectionHeaderView;
    LegIndex: number;
    PlatingCarrierCode: string;
    PlatingCarrierName: string;
    ShowLegPlatingCarrier: boolean;
    ToggleFlightTimes: () => void;
    IsExpanded: boolean;
    timeSegmentDiff?: any;
}



const FareLegHeader: React.FC<LegProps> = props => {
    
    const [arrivalTooltipOpen, setArrivalTooltipOpen] = useState(false);
    const arrivalTooltipContent = `${props.Data?.arrival} (${props.Data?.arrivalFullName})`;
    const togglearrivalToolTip = () => setArrivalTooltipOpen(!arrivalTooltipOpen);
    const arrivalRef = useRef<HTMLSpanElement>(null);

    const [departureTooltipOpen, setDepartureToolTipOpen] = useState(false);
    const departureTooltipContent = `${props.Data?.departure} (${props.Data?.departureFullName})`;
    const toggledepartureToolTip = () => setDepartureToolTipOpen(!departureTooltipOpen);
    const departureRef = useRef<HTMLSpanElement>(null);
    const totalTravelTime =
        props.Data?.legTravelTime!.addHours * 60 +
        props.Data?.legTravelTime!.minutes;
    return (
        <div className="segment bb-1 clickme" style={{ display: "block" }} onClick={props.ToggleFlightTimes}>
            <div className="py-2 d-flex justify-content-center align-items-center">
                <Popover target={arrivalRef} isOpen={arrivalTooltipOpen} toggle={togglearrivalToolTip}>
                    <PopoverBody>
                        {arrivalTooltipContent}
                    </PopoverBody>
                </Popover>
                <Popover target={departureRef} isOpen={departureTooltipOpen} toggle={toggledepartureToolTip}>
                    <PopoverBody>
                        {departureTooltipContent}
                    </PopoverBody>
                </Popover>
                <div
                    className="segment__departure_airport"
                >
                    <span onMouseEnter={() => setDepartureToolTipOpen(true)}
                        onMouseLeave={() => setDepartureToolTipOpen(false)}
                        onTouchStart={(e) => { e.stopPropagation(); setDepartureToolTipOpen(true) }}
                        onTouchEnd={() => setDepartureToolTipOpen(false)} ref={departureRef}>{props.Data?.departure}</span>
                </div>
                <div className="segment__departure_time text-nowrap">
                    {props.Data?.departureDisplayTime}
                    <div className="" style={{ fontSize: "0.6em" }}>
                        <span>
                            <FontAwesomeIcon icon={faPlaneDeparture} /> {' '} {props.Data?.departureDate}</span>
                    </div>
                </div>
                <div className="segment__stops d-flex justify-content-center">
                    {/* <div className="segment__stops_duration w-75">
                        <div className="segment__stops_traveltime">
                            {props.Data?.legTravelTime!.addHours}h {props.Data?.legTravelTime!.minutes}m
                        </div>
                        <StopsIndicator
                            Data={props.Data?.travelStopps}
                            legTravelTime={totalTravelTime}
                            timeSegmentDiff={props.timeSegmentDiff}
                        />
                    </div> */}
                    <div className="segment__stops_duration w-100">
                        <div className="segment__stops_traveltime">

                            {props.Data?.legTravelTime!.addHours}h{" "}
                            {props.Data?.legTravelTime!.minutes}m

                        </div>
                        <StopsIndicator
                            Data={props.Data?.travelStopps}
                            legTravelTime={totalTravelTime}
                            timeSegmentDiff={props.timeSegmentDiff}
                        />
                    </div>
                </div>
                <div className="segment__arrival_time text-nowrap justify-content-center">
                    {props.Data?.arrivalDisplayTime}
                    <span className="nextday"><sup>{props.Data?.dayIndicator}</sup></span>
                    <div className="" style={{ fontSize: "0.6em" }}>
                        <span>
                            <FontAwesomeIcon icon={faPlaneArrival} /> {' '} {props.Data?.arrivalDate}</span>
                    </div>
                </div>
                <div className="segment__arrival_airport mr-2 pulse">
                    <span onMouseEnter={() => setArrivalTooltipOpen(true)}
                        onMouseLeave={() => setArrivalTooltipOpen(false)}
                        onTouchStart={(e) => { e.stopPropagation(); setArrivalTooltipOpen(true) }}
                        onTouchEnd={() => setArrivalTooltipOpen(false)} ref={arrivalRef}>{props.Data?.arrival}</span>
                </div>
                {
                    props.ShowLegPlatingCarrier &&
                    <AirlineLogo
                        PlatingCarrier={props.PlatingCarrierCode}
                        PlatingCarrierName={props.PlatingCarrierName}
                        IsAlliance={false}
                    />
                }

                <div className="float-end ml-2">
                    <FontAwesomeIcon icon={props.IsExpanded ? faAngleDoubleUp : faAngleDoubleDown} />
                </div>
            </div>
        </div>
    );
};

interface StopsIndicatorProps {
    Data: number;
    legTravelTime?: number;
    timeSegmentDiff: Flight[];
}
interface FlightData {
    arrivalDepartureDifference: number;
    segmentDifference: number;
}
interface MergedFlightTimeDifferences {
    [key: string]: FlightData;
}

const StopsIndicator: React.FC<StopsIndicatorProps> = (props) => {
    const [className, setClassName] = useState<string>("");
    const [label, setLabel] = useState<string>("");
    const [stops, setStops] = useState<number>(0);
    const totalTravelTime: number = props.legTravelTime || 0;
    const [segArray, setSegArray] = useState<any>({}); 

    function segCal() {
        if (props?.timeSegmentDiff?.length > 1) {
            let myObject: any = {};
            props.timeSegmentDiff.forEach((time: Flight, i: number) => {
                const dateTime1: any = time.arrivalDate;
                const dateTime2: any = time.departureDate;
                const date1: any = new Date(dateTime1);
                const date2: any = new Date(dateTime2);
                const timeBetween = date1 - date2;
                const hoursBetween = Math.floor(timeBetween / (1000 * 60 * 60));
                const minutesBetween = Math.floor(
                    (timeBetween % (1000 * 60 * 60)) / (1000 * 60)
                );
                myObject[`timeBetween${i}`] = `${hoursBetween}h ${minutesBetween}m`;
                if (i < props?.timeSegmentDiff?.length - 1) {
                    const dateTime3: any = props?.timeSegmentDiff[i + 1]?.departureDate;
                    const date3: any = new Date(dateTime3);
                    const timeDifference = date3 - date1;
                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                    const minutesDifference = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    myObject[
                        `timeDifference${i}`
                    ] = `${hoursDifference}h ${minutesDifference}m`;
                }
                // console.log("myObject---", dateTime1, myObject);
            });
            setSegArray({ ...myObject });
        }
    }

    const flightTimeCalculator = {
        getTimeDifferenceInMinutes: (
            arrivalDateString: string,
            departureDateString: string
        ): number => {
            const arrivalTime = new Date(arrivalDateString).getTime();
            const departureTime = new Date(departureDateString).getTime();
            return (arrivalTime - departureTime) / (1000 * 60); // Convert milliseconds to minutes
        },

        calculateFlightTimeDifferences: (flights: Flight[]) => {
            const DiffArr = flights?.map((flight: Flight, i) => {
                const arrivalDepartureDifference = Math.abs(
                    flightTimeCalculator.getTimeDifferenceInMinutes(
                        flight.arrivalDate,
                        flight.departureDate
                    ));
                let segmentDifference = 0;
                if (i < flights.length - 1) {
                    // Check if not the last flight
                    const nextDepartureTime = new Date(
                        flights[i + 1].departureDate
                    ).getTime();
                    const currentArrivalTime = new Date(flight.arrivalDate).getTime();

                    if (nextDepartureTime > currentArrivalTime) {
                        segmentDifference =
                            Math.abs((nextDepartureTime - currentArrivalTime) / (1000 * 60));
                    }
                }

                return {
                    flightNumber: i + 1,
                    arrivalDepartureDifference: arrivalDepartureDifference,
                    segmentDifference: segmentDifference,
                };
            });

            const mergedFlightTimeDifferences: MergedFlightTimeDifferences = {};
            DiffArr?.forEach((flightData: any) => {
                const { flightNumber, arrivalDepartureDifference, segmentDifference } = flightData;
                mergedFlightTimeDifferences[`flight_${flightNumber}`] = {
                    arrivalDepartureDifference,
                    segmentDifference,
                };
            });
            return mergedFlightTimeDifferences;
        },
    };

    // Usage example:
    const flightDifferences: MergedFlightTimeDifferences = flightTimeCalculator.calculateFlightTimeDifferences(props.timeSegmentDiff);

    useEffect(() => {
        const amount = props.Data;
        let newClassName = "";
        let newLabel = "";
        let newStops = 0;
        switch (amount) {
            case 0:
                newClassName = "segment__stops_direct";
                newLabel = "Nonstop";
                newStops = 0;
                break;
            case 1:
                newClassName = "segment__stops_1plus";
                newLabel = "1 Stop";
                newStops = 1;
                break;
            case 2:
                newClassName = "segment__stops_2plus";
                newLabel = "2 Stops";
                newStops = 2;
                break;
            default:
                newClassName = "segment__stops_2plus";
                newLabel = `${amount} Stops`;
                newStops = amount;
                break;
        }

        setClassName(newClassName);
        setLabel(newLabel);
        setStops(newStops);
        segCal();
    }, [props.Data, props?.timeSegmentDiff]);

    let position = 0;
    let numberString = totalTravelTime.toString();
    for (let i = 0; i < numberString.length; i++) {
        position += parseInt(numberString[i]);
    }

    let total: any = 0;
    // Iterate through each flight
    for (const flightKey in flightDifferences) {
        if (flightDifferences.hasOwnProperty(flightKey)) {
            const flight: any = flightDifferences[flightKey];

            // Iterate through each property of the flight
            for (const prop in flight) {
                if (flight.hasOwnProperty(prop)) {
                    // Add the value to the total
                    total += flight[prop];
                }
            }
        }
    }

    var count = 0;
    const flatSegArray = Object.keys(flightDifferences).map((key, index) => {
        return Object.values(flightDifferences[key]).map((value, index2) => {
            const widthPercentage = (value / total) * 100;
            const isTimeDifference =
                index2 == 0 || widthPercentage == 0 ? false : true;
            return [
                <>
                    <PopoverComponent
                        key={index}
                        stops={stops}
                        timeValue={value}
                        widthPercentage={widthPercentage}
                        isTimeDifference={isTimeDifference}
                    />
                </>,
            ];
        });
        count++;
    });

    // Extract arrival name from each object and rendering Airports
    const flightsExceptLast = _.initial(props.timeSegmentDiff);
    const arrivalNames = _.map(flightsExceptLast, "arrivalAirport.cityCode");
    const arrivalFullNames = _.map(flightsExceptLast, "arrivalAirport.cityName");

    const airportSegArray = Object.keys(flightDifferences).map((key, indexAirSeg) => {
        return Object.values(flightDifferences[key]).map((value: any, indexAirSegInner) => {
            const widthPercentage = (value / total) * 100;
            const isTimeDifference =
                indexAirSegInner == 0 || widthPercentage == 0 ? false : true;
            return [
                <>
                    <span style={{
                        width: `${widthPercentage}%`,
                        visibility: (isTimeDifference || label == "Nonstop") && widthPercentage != 0
                            ? ("visible" as const)
                            : ("hidden" as const),
                    }}>
                        {label == "Nonstop" ? (
                            <div className={`${className} segment__stops_totalstops`}>
                                {label}
                            </div>
                        ) : (
                            <span className={`${className} segment__stops_totalstops`}>
                                <Tooltip
                                    title={
                                        <>
                                            <span>{arrivalFullNames[indexAirSeg]}</span>
                                        </>
                                    }
                                >
                                    <span>{arrivalNames[indexAirSeg]}</span>
                                </Tooltip>
                            </span>
                        )}
                    </span>
                </>
            ];
        });
        count++;
    });

    return ( 
        <React.Fragment>
            <div className="segment__stops_travelstops">
                {flatSegArray}
            </div>

            <div className="airportNameContainerDiv">
                {airportSegArray}
            </div>
        </React.Fragment>
    );
};


interface PopoverComponentProps {
    timeValue: string;
    key: number;
    stops?: any;
    widthPercentage: any;
    isTimeDifference: any;
}

const PopoverComponent: React.FC<PopoverComponentProps> = ({
    timeValue,
    key,
    stops,
    widthPercentage,
    isTimeDifference,
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const indicatorRef = useRef<HTMLSpanElement>(null);
    const togglePopover = () => setPopoverOpen(!popoverOpen);
    const hours = Math.floor(Number(timeValue) / 60);
    const minutes = Number(timeValue) % 60;
    const formattedDuration = `${hours}h ${minutes}m`;

    return (
        <>
            <span
                key={key}
                className={`${stops && stops >= 2 ? "segment__stops_2" : "segment__stops_1"
                    } point`}
                style={{
                    width: `${widthPercentage}%`,
                    visibility: isTimeDifference
                        ? ("visible" as const)
                        : ("hidden" as const),
                }}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
                onTouchStart={(e) => {
                    e.stopPropagation();
                    setPopoverOpen(true);
                }}
                onTouchEnd={() => setPopoverOpen(false)}
                ref={indicatorRef}
            />
            <Popover
                isOpen={popoverOpen}
                target={indicatorRef}
                toggle={togglePopover}
            >
                <PopoverBody>{formattedDuration}</PopoverBody>
            </Popover>
        </>
    );
};

export default FareLegHeader;