import { faClock, faFileAlt, faInfoCircle, faStar } from "@fortawesome/pro-regular-svg-icons";
import { faCalculator, faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { AvailableFareLegModel, AvailableFareModel, b2cSettingsText, FareIdentifier } from "WebApiClient";
import ShareFareModal from "./Fare.Buttons.ShareFareModal";
import {  useSelector } from "react-redux";


interface FareButtonProps {
    ToggleFlightTimes: () => void;
    ToggleRulesModal: () => void;
    ToggleFlightDetailsModal: () => void;
    ToggleAncillariesModal: () => void;
    ToggleCalculation: () => void;
    ToggleCalculationDebug: () => void;
    ShowCalcDebug: boolean;
    SelectedConnections: number[];
    BookFare: () => void;
    IsFareBooking: boolean;
    Identifier: FareIdentifier;
    Fare: AvailableFareModel
    UpscaleCalculation: (upscalePropertyArray:any,propertyString:string)=>void;
    upSaleToggle:boolean;
    showLegs:boolean;
    upscaleFilterData:any[];
    setupSaleToggle:any;
}

const FareButtons: React.FC<FareButtonProps> = props => {
    const [showSendFareModal, setSendFareModalOpen] = useState(false);

    function ToggleSendFareModal() {
        setSendFareModalOpen(!showSendFareModal);
    }

    const bookingRedirect: any = useSelector((state: any) => state.bookingRedirectSession.value);

    //Get B2C setting because it contains some B2B as well

    const b2cSettingsText = useSelector((state: any) => state.Session.FrontendSettings.b2cSettingsText);
    
    const B2CSettings: b2cSettingsText = (() => {
        try {
        return JSON.parse(b2cSettingsText || "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Left\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":true},\"Business\":{\"Checked\":true,\"preSelect\":true},\"First\":{\"Checked\":true,\"preSelect\":true}},\"showMarqueeFrom\":\"2024-05-20T11:54\",\"showMarqueeText\":\"\",\"showMarqueeTo\":\"2024-05-20T11:55\",\"Style\":\"\",\"priceOption\":\"pricePerPerson\",\"showUpsellB2B\":\"On\",\"showUpsell\":\"On\"}");
        } catch {
        return { HotelTab: "All", AirPortSearchType: "All",showUpsellB2B:"On",showUpsell:"On" };
        }
    })();

    function matchConnIndex(connectionArr:any,targetIndex:number){
        for (let i = 0; i < connectionArr?.connections.length; i++) {
            const connection = connectionArr?.connections[i];
            
            // If the connection's index field matches the targetIndex, return the connection's position
            if (connection.index === targetIndex) {
              return i; // Return the position of this connection in the connections array
            }
        }
    }
    
    function Upscale(stringPass:string){
        const upscalePropertyArray:any = [];
        props.Fare.legs.map((leg:AvailableFareLegModel,indexLeg:number) =>{
            const flightNumberArr:any= [];
            const indexToSearch:any = matchConnIndex(leg,props.SelectedConnections![indexLeg])
            leg?.connections[indexToSearch]?.segments.map((flightNum:any,segIndex:number)=>{
                flightNumberArr.push({flightNumber:flightNum.flightNumber,legNumber:indexLeg});
            })
            upscalePropertyArray.push({
                code: leg?.platingCarrier?.code,
                arrivalDisplayTime: leg?.connections[props.SelectedConnections[indexLeg]]?.connectionHeader?.arrivalDisplayTime,
                departureDisplayTime: leg?.connections[props.SelectedConnections[indexLeg]]?.connectionHeader?.departureDisplayTime,
                codeNumber: flightNumberArr,
                fareIndex:props.Fare.fareIndex
            })
        })
        
        props.UpscaleCalculation(upscalePropertyArray,stringPass);
    }

    useEffect(() => {
        if(props.upSaleToggle || props.showLegs){
            Upscale('connectionTriggeredLeg')
        }
    }, [props.SelectedConnections,props.showLegs])

    const hasBrandNameInLegs = (data:AvailableFareModel) => {
        return data.legs.some(leg => 
            leg.connections.some(connection => 
                connection.segments.some(segment => 
                    segment.hasOwnProperty('brandName') && segment.brandName !== null && segment.brandName !== ""
                )
            )
        );
    };
    
    return (
        <React.Fragment>
            <div className="bg-secondary py-2 px-2 clearfix align-items-center">
                <span className="float-start">
                    {/* {hasDebugInfo &&
                        <button className="btn btn-sm btn-info text-uppercase me-1" onClick={ToggleDebugModal}>
                            <FontAwesomeIcon icon={faBug} className="me-1" />DEBUG</button>
                    } */}
                    <button className="btn btn-sm btn-outline me-1 toggleFlightTimes" onClick={props.ToggleFlightTimes}>
                        <FontAwesomeIcon icon={faClock} className="mr-0 mr-md-1 me-1" />
                        <span className="d-none d-md-inline-block">Flight times</span>
                    </button>
                    <button className="btn btn-sm btn-outline me-1" title="Calculation" onClick={props.ToggleCalculation} >
                        <FontAwesomeIcon icon={faCalculator} className="mr-0 mr-md-1 me-1" />
                        <span className="d-none d-md-inline-block">Calculation</span></button>
                    {props.ShowCalcDebug &&
                        <button className="btn btn-danger btn-sm btn-outline me-1" title="Calculation" onClick={props.ToggleCalculationDebug} >
                            <FontAwesomeIcon icon={faCalculator} className="mr-0 mr-md-1 me-1" />
                            <span className="d-none d-md-inline-block">Calculation details</span></button>
                    }
                    {
                        // (hasBrandNameInLegs(props.Fare) && !props.IsFareBooking) 
                        (!props.IsFareBooking && props.upscaleFilterData.length !=0 && B2CSettings?.showUpsell == "On")
                        && <button
                        onClick={()=>props.setupSaleToggle(!props.upSaleToggle)}
                        className="btn btn-sm btn-outline me-1"
                        type="button" aria-hidden="true">
                        <FontAwesomeIcon icon={faFileAlt} className="mr-0 mr-md-1 me-1" />Upsell
                        </button>
                    }
                </span>
                <span className="float-end">
                    <button
                        onClick={props.ToggleRulesModal}
                        className="btn btn-sm btn-primary text-uppercase ms-1"
                        type="button" aria-hidden="true">
                        <FontAwesomeIcon icon={faFileAlt} className="me-1" />Rules
                    </button>

                    <button
                        onClick={props.ToggleFlightDetailsModal}
                        className="btn btn-sm btn-primary text-uppercase ms-1"
                        type="button" aria-hidden="true">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-1" />Details
                    </button>

                    {/* {!props.IsFareBooking &&
                        <button onClick={props.ToggleAncillariesModal}
                            className="btn btn-sm btn-primary text-uppercase ms-1"
                            type="button" aria-hidden="true">
                            <FontAwesomeIcon icon={faStar} className="me-1" />Ancillaries
                        </button>
                    } */}

                    {bookingRedirect?.checkActiveBtnClicked !== "Rebook" && (
                        !props.IsFareBooking && (
                            <button onClick={props.ToggleAncillariesModal}
                                className="btn btn-sm btn-primary text-uppercase ms-1"
                                type="button" aria-hidden="true">
                                <FontAwesomeIcon icon={faStar} className="me-1" />Ancillaries
                            </button>
                        )
                    )}

                    {!props.IsFareBooking &&
                        <button className="btn btn-sm btn-primary text-uppercase ms-1" onClick={props.BookFare}> <FontAwesomeIcon icon={faShoppingCart} className="me-1" />
                            Book
                        </button>
                    }
                </span>
            </div>
            <ShareFareModal IsOpen={showSendFareModal} Toggle={ToggleSendFareModal} Identifier={props.Identifier} />
        </React.Fragment>
    );
};

export default FareButtons;