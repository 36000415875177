import { faBug, faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BugReportModal } from "components/Shared/BugReportModal";
import { DateDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AvailableFareLegModel,
  AvailableFareModel,
  AvailFaresConnectionCalcDebugDetails,
  AvailFaresConnectionCalcDebugDetailsApiResponse,
  CalculationDetailsModel,
  CalculationResultModel,
  DebugDataModel,
  DebugPaxDataModel,
  FareIdentifier,
  InputDataModel,
} from "WebApiClient";
import FareLeg from "./Fare.Leg";

interface CalculationDebugModalProps {
  IsOpen: boolean;
  Toggle: () => void;
  Identifier: FareIdentifier;
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  GetSelectedConnectionIndexByLegIndex?: any;
  testConnection: any;
  setStatus: (status: boolean) => void;
}

export const CalculationDebugModal: React.FC<CalculationDebugModalProps> = (
  props
) => {
  return (
    <Modal
      size="xl"
      scrollable={true}
      isOpen={props.IsOpen}
      toggle={props.Toggle}
    >
      <ModalHeader toggle={props.Toggle}>Calculation Details</ModalHeader>
      <ModalBody>
        {props.IsOpen && (
          <div className="row">
            {props.Fare.legs?.map((leg, index) => (
              <div className="col-12 mb-2" key={"props.Fare.legs" + index}>
                <CalculationDebugLeg
                  SelectedConnection={props.SelectedConnections[index]}
                  Identifier={props.Fare.identifier!}
                  SetSelectedConnection={props.SetSelectedConnection}
                  Leg={leg}
                  LegIndex={index}
                  Fare={props.Fare}
                  GetSelectedConnectionIndexByLegIndex={
                    props.GetSelectedConnectionIndexByLegIndex
                  }
                  testconnection={props.testConnection(index)}
                  setStatus={props.setStatus}
                />
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {/* <Button color="primary" onClick={FetchRules}>Refresh</Button>{' '} */}
        <Button color="primary" onClick={props.Toggle}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface CalculationDebugLegProps {
  Identifier: FareIdentifier;
  LegIndex: number;
  SelectedConnection?: any;
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Leg: AvailableFareLegModel;
  Fare: AvailableFareModel;
  GetSelectedConnectionIndexByLegIndex: any;
  testconnection: any;
  setStatus: (status: boolean) => void;
}
const CalculationDebugLeg: React.FC<CalculationDebugLegProps> = (props) => {
  const dispatch = useDispatch();
  const client = useAvailableFaresClient();

  const [flightTimes, setflightTimes] = useState(false);
  const ToggleFlightTimes = () => {
    setflightTimes(!flightTimes);
  };

  const [bugReportModalOpen, setBugReportOpen] = useState(false);
  const ToggleBugReportModal = () => {
    setBugReportOpen(!bugReportModalOpen);
  };

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const connectionIdentifier =
    props.Leg.connections[props.SelectedConnection].connectionIdentifier!;
  const fallbackSegments =
    props.Leg.connections[props.SelectedConnection].segments;

  const [response, setResponse] = useState<
    AvailFaresConnectionCalcDebugDetailsApiResponse | undefined
  >(undefined);
  const responseMetaData = response?.responseMetaData;
  const result = response?.result;

  useEffect(() => {
    if (flightTimes) {
      OnRefresh();
    }
  }, [props.SelectedConnection, flightTimes]);

  function OnRefresh() {
    setFetching(true);
    setResponse(undefined);
    setError(false);
    client
      .getCalculationDetails(connectionIdentifier)
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        setResponse(response);
        if (response.result) {
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-1">
            <FareLeg
              LegIndex={props.LegIndex}
              // SelectedConnection={props.SelectedConnection}
              Data={props.Leg}
              key={props.LegIndex}
              ShowFlightTimes={flightTimes}
              SelectConnection={props.SetSelectedConnection}
              ShowLegPlatingCarrier={true}
              Fare={props.Fare}
              ToggleFlightTimes={ToggleFlightTimes}
              GetSelectedConnectionIndexByLegIndex={
                props.GetSelectedConnectionIndexByLegIndex
              }
              SelectedConnection={props.testconnection}
              setStatus={props.setStatus}
              headerOffVisible={true}
              upscaleLength={1000}
            />
          </div>

          <Fragment>
            {flightTimes && (
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {fetching ? (
                      <div className="col-12 mb-2">
                        <WaitingCard />
                      </div>
                    ) : (
                      <div className="col-12 mb-2">
                        <div className="btn-group mb-2" role="group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={OnRefresh}
                          >
                            <FontAwesomeIcon icon={faSync} /> Refresh
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={ToggleBugReportModal}
                          >
                            <FontAwesomeIcon icon={faBug} /> Bug Report
                          </button>
                        </div>
                      </div>
                    )}

                    {result && (
                      <div className="col-12">
                        <CalculationDebugDisplay data={result} />
                      </div>
                    )}
                    {error && (
                      <Fragment>
                        <div className="col-12">
                          <ErrorDisplay data={response?.responseMetaData} />
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
      <BugReportModal
        Toggle={ToggleBugReportModal}
        IsOpen={bugReportModalOpen}
        MetaData={responseMetaData}
      ></BugReportModal>
    </div>
  );
};

const CalculationDebugDisplay: React.FC<{
  data: AvailFaresConnectionCalcDebugDetails;
}> = ({ data }) => {
  const { calculatedResult } = data;
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {calculatedResult.map((r, i) => (
            <div className="col-12 mb-2" key={"calculatedResult" + i}>
              <CalculationResultDisplay data={r} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const CalculationResultDisplay: React.FC<{
  data: CalculationResultModel;
  index: number;
}> = ({ data, index }) => {
  const { calculationDebugPaxInfo, calculationInput, calculationItems } = data;
  return (
    <div className="card">
      <div className="card-header card-header-primary">
        Calculation result #{(index + 1).toString()}
      </div>
      <div className="card-body">
        <div className="row">
          {calculationInput && (
            <div className="col-12 mb-2">
              <InputDataModelDisplay data={calculationInput} />
            </div>
          )}
          <div className="col-12 mb-2">
            <div className="card card-primary">
              <div className="card-header card-header-primary">
                Applied calculations
              </div>
              <div className="card-body card-body-primary">
                <table className="table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Function</th>
                      <th>Passenger</th>
                      <th>DOB</th>
                      <th>Original input</th>
                      <th>Original output</th>
                      <th>Equiv. Input</th>
                      <th>Equiv. Output</th>
                      <th>Equiv. Tax</th>
                      <th>Error</th>
                      <th>Path</th>
                    </tr>
                  </thead>
                  <tbody>
                    {calculationItems.map((c, i) => (
                      <CalculationDetailsRow
                        data={c}
                        key={"calculationItems" + i}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {calculationDebugPaxInfo.map((c, i) => (
            <div className="col-12 mb-2" key={"calculationDebugPaxInfo" + i}>
              <DebugPaxDataModelDisplay data={c} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const CalculationDetailsRow: React.FC<{ data: CalculationDetailsModel }> = ({
  data,
}) => {
  const {
    calculationFunctionName,
    passengerType,
    calculationPath,
    dob,
    equivalentInputPrice,
    equivalentOutputPrice,
    equivalentTax,
    errorMessage,
    originalInputPrice,
    originalOutputPrice,
  } = data;
  const [showPath, setShowPath] = useState(false);
  const ToggleShowPath = () => {
    setShowPath(!showPath);
  };

  return (
    <tr>
      <td>{calculationFunctionName}</td>
      <td>{passengerType}</td>
      <td>
        <DateDisplay date={dob}></DateDisplay>
      </td>
      <td>
        <SharedPriceDisplay price={originalInputPrice} />
      </td>
      <td>
        <SharedPriceDisplay price={originalOutputPrice} />
      </td>
      <td>
        <SharedPriceDisplay price={equivalentInputPrice} />
      </td>
      <td>
        <SharedPriceDisplay price={equivalentOutputPrice} />
      </td>
      <td>{equivalentTax ? equivalentTax.toString() : ""}</td>
      <td>{errorMessage}</td>
      <td>
        {showPath && (
          <span>
            {calculationPath}{" "}
            <button className="btn btn-primary btn-sm" onClick={ToggleShowPath}>
              Hide
            </button>
          </span>
        )}
        {!showPath && (
          <button className="btn btn-primary btn-sm" onClick={ToggleShowPath}>
            show
          </button>
        )}
      </td>
    </tr>
  );
};

const DebugPaxDataModelDisplay: React.FC<{ data: DebugPaxDataModel }> = ({
  data,
}) => {
  const { debugInfo, passengerType } = data;
  return (
    <div className="card">
      <div className="card-header card-header-primary">
        Passenger calculation
      </div>
      <div className="card-body card-body-primary">
        <div className="row">
          <div className="col-12 mb-2">Passenger type: {passengerType}</div>
          <div className="col-12 mb-2">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Valid</th>
                  <th>Invalid because</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                {debugInfo.map((c, i) => (
                  <DebugDataRow data={c} key={"debugInfo" + i} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const InputDataModelDisplay: React.FC<{ data: InputDataModel }> = ({
  data,
}) => {
  const { branch, branchGroup, level, levelTitle, web } = data;
  return (
    <div className="card card-primary">
      <div className="card-header card-header-primary">Input data</div>
      <div className="card-body card-body-primary">
        <div className="row">
          <div className="col-12 mb-2">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Web</th>
                  <th>Branchgroup</th>
                  <th>Branch</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {level.toString()}
                    {levelTitle.length > 0 ? ` - ${levelTitle}` : ""}
                  </td>
                  <td>{web}</td>
                  <td>{branchGroup}</td>
                  <td>{branch}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const DebugDataRow: React.FC<{ data: DebugDataModel }> = ({ data }) => {
  const { guid, invalidityReason, isValid, position } = data;
  return (
    <tr>
      <td>{position ? position.toString() : ""}</td>
      <td>{isValid !== undefined ? (isValid ? "Yes" : "No") : ""}</td>
      <td>{invalidityReason}</td>
      <td>{guid}</td>
    </tr>
  );
};
