import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: {index:0,component:""} }
export const TabStateSlice = createSlice({
    name: 'tabState',
    initialState,
    reducers: {
        tab_State_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { tab_State_Reducer } = TabStateSlice.actions