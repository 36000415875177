import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fare from "components/Fare/Fare";
import { FareFilterApplicability } from "components/Fare/FareFilter.Applicability";
import ErrorBoundary from "components/Shared/ErrorBoundary";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AvailableFareModel } from "WebApiClient";

interface OwnProps {
  TotalFaresAmount: number;
  Fares: AvailableFaresFare[];
  ShowAllLegsActive: boolean;
  ShowAllFlightTimesActive: boolean;
  ShowAllCalculationActive: boolean;
  FaresWhichPassfilter: number;
  ValidUntil: Date;
  BookFare: (payload: SetBookingPayload) => void,
  ShowCalcDebugger: boolean;
  myRefs?: any
}

const AvailalableFaresFares: React.FC<OwnProps> = props => {
  function BookFare(payload: SetBookingPayload) {
    props.BookFare(payload);
  }

  const [expiredModalOpen, setExpiredModalOpen] = useState(false);

  function ToggleExpiredModal() {
    setExpiredModalOpen(!expiredModalOpen);
  }

  const [fareLegBoolArray, setFareLegBoolArray] = useState([]);

  useEffect(() => {
    if(props.Fares.length){
      props.Fares.map((fare:AvailableFaresFare)=>{
        setFareLegBoolArray((prevArray:any) => {
          const exists = prevArray.some((item:any) => item.fareIndex === fare.Fare.fareIndex);
          if (exists) return prevArray; // Return unchanged if duplicate
          return [...prevArray, { fareIndex: fare.Fare.fareIndex, fareLeg: props.ShowAllLegsActive !=undefined?props.ShowAllLegsActive:false }];
        });
      })
    }
  }, [])
  
  
  function RenderFares(setFareLegBoolArray:any,fareLegBoolArray:any,fares: AvailableFaresFare[], allLegsActive: boolean, flightTimesActive: boolean, allCalc: boolean): JSX.Element {
    

    return <React.Fragment>
      {
        fares.map((fare, index) =>
          <ErrorBoundary key={"frs" + index}>
            <Fare
              FareData={fare.Fare}
              ShowAllLegsActive={allLegsActive}
              ShowAllFlightTimesActive={flightTimesActive}
              ShowAllCalculationActive={allCalc}
              Applicability={fare.Applicability}
              key={index!}
              isActivate={true}
              myRefs={props.myRefs!}
              IsFareBooking={false}
              BookFare={BookFare}
              ShowCalcDebugger={props.ShowCalcDebugger}
              TotalFare={props.Fares}
              setFareLegBoolArray={setFareLegBoolArray}
              fareLegBoolArray={fareLegBoolArray}
              isScrollF={"isScrollState"}            />
          </ErrorBoundary>
        )
      }
    </React.Fragment>
  }

  const FilteredFares: JSX.Element = useMemo(() => RenderFares(setFareLegBoolArray,fareLegBoolArray,props.Fares, props.ShowAllLegsActive, props.ShowAllFlightTimesActive, props.ShowAllCalculationActive), [fareLegBoolArray,props.Fares, props.ShowAllLegsActive, props.ShowAllFlightTimesActive, props.ShowAllCalculationActive]);

  return (
    <React.Fragment>
      <div className="fare__selector_container mt-1 mt-md-3">

        {/* FARES */}
        {props.Fares &&
          <div className="fare__selector">
            {FilteredFares}
            {(props.TotalFaresAmount === 0 || props.FaresWhichPassfilter === 0) &&
              <div id="fare__nofilteredresults" className="border border-dark rounded mx-2 mx-md-0 mb-4 p-2 text-center">
                <FontAwesomeIcon icon={faEyeSlash} className="me-1" /> <span className="font-weight-bold">SORRY - NO FARES FOUND</span> <FontAwesomeIcon icon={faEyeSlash} className="ms-1" /><br />
                {props.TotalFaresAmount === 0 &&
                  <p className="mt-2 mb-0 pb-0">Your search returned no results.</p>
                }
                {props.FaresWhichPassfilter === 0 && props.TotalFaresAmount > 0 &&
                  <p className="mt-2 mb-0 pb-0">Please change your filter settings to get more results.</p>
                }
              </div>
            }
          </div>
        }
      </div>
      <Modal centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal}>
        <ModalHeader toggle={ToggleExpiredModal}>Expired</ModalHeader>
        <ModalBody>
          <Alert color="danger">
            <h4 className="alert-heading">This result has expired.</h4>
            <p>Please start a new search.</p>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button onClick={ToggleExpiredModal} color="primary">Ok</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export interface AvailableFaresFare {
  Fare: AvailableFareModel;
  Applicability: FareFilterApplicability
}

export default AvailalableFaresFares;