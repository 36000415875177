import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faConciergeBell,
  faPlaneArrival,
  faPlaneDeparture
} from "@fortawesome/pro-regular-svg-icons";
import { faStar as solidStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as lightStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { DateDisplay } from "components/Shared/DateDisplay";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import {
  ServiceBookingTypeEnumModel,
  ServiceModel,
  ServicePassengerModel,
  ServicePriceModel,
  ServiceReferenceIDModel,
  ServiceSegmentModel,
} from "WebApiClient";
import { AncillaryRelation } from "./AncillaryRelation";
import { AssignablePassengerOption } from "./AncillarySelection";

interface AncillaryProps {
  Service: ServiceModel;
  Passengers: ServicePassengerModel[];
  Prices: ServicePriceModel[];
  AllSegments: ServiceSegmentModel[];
  ToggleAll: boolean;
  OnAddService(obj: ServiceAddObj): void;
  AddedServices: ServiceAddObj[];

  PassengerOptions: AssignablePassengerOption[];
}
export interface GroupedPassenger {
  PassengerOptions: AssignablePassengerOption[];
  Passenger: ServicePassengerModel;
  Prices: GroupedPassengerPrice[];
  Service: ServiceModel;
}
export interface GroupedPassengerPrice {
  Segments: ServiceSegmentModel[];
  Price: ServicePriceModel | undefined;
}
export const Ancillary: React.FC<AncillaryProps> = ({
  Service,
  Passengers,
  Prices,
  AllSegments,
  ToggleAll,
  OnAddService,
  AddedServices,
  PassengerOptions,
}) => {
  const [open, setOpen] = useState(false);
  const [isServiceAdded, setIsServiceAdded] = useState<boolean>(false);
  const {
    bookingCode,
    bookingType,
    code,
    emdRequired,
    extensions,
    freeTextFormat,
    freeTextMode,
    id,
    lastEMDDate,
    referenceIDs,
    refundable,
    serviceCarrier,
    serviceDescription,
    serviceLocation,
    text,
    type,
  } = Service;
  const isRefundable = refundable !== undefined && refundable === true;
  function Toggle() {
    setOpen(!open);
  }
  useEffect(() => {
    setOpen(ToggleAll);
  }, [ToggleAll]);
  function GetPriceReferences(): ServicePriceModel[] {
    let result: ServicePriceModel[] = [];
    referenceIDs.forEach((e) => {
      if (e.priceID.length > 0) {
        result = result.concat(Prices.filter((y) => y.priceID === e.priceID));
      }
    });
    return result;
  }

  const PriceReferences: ServicePriceModel[] = GetPriceReferences();
  const grouped: GroupedPassenger[] = GetGrouped();

  function GetGrouped(): GroupedPassenger[] {
    let result: GroupedPassenger[] = [];
    referenceIDs.forEach((reference) => {
      const price = GetPrice(reference);
      if (
        result.filter((e) => e.Passenger.id! === reference.passengerID)
          .length === 0
      ) {
        const passengerType: ServicePassengerModel | undefined =
          Passengers.find((e) => e.id === reference.passengerID);
        if (passengerType) {
          const availPassengers = PassengerOptions.filter(
            (e) => e.PassengerType === passengerType.typeAbbr
          );
          const newGroup: GroupedPassenger = {
            Passenger: passengerType,
            Prices: [price],
            Service: Service,
            PassengerOptions: availPassengers,
          };
          result.push(newGroup);
        }
      } else {
        result
          .find((e) => e.Passenger.id === reference.passengerID)!
          .Prices.push(price);
      }
    });
    return result;
  }

  function GetPrice(ref: ServiceReferenceIDModel): GroupedPassengerPrice {
    const price: ServicePriceModel = Prices.find(
      (e) => e.priceID === ref.priceID
    )!;
    let segments: ServiceSegmentModel[] = [];
    ref.segmentIDs.forEach((sID) => {
      const s = AllSegments.find((e) => e.id === sID)!;
      segments.push(s);
    });
    const result: GroupedPassengerPrice = { Price: price, Segments: segments };
    return result;
  }

  return (
    <div className="card">
      <AncillaryHeader
        OnClick={Toggle}
        Open={open}
        Prices={PriceReferences}
        Service={Service}
        grouped={grouped}
        AddedServices={AddedServices}
      />
      <Collapse isOpen={open}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-2">
              <div className="card">
                <div className="card-header card-header-primary">
                  Description
                </div>
                <div className="card-body description">
                  <p>
                    {serviceDescription.length > 0 ? serviceDescription : text}
                  </p>
                  {refundable !== undefined && (
                    <p>Refundable: {refundable ? "Yes" : "No "}</p>
                  )}
                  {emdRequired !== undefined && (
                    <p>EMD required: {emdRequired ? "Yes" : "No "}</p>
                  )}
                  {lastEMDDate && (
                    <p>
                      Last EMD date: <DateDisplay date={lastEMDDate} />
                    </p>
                  )}
                  {serviceCarrier.length > 0 && (
                    <p>Service carrier: {serviceCarrier}</p>
                  )}
                </div>
              </div>
            </div>
            {bookingCode.length > 0 && (
              <div className="col-12 mb-2">
                <div className="card">
                  <div className="card-header card-header-primary">
                    Add Service
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* <div className="col-12 mb-4">
                                            <AddServiceSelector groups={grouped} />
                                        </div> */}
                      {grouped.map((group, index) => (
                        <div className="col-12 mb-2" key={"grup" + index}>
                          <div className="row">
                            <AncillaryRelation
                              GroupedPassenger={group}
                              OnAddService={OnAddService}
                              AddedServices={AddedServices}
                              AllSegments={AllSegments}
                              isServiceAdded={setIsServiceAdded}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

interface AncillaryHeaderProps {
  OnClick: () => void;
  Open: boolean;
  Service: ServiceModel;
  Prices: ServicePriceModel[];
  grouped: GroupedPassenger[];
  AddedServices: ServiceAddObj[];
}
const AncillaryHeader: React.FC<AncillaryHeaderProps> = ({
  OnClick,
  Open,
  Service,
  Prices,
  grouped,
  AddedServices,
}) => {

  const { bookingCode, code, text, bookingType, serviceDescription } = Service;

  function renderSegments(): JSX.Element[] {
    const segments: JSX.Element[] = [];
    const uniqueKeys = new Set(); // Create a Set to track unique keys

    grouped.forEach((group, groupIndex) => {
      group.Prices.forEach((price, priceIndex) => {
        price.Segments.forEach((segment, segmentIndex) => {
          const { departure, arrival } = segment;

          // Generate a unique identifier for the segment
          const uniqueKey = `${departure}-${arrival}-${segment.flightNumber}`;

          // Check if this segment has already been added
          if (!uniqueKeys.has(uniqueKey)) {
            uniqueKeys.add(uniqueKey); // Add to the set to prevent duplicates
            const key = `segment_${groupIndex}_${priceIndex}_${segmentIndex}`;

            segments.push(
              <div key={key}>
                <FontAwesomeIcon icon={faPlaneDeparture} /> &nbsp;
                <span className="segment-label">{departure}</span>&nbsp;
                {" - "}
                <FontAwesomeIcon icon={faPlaneArrival} />
                &nbsp;
                <span className="segment-label">{arrival}</span>
              </div>
            );
          }
        });
      });
    });

    return segments; // Return the array of unique segments
  }
  function getPrice() {
    return Prices.map((price) => {
      return (
        price.equivalentPrice?.currencyCode + " " + price.equivalentPrice?.value
      );
    });
  }
  function ServiceAlreadyAdded(): string {
    // Initialize a variable to store the result
    let result = "noMatch"; // Default result is "noMatch"
    if (AddedServices.length) {
      // Check if AddedServices is not empty
      AddedServices.forEach((item) => {
        grouped.forEach((group) => {
          const passengerCheck = group.PassengerOptions.some(
            (passenger) => passenger.Index == item.PassengerIndex
          );

          if (
            passengerCheck &&
            item.Service.code === group.Service.code &&
            item.Service.bookingCode === group.Service.bookingCode
          ) {
            const priceMatch = group.Prices.some(
              (price) =>
                price.Price?.equivalentPrice?.value ===
                item.Price?.equivalentPrice?.value &&
                item.Price.priceID === price.Price?.priceID
            );

            const segmentMatch = group.Prices.some((groupPrice) => {
              return groupPrice.Segments.some((seg) => {
                return (
                  seg.arrival === item.Segments[0].arrival &&
                  seg.departure === item.Segments[0].departure
                );
              });
            });

            if (priceMatch && segmentMatch) {
              result = "fullMatch"; // Set the result to "fullMatch" for a full match
            } else if (priceMatch || segmentMatch) {
              if (result !== "fullMatch") {
                result = "halfMatch";
              } // Set the result to "halfMatch" if either condition is met
            }
          }
        });
      });
    }
    return result;
  }
  return (
    <div className="card-header clickme text-center card-header-primary bg-secondary"
      onClick={OnClick}>
      <div className="d-flex justify-content-between">
        <div className="font-weight-bold float-start w-25 text-start">
          <span>
            <FontAwesomeIcon
              className={
                ServiceAlreadyAdded() === "fullMatch" ||
                  ServiceAlreadyAdded() === "halfMatch"
                  ? "alreadyAdded"
                  : "notAdded"
              }
              icon={
                bookingType === ServiceBookingTypeEnumModel.None
                  ? faConciergeBell
                  : ServiceAlreadyAdded() === "fullMatch"
                    ? solidStar
                    : lightStar
              }
              title="Ancillary"
            />
          </span>
          <span>
            {" "}
            {bookingCode.length > 0 ? bookingCode : code}{" "}
            {/* <ServiceBookingTypeEnumDisplay type={bookingType} /> */}
          </span>
        </div>
        <div className="w-50 text-start">
          {text.length > 0 ? text : serviceDescription}
        </div>
        {/* <div className="w-25 text-end">
          <strong>{getPrice()}</strong>
        </div> */}
        <div className="w-50 float-end text-end">
          <div className="d-flex justify-content-end gap-4 text-end">
            {renderSegments()}
          </div>
        </div>
        <div className="float-end w-25 text-end">
          <FontAwesomeIcon icon={Open ? faAngleDoubleUp : faAngleDoubleDown} />
        </div>
      </div>
    </div>
  );
};
