import { FaresFilterState, FreeBaggageFilterCriteria, IncludedServiceSubFilterCriteria } from "components/AvailableFares/types/AvailableFaresTypes";
import * as moment from "moment";
import { AvailableFareConnectionModel, AvailableFareModel, IncludedServiceData } from "WebApiClient";
export interface FareFilterApplicability {
    Applicable: boolean;
    ApplicableConnections?: Array<number[]>
}

export function FareAppliesToFilter(fare: AvailableFareModel, filter: FaresFilterState): FareFilterApplicability {
    // console.log("its working");
    let applies = true;
    let applicableConnections: Array<number[]> = [];
    if (fare.fareInfoBox) {
        if (filter.FareTypes.length > 0) {
            fare.legs.forEach((leg, legIndex) => {
                let faretype = "";
                if (leg.fareTypeInformation.salesPreferenceDisplayName) {
                    faretype = leg.fareTypeInformation.salesPreferenceDisplayName;
                }
                else {
                    faretype = leg.fareTypeInformation.originalFareType;
                }

                if (!filter.FareTypes.some(e => e.Checked && e.Value === faretype)) {
                    applies = false;
                }

            })
        }
        if (filter.QueryPCCs.length > 0) {
            if (fare.fareInfoBox.queryPccs) {
                const filterQueryPCCs = filter.QueryPCCs.filter(e => e.Checked === true).map(e => e.Value);
                if (!fare.fareInfoBox.queryPccs.some(item => filterQueryPCCs.includes(item))) {
                    // console.log("filter fail: querypcc")
                    applies = false;
                }
            }
        }
        if (filter.BookingPCCs.length > 0) {
            if (fare.fareInfoBox.bookingPccs) {
                const filterBookingPCCs = filter.BookingPCCs.filter(e => e.Checked === true).map(e => e.Value);
                if (!fare.fareInfoBox.bookingPccs.some(item => filterBookingPCCs.includes(item))) {
                    // console.log("filter fail: booking  pocc")
                    applies = false;
                }
            }

        }
        if (filter.Sources.length > 0) {
            if (fare.fareInfoBox.sources) {
                const filterSources = filter.Sources.filter(e => e.Checked === true).map(e => e.Value);
                if (!fare.fareInfoBox.sources.some(item => filterSources.includes(item))) {
                    // console.log("filter fail: source")
                    applies = false;
                }

            }
        }
    }
    const legCount = fare.legs.length;
    if (applies) {
        fare.legs.forEach((leg, legIndex) => {
            let legApplicableConnections: number[] = [];
            if (leg.platingCarrier) {
                if (leg.platingCarrier.code && leg.platingCarrier.code !== "") {
                    if (filter.Airlines.length > 0) {
                        if (!filter.Airlines.some(e => e.Checked && e.Value === leg.platingCarrier!.code!)) {
                            applies = false;
                            // console.log("filter fail: airline")
                            return;
                        }
                    }
                }
                if (leg.platingCarrier.allianceCode && leg.platingCarrier!.allianceCode !== "") {
                    if (filter.Alliances.length > 0) {
                        if (!filter.Alliances.some(e => e.Checked && e.Value === leg.platingCarrier!.allianceCode!)) {
                            applies = false;
                            // console.log("filter fail: alliance")

                            return;
                        }
                    }
                }
            }

            leg.connections!.forEach((connection) => {
                const connectionApplies = ConnectionAppliesToFilter(connection, filter, legIndex);
                if (connectionApplies) {
                    legApplicableConnections.push(connection.index);
                }
            })
            if (legApplicableConnections.length === 0) {
                applies = false;
                return;
            }
            applicableConnections.push(legApplicableConnections);

        })
    }
    if (applicableConnections.length !== legCount) {
        applies = false;
    }
    let result: FareFilterApplicability = { Applicable: false, ApplicableConnections: undefined };
    if (applies) {
        result = { Applicable: true, ApplicableConnections: applicableConnections };
    }
    else {
        result = { Applicable: false }
    }

    return result;
}

function ConnectionAppliesToFilter(connection: AvailableFareConnectionModel, filter: FaresFilterState, legIndex: number): boolean {
    let result = true;
    if (filter.LegsMaxStopAmount.some(x => x.LegIndex === legIndex)) {
        const segmentAmount = connection.segments!.length - 1;
        const value = filter.LegsMaxStopAmount.filter(x => x.LegIndex === legIndex)[0];
        if (segmentAmount > value.MaxStopSelection) {
            // console.log("filter fail: max stop")
            result = false;
        }
    }
    connection.segments!.forEach((segment, segmentIndex) => {
        const isfirstSegment = segmentIndex === 0;
        const isLastSegment = segmentIndex === (connection.segments!.length - 1);

        if (!filter.CabinClasses.some(e => e.Checked && e.Value === segment.cabinClassName)) {
            // console.log("cabin class")
            result = false;
            return;
        }

        const segmentAllotment = segment.allotmentInformation;
        if (segmentAllotment) {
            filter.AllotmentTypes.forEach((elem) => {
                if (elem.Checked) {
                    if (segmentAllotment.types.some(e => e === elem.Value)) {
                        return;
                    }
                } else {
                    result = false
                    return
                }
            })
        }


        const filterMainGroups: IncludedServiceSubFilterCriteria[] = [];
        switch (filter.FreeBaggage) {
            case FreeBaggageFilterCriteria.No:
                if (segment.hasFreeBaggage) {
                    // console.log("filter fail: baggage");

                    result = false;
                    return;
                }
                break;
            case FreeBaggageFilterCriteria.Yes:
                if (!segment.hasFreeBaggage) {
                    // console.log("filter fail: baggage");

                    result = false;
                    return;
                }
                break;
        }


        filter.IncludedService.forEach(elem => {
            elem.Values.forEach(sub => {
                filterMainGroups.push(sub)
            })
        })

        let segmentMainGroups: IncludedServiceData[] = [];
        if (segment.includedServices) {
            segment.includedServices.forEach(elem => {
                if (elem.services) {
                    elem.services.forEach(sub => {
                        segmentMainGroups.push(sub)
                    })
                }
            })
        }


        filterMainGroups.forEach((type) => {
            if (type.Checked) {
                if (!segmentMainGroups.some(e => e.hint!.toLowerCase() === type.Value.toLowerCase())) {
                    result = false;
                    // console.log("filter fail: included service");

                    return;
                }
            }
        })
        if (isfirstSegment) {
            const filterdepartureTime = filter.DepartureTime[legIndex];
            if (SegmentDateNotInRange(filterdepartureTime.MinHour, filterdepartureTime.MaxHour, segment.departureDate)) {
                result = false;
                return;
            }
            if (!filter.DepartureAirports.some(e => e.Checked && e.Value === segment.departureAirport!.iata!)) {
                result = false
                return;
            }


        }
        if (isLastSegment) {
            const filterArrivalTime = filter.ArrivalTime[legIndex];
            if (SegmentDateNotInRange(filterArrivalTime.MinHour, filterArrivalTime.MaxHour, segment.arrivalDate)) {
                result = false;
                // console.log("filter fail: arrival time");
                return;
            }
            if (!filter.ArrivalAirports.some(e => e.Checked && e.Value === segment.arrivalAirport!.iata!)) {
                result = false;
                return;
            }

        }

    })
    return result;
}

function SegmentDateNotInRange(filterMinTime: number, filterMaxTime: number, segmentTime: Date | undefined): boolean {
    if (segmentTime) {
        const segmentHour = moment(segmentTime).hour();
        const segmentMinute = moment(segmentTime).minute();

        const filterMinHoursVal: number = Math.floor(filterMinTime);
        const filterMinMinutesVal: number = ((filterMinTime % 1) * 100) / 100 * 60;

        const filterMaxHoursVal = Math.floor(filterMaxTime);
        const filterMaxMinutesVal = ((filterMaxTime % 1) * 100) / 100 * 60;

        const minTime = filterMinHoursVal + (filterMinMinutesVal / 100);
        const maxTime = filterMaxHoursVal + (filterMaxMinutesVal / 100);
        const timeVal = segmentHour + (segmentMinute / 100);
        const oor = timeVal < minTime || timeVal > maxTime;
        if (oor) {
            return true;
        }

        return false;
    }
    else {
        console.error("TIME IS NULL")
        return false;
    }


}