import {
  faPenToSquare,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay } from "components/Shared/DateDisplay";
import DateInput from "components/Shared/DateInput";
import { GenderEnumDisplay } from "components/Shared/GenderEnumDisplay";
import { useBookingClient } from "hooks/useHttpClient";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { State } from "rootExports/rootReducer";
import {
  AgentGenderEnum,
  Airline,
  BookingIdentifier,
  BookingItemPassengerModel,
  eBookingStatus,
  NameChangeRequestModel,
} from "WebApiClient";

interface BookingVersionItemDetailsBookedFlightProps {
  Data: BookingItemPassengerModel[];
  bookingIdentifier: BookingIdentifier;
  OnCancel?: (passengers: number[]) => void;
  platingCarrier?: Airline;
  refresh?: () => void | any;
  OnSync?: any;
}

const BookingPassengersCard: React.FC<
  BookingVersionItemDetailsBookedFlightProps
> = (props) => {
  const [editPassenger, setEditPassenger] =
    useState<BookingItemPassengerModel>();
  const [IsEdit, setIsEdit] = useState<boolean>(false);
  const [isNameChange, setNameChange] = useState<boolean>(false);

  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{\"textColor\":\"#ffffff\",\"bgColor\":\"#242424\"}\nshowFareHeader=Off\nshowSmartCalender=All/n[{\"value\":\"LH\",\"text\":\"Lufthansa\",\"image\":\"XXXX\"}]/ntrue/ntrue/n\n{\"Economy\":{\"Checked\":true,\"preSelect\":false},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":true,\"preSelect\":false}}\n0\n{\"PassengerGender\":\"Unselected\",\"PassengerDOB\":\"Yes\",\"DOB\":0}\nshowHotelTab=None\n{\"Container\":\"#REBOOKING##NameChangeE9##SPECIALDISCOUNT##NameChangeLH#\"}\nshowCabinContainer=All'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );

  let settingArr = otherSettings.split("\n");
  useEffect(() => {
    const checkSetting = settingArr[7] && settingArr[7].includes("NameChange");
    if (checkSetting) {
      const carrierValueMatch = settingArr[7].match(/NameChange([^#]*)#/);
      const carrierValue = carrierValueMatch ? carrierValueMatch[1] : null;
      if (props.platingCarrier?.code === carrierValue) {
        setNameChange(true);
      } else {
        setNameChange(false);
      }
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header card-header-primary">
        Passengers{" "}
        <span className="badge badge-primary badge-pill align-top">
          {props.Data.length}
        </span>
      </div>
      <div className="card-body">
        <table className="table table-sm table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="font-weight-bold">No.</th>
              <th className="font-weight-bold">Last Name</th>
              <th className="font-weight-bold">First Name</th>
              <th className="font-weight-bold">DOB</th>
              <th className="font-weight-bold">Type</th>
              <th className="font-weight-bold">Gender</th>
              <th className="font-weight-bold">Ticket No.</th>
              {isNameChange && <th className="font-weight-bold">Action</th>}
            </tr>
          </thead>
          <tbody>
            {props.Data.map((pax, paxIndex) => (
              <PassengerItem
                key={"PaxIndex" + paxIndex}
                data={pax}
                BookingIdentifier={props.bookingIdentifier}
                editPassenger={editPassenger}
                setEditPassenger={setEditPassenger}
                IsEdit={IsEdit}
                setIsEdit={setIsEdit}
                isNameChange={isNameChange}
              />
            ))}
          </tbody>
        </table>
        {editPassenger !== undefined && IsEdit && isNameChange && (
          <APISAddComponent
            value={editPassenger}
            setEditPassenger={setEditPassenger}
            IsEdit={IsEdit}
            setIsEdit={setIsEdit}
            BookingIdentifier={props.bookingIdentifier}
            refresh={props.refresh}
            OnSync={props.OnSync}
          />
        )}
      </div>
    </div>
  );
};

export default BookingPassengersCard;

interface Transaction {
  errorCode: string;
  title: string;
  success: boolean;
  errorMessage: string;
  source: string;
  startTime: string;
  endTime: string;
  details: string;
}
interface ErrorReason {
  errorCode: string;
  title: string;
  success: boolean;
  errorMessage: string;
  source: string;
  startTime: string;
  endTime: string;
  details: string;
}
interface ResponseMetaData {
  errorOccured: boolean;
  errorReason: ErrorReason;
  requestTitle: string;
  transactionID: string;
  requestReceivedTime: string;
  responseSentTime: string;
  sessionID: string;
  userName: string;
  branchName: string;
  branchgroupName: string;
  webName: string;
  flightAPINode: string;
  transactions: Transaction[];
  environment: string;
}
interface ApiResponse {
  responseMetaData?: ResponseMetaData;
  result: [];
}
const PassengerItem: React.FC<{
  data: BookingItemPassengerModel;
  BookingIdentifier: BookingIdentifier;
  editPassenger: BookingItemPassengerModel | undefined;
  setEditPassenger: any;
  IsEdit: boolean;
  setIsEdit: any;
  isNameChange: boolean;
}> = ({
  data,
  editPassenger,
  setEditPassenger,
  IsEdit,
  setIsEdit,
  isNameChange,
}) => {
    const {
      birthDate,
      firstName,
      type,
      gender,
      lastName,
      passengerNumber,
      ticketNumbers,
    } = data;


    const [passenger, setPassenger] = useState<BookingItemPassengerModel>(data);
    const OnAddQueueItem = () => {
      setEditPassenger(passenger);
      const updatedPassenger: BookingItemPassengerModel = {
        ...editPassenger,
        ...passenger,
      };
    };

    useEffect(() => {
      setEditPassenger(passenger);
    }, [birthDate, firstName, lastName, gender, type]);

    return (
      <tr>
        <td>{passengerNumber}</td>
        <td>{lastName}</td>
        <td>{firstName}</td>
        <td>
          <DateDisplay
            date={birthDate !== null ? new Date(birthDate) : undefined}
          />
        </td>
        <td>{type}</td>
        <td>
          <GenderEnumDisplay
            gender={
              gender === AgentGenderEnum.Male
                ? AgentGenderEnum.Male
                : gender === AgentGenderEnum.Female
                  ? AgentGenderEnum.Female
                  : AgentGenderEnum.Male // Default to Male if gender is undefined
            }
          />
        </td>
        <td>
          {ticketNumbers.length > 0 && (
            <table className="table table-sm table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th className="font-weight-bold">No.</th>
                  <th className="font-weight-bold">Status</th>
                  <th className="font-weight-bold">Carrier</th>
                  <th className="font-weight-bold">Segments</th>
                  <th className="font-weight-bold">Ticket type</th>
                </tr>
              </thead>
              <tbody>
                {ticketNumbers.map((tkt, tktIndex) => (
                  <tr key={"tkt" + tktIndex}>
                    <td>{tkt.ticketNo}</td>
                    <td>
                      {tkt.ticketStatus && (
                        <span>{eBookingStatus[tkt.ticketStatus]}</span>
                      )}
                    </td>
                    <td>{tkt.ticketingCarrier}</td>
                    <td>{tkt.segmentNumbers.join(",")}</td>
                    <td>{tkt.ticketType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </td>
        {isNameChange && (
          <td
            className="cursor-pointer"
            onClick={(e) => {
              OnAddQueueItem();
              setIsEdit(!IsEdit);
            }}
          >
            <span
              className=" btn-sm cursor-pointer"
              onClick={(e) => {
                OnAddQueueItem();
                setIsEdit(!IsEdit);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} /> Edit
            </span>
          </td>
        )}
      </tr>
    );
  };

const APISAddComponent: React.FC<{
  value: BookingItemPassengerModel | any;
  setEditPassenger: any;
  IsEdit: boolean;
  setIsEdit: any;
  BookingIdentifier: BookingIdentifier;
  refresh?: any;
  OnSync?: any;
}> = (props) => {
  const { setEditPassenger, value, setIsEdit, BookingIdentifier } = props;
  const { birthDate, firstName, gender, lastName } = value;
  const bookingClient = useBookingClient();
  const [isLoading, setIsLoading] = useState(false);


  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it is zero-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const nameChangeRequest: NameChangeRequestModel = {
    passengerChanges: [
      {
        passengerBookingManagerID: value.passengerID,
        newLastName: lastName,
        newFirstName: firstName,
        newDOB: birthDate,
        newTitle: "",
        newGender: gender?.toString(),
        changeAPIS: true,
        changedStatusCode: 0,
      },
    ],
    BookingIndentifier: BookingIdentifier,
  };

  const updatePassenger = async () => {
    try {
      setIsLoading(true);
      const resString: ApiResponse | any = await bookingClient.doNameChange(
        nameChangeRequest
      );
      const res: ApiResponse = JSON.parse(resString);
      if (res.responseMetaData && res.responseMetaData.errorOccured) {
        const errorReason: ErrorReason = res.responseMetaData.errorReason;
        console.log("Error occurred:", errorReason.errorMessage);
        toast.error(errorReason.errorMessage);
      } else {
        // Check if res.result contains the updated data
        toast.success("Your information has been successfully changed.");
        setEditPassenger((prevState: BookingItemPassengerModel) => ({
          ...prevState,
          ...res.result,
        }));

      }
    } catch (error: any) {
      console.error("Error updating passenger data:", error);
      toast.error("Error updating passenger data: " + error?.message);
    } finally {
      setIsEdit(false);
      setIsLoading(false);
      props.OnSync();
      props.refresh();
    }
  };

  return (
    <div className="card">
      <div className="card-header card-header-primary">
        Edit Passenger
        <div className="float-end">
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => {
              setIsEdit(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
        </div>
      </div>
      <div className="card-body card-body-primary">
        <div className="row">

          <div className="col-3">
            <div className="form-group">
              <label className="">Last name</label>
              <input
                value={lastName}
                onChange={(e) => {
                  setEditPassenger({ ...value, lastName: e.target.value });
                }}
                type="text"
                className="form-control form-control-sm"
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label className="">First name</label>
              <input
                value={firstName}
                onChange={(e) => {
                  setEditPassenger({
                    ...props.value,
                    firstName: e.target.value,
                  });
                }}
                type="text"
                className="form-control form-control-sm"
                placeholder="First name"
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <label className="">Date of birth</label>
              <DateInput
                OnDateChange={(date: Date | undefined) => {
                  if (date) {
                    setEditPassenger({
                      ...props.value,
                      birthDate: formatDate(date),
                    });
                  }
                }}
                Value={birthDate ? moment(birthDate).toDate() : undefined}
                InputGroup={false}
                MaxDate={moment().toDate()}
                IsDob={true}
                MinDate={moment().subtract(120, "years").toDate()}
                InputClassNames="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label className="">Gender</label>
              <br></br>
              <select
                value={gender}
                className="form-select"
                onChange={(e) => {
                  setEditPassenger({
                    ...props.value,
                    gender: e.target.value as any as AgentGenderEnum,
                  });
                }}
              >
                <option value={AgentGenderEnum.Unknown}>- select -</option>
                <option value={AgentGenderEnum.Male}>Male</option>
                <option value={AgentGenderEnum.Female}>Female</option>
              </select>
            </div>
          </div>
        </div>
        <button
          className="btn btn-success btn-sm w-100 mt-2"
          onClick={updatePassenger}
          disabled={isLoading}
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <FontAwesomeIcon icon={faPenToSquare} />
          )}{" "}
          Request for name change
        </button>
      </div>
    </div>
  );
};
