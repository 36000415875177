export function capitalizerText(text: string) {
    return text
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export function getFirstTwoLettersCapitalized(text: string | undefined) {
    return text != undefined ? text.slice(0, 2).toUpperCase() : "";
}

export function capitalizeWholeHint(dataText: any) {
    return dataText.map((item: any, index: any) => {
        let hint = item.hint.toLowerCase();

        // Capitalize the first word of the hint in the first object only
        if (index === 0) {
            hint = hint.charAt(0).toUpperCase() + hint.slice(1);
        }

        return { ...item, hint };
    });
}

export function processBrandNames(data: any) {
    let brandName = data[0].brandName.toLowerCase();
  
    // Capitalize only the first word
    brandName = brandName.charAt(0).toUpperCase() + brandName.slice(1);
    return [{ ...data[0], brandName }];
    };

