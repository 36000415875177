import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {  faCircle,faCouch, faInfoCircle, faRandom, faSuitcase, faTv, faUtensils, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";

import { IncludedServiceData, IncludedServiceMainType } from "WebApiClient";

interface IncludedServiceIconProps {
    data: any;
    combinedHints: any;
}

export const incServices: React.FC<IncludedServiceIconProps> = (props) => {
    var mainGroup: any = props.data;
    return <React.Fragment>
        <span style={{ width: "20px", position: "relative", margin: "1px" }}>
            <FontAwesomeIcon icon={GetIcon(mainGroup)}
            />
        </span>
    </React.Fragment>
}

export function GetIcon(data: IncludedServiceData): IconDefinition {
    let iconClass: IconDefinition = faInfoCircle;

    switch (data.subType) {
        // case IncludedServiceSubType.FreeBaggageAllowance:
        //     iconClass = faSuitcase;
        //     break;
        // case IncludedServiceSubType.CarryOnBaggage:
        //     iconClass = faShoppingBag;
        //     break;
        // case IncludedServiceSubType.Lunch:
        //     iconClass = faUtensils;
        //     break;
        // case IncludedServiceSubType.Snack:
        //     iconClass = faCookieBite;
        //     break;
        // case IncludedServiceSubType.SnacksAndBeverage:
        //     iconClass = faUtensils;
        //     break;
        // case IncludedServiceSubType.NonAlcoholicDrink:
        //     iconClass = faCoffee;
        //     break;
        // case IncludedServiceSubType.FreeAlcoholicDrink:
        //     iconClass = faGlassMartini;
        //     break;
        // // INFLIGHT ENTERTAINMENT
        // case IncludedServiceSubType.PersonalEntertainment:
        //     iconClass = faTv;
        //     break;
        // case IncludedServiceSubType.InternetAccess:
        //     iconClass = faWifi;
        //     break;
        // case IncludedServiceSubType.InSeatpower:
        //     iconClass = faPlug;

        //     break;
        // case IncludedServiceSubType.Pet:
        //     iconClass = faPaw;

        //     break;
        // case IncludedServiceSubType.Security:
        //     iconClass = faGavel;

        //     break;
        // case IncludedServiceSubType.PriorityBoarding:
        //     iconClass = faFastForward;

        //     break;
        // case IncludedServiceSubType.Ticket:
        //     iconClass = faTicketAlt;

        //     break;
        // case IncludedServiceSubType.CheckIn:
        //     iconClass = faChalkboardTeacher;

        //     break;
        // case IncludedServiceSubType.ReBooking:
        //     iconClass = faRedo;
        //     break;
        // case IncludedServiceSubType.RefundAllowed:
        //     iconClass = faMoneyCheckEditAlt;
        //     break;
        // case IncludedServiceSubType.PriorityCheckIn:
        //     iconClass = faUserCheck;
        //     break;
        // case IncludedServiceSubType.FastTrack:
        //     iconClass = faTachometerFastest;
        //     break;
        // case IncludedServiceSubType.ChangeAfterDeparture:
        //     iconClass = faUndoAlt;
        //     break;
        // case IncludedServiceSubType.ChangeBeforeDeparture:
        //     iconClass = faUndoAlt;
        //     break;
        // case IncludedServiceSubType.ChangeInCaseOfNoShow:
        //     iconClass = faMapMarkerQuestion;
        //     break;
        // case IncludedServiceSubType.OpenReturnDate:
        //     iconClass = faExchange;
        //     break;
        // case IncludedServiceSubType.Stopover:
        //     iconClass = faMapSigns;
        //     break;
        default:
            switch (data.type) {
                case IncludedServiceMainType.Baggage:
                    iconClass = faSuitcase;
                    break;
                case IncludedServiceMainType.Meal:
                    iconClass = faUtensils;
                    break;
                case IncludedServiceMainType.InFlightEntertainment:
                    iconClass = faTv;
                    break;
                case IncludedServiceMainType.Miles:
                    iconClass = faMedium;
                    break;
                case IncludedServiceMainType.Transfer:
                    iconClass = faRandom;
                    break;
                case IncludedServiceMainType.Seat:
                    iconClass = faCouch;
                    break;
                case IncludedServiceMainType.Other:
                    iconClass = faCircle;
                    break;
            }
    }
    return iconClass;
}

export default incServices