import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AddServiceToBookingRequestModel, BaseApiResponse, BookingAvailableServiceModel, BookingIdentifier, BookingItemServicesManagementModel, BookingServiceRequestModel, ServiceBookingTypeEnumModel, ServiceGroupTypeEnumModel, SSRRequestDataModel } from "WebApiClient";
import { AncillaryGroup } from "./BookingAvailServicesGroup";
import { SelectedServicesOverview } from "./SelectedServicesOverview";
import { toast } from "react-toastify";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";




interface AddBookingServicesProps {
    model: BookingItemServicesManagementModel;
    Identifier: BookingIdentifier;
    OnRefresh: () => void;

}

export interface BookingGroupedService {
    Type: ServiceGroupTypeEnumModel,
    Services: BookingAvailableServiceModel[];

}

export const AddBookingServices: React.FC<AddBookingServicesProps> = ({ model, Identifier, OnRefresh }) => {
    const { availableServices, availableServicesPrices, passengers, segments } = model;
    const [fetching, setFetching] = useState(false);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const bookClient = useBookingClient();
    const [errorOccured, setErrorOccured] = useState(false);

    useEffect(() => {
        setSelectedServices([]);
    }, [availableServices])


    function OnToggleSelection(selection: string) {
        let sCopy = [...selectedServices];
        const iOf = sCopy.findIndex(e => e === selection);
        if (iOf === -1) {
            sCopy.push(selection);
        }
        else {
            sCopy.splice(iOf, 1);
        }
        setSelectedServices(sCopy);
    }

    function OnAddSelectionRange(selectionRange: string[]) {
        let sCopy = [...selectedServices];
        selectionRange.forEach((selection) => {
            const iOf = sCopy.findIndex(e => e === selection);
            if (iOf === -1) {
                sCopy.push(selection);
            }
        })
        setSelectedServices(sCopy);
    }
    function OnRemoveSelectionRange(selectionRange: string[]) {
        let sCopy = [...selectedServices];
        selectionRange.forEach((selection) => {
            const iOf = sCopy.findIndex(e => e === selection);
            if (iOf !== -1) {
                sCopy.splice(iOf, 1);
            }
        })
        setSelectedServices(sCopy);
    }

    // function OnSubmitAdd() {
    //     if (selectedServices.length > 0) {
    //         let ssrRequests: SSRRequestDataModel[] = [];
    //         let serviceRequests: BookingServiceRequestModel[] = [];
    //         selectedServices.forEach((id) => {
    //             availableServices.forEach((availService) => {
    //                 availService.referenceIDs.forEach((availServiceReference) => {
    //                     if (availServiceReference.referenceID === id) {
    //                         if (availService.bookingType === ServiceBookingTypeEnumModel.SVC) {
    //                             let s: BookingServiceRequestModel = { carrier: availService.serviceCarrier, code: availService.bookingCode, text: availService.text, passengerSelection: [availServiceReference.passengerID], segmentSelection: availServiceReference.segmentIDs, date: undefined, location: availService.serviceLocation, quantity: undefined, id: availService.id, extensions: availService.extensions, serviceType: availService.serviceType }
    //                             serviceRequests.push(s);
    //                         }
    //                         if (availService.bookingType === ServiceBookingTypeEnumModel.SSR) {
    //                             let r: SSRRequestDataModel = { carrierCode: availService.serviceCarrier, code: availService.bookingCode, freeText: availService.text, passengerSelection: [availServiceReference.passengerID], segments: availServiceReference.segmentIDs }
    //                             ssrRequests.push(r);
    //                         }
    //                     }
    //                 })
    //             })
    //         })
    //         let request: AddServiceToBookingRequestModel = { bookingIndentifier: Identifier, services: serviceRequests, ssrServices: ssrRequests }

    //         setFetching(true);
    //         bookClient.addServices(request)
    //             .then(response => {
    //                 setResponse(response);
    //                 dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
    //                 if (response.responseMetaData.errorOccured) {
    //                     setErrorOccured(true);
    //                 }
    //             })
    //             .catch(() => {
    //                 setErrorOccured(true);
    //             })
    //             .finally(() => {
    //                 setFetching(false);
    //             })
    //     }



    // }


    function OnSubmitAdd() {
        if (selectedServices.length > 0) {
            let ssrRequests: SSRRequestDataModel[] = [];
            let serviceRequests: BookingServiceRequestModel[] = [];
            selectedServices.forEach((id) => {
                availableServices.forEach((availService) => {
                    availService.referenceIDs.forEach((availServiceReference) => {
                        if (availServiceReference.referenceID === id) {
                            if (availService.bookingType === ServiceBookingTypeEnumModel.SVC) {
                                let s: BookingServiceRequestModel = { carrier: availService.serviceCarrier, code: availService.bookingCode, text: availService.text, passengerSelection: [availServiceReference.passengerID], segmentSelection: availServiceReference.segmentIDs, date: undefined, location: availService.serviceLocation, quantity: undefined, id: availService.id, extensions: availService.extensions, serviceType: availService.serviceType }
                                serviceRequests.push(s);
                            }
                            if (availService.bookingType === ServiceBookingTypeEnumModel.SSR) {
                                let r: SSRRequestDataModel = { carrierCode: availService.serviceCarrier, code: availService.bookingCode, freeText: availService.text, passengerSelection: [availServiceReference.passengerID], segments: availServiceReference.segmentIDs }
                                ssrRequests.push(r);
                            }
                        }
                    })
                })
            })
            let request: AddServiceToBookingRequestModel = { bookingIndentifier: Identifier, services: serviceRequests, ssrServices: ssrRequests }

            setFetching(true);
            bookClient.addServices(request)
                .then(response => {
                    setResponse(response);
                    toast.success("Service added successfully");
                    dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                    if (response.responseMetaData.errorOccured) {
                        setErrorOccured(true);
                        const errorMessage = typeof response.responseMetaData.errorReason === 'string'
                            ? response.responseMetaData.errorReason
                            : "An error occurred";
                        toast.error(errorMessage);
                    }
                })
                .catch((e) => {
                    setErrorOccured(true);
                    toast.error(e?.message)
                })
                .finally(() => {
                    setFetching(false);
                    OnRefresh()
                })
        }
    }

    const grouped: BookingGroupedService[] = GetGrouped(availableServices);
    function GetGrouped(services: BookingAvailableServiceModel[]): BookingGroupedService[] {
        let result: BookingGroupedService[] = [];
        services.forEach(service => {
            if (result.filter(e => e.Type === service.type).length === 0) {

                const newGroup: BookingGroupedService = { Type: service.type, Services: [service] }
                result.push(newGroup);
            }
            else {
                result.filter(e => e.Type === service.type)[0].Services.push(service);
            }
        })
        return result;
    }


    return <div className="row">
        {fetching &&
            <div className="col-12">
                <WaitingCard />
            </div>
        }
        {!fetching &&
            <Fragment>

                {errorOccured &&
                    <div className="col-12 mb-2">
                        <ErrorDisplay data={response?.responseMetaData} />
                    </div>
                }
                {response &&

                    <div className="col-12 mb-2">
                        <Alert color={errorOccured ? "danger" : "success"}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    {errorOccured &&
                                        <span>Services could not be added.</span>
                                    }
                                    {!errorOccured &&
                                        <span>Services added.</span>
                                    }

                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" onClick={OnRefresh}>Refresh</button>

                                </div>
                            </div>
                        </Alert>
                    </div>
                }

                {!response &&
                    <Fragment>


                        <div className="col-12 mb-2">
                            <div className="card card-primary">

                                <div className="card-body card-body-primary">
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <SelectedServicesOverview OnConfirm={OnSubmitAdd} AvailableServices={availableServices} Passengers={passengers} Segments={segments} AvailableServicesPrices={availableServicesPrices} SelectedServices={selectedServices} OnAddSelectionRange={OnAddSelectionRange} OnRemoveSelectionRange={OnRemoveSelectionRange} OnToggleSelection={OnToggleSelection} />
                                        </div>
                                        {grouped.map((group, serviceIndex) =>
                                            <div className="col-12 mb-2" key={"serI_" + serviceIndex}>
                                                <AncillaryGroup Group={group} Passengers={passengers} Segments={segments} Prices={availableServicesPrices} SelectedServices={selectedServices} OnAddSelectionRange={OnAddSelectionRange} OnRemoveSelectionRange={OnRemoveSelectionRange} OnToggleSelection={OnToggleSelection} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }



            </Fragment>
        }


    </div>
}









