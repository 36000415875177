import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse } from "reactstrap";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faMoneyBill,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import { SettingsOrder } from "WebApiClient";
import { State } from "rootExports/rootReducer";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import _ from "lodash";

interface IPaymentData {
  WebLevel?: SettingsOrder;
  setPaymentBOFDetails: any;
  IsOpen: any;
  Toggle: any;
  paymentBOFDetails: any;
  setPaymentDataBackend: any;
  paymentValid: any;
  paymentDataIndex: number
  isPerPerson: boolean | null;
  PassengersAmount: number;
}

const PaymentDataTab: React.FC<IPaymentData> = (props) => {
  const [finalExtractValues, setFinalExtractValues] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<{ [key: string]: string | number | boolean }>({});
  const [globalDefaultValue, setGlobalDefaultValue] = useState<{ [key: string]: string | number | null | boolean }>({});

  const paymentDataSettings: string = useSelector((state: State) => state.Session.FrontendSettings.paymentDataSettings);

  const settings = paymentDataSettings.length
    ? JSON.parse(paymentDataSettings)
    : null;

  useEffect(() => {
    if (settings?.ID) {
      const arrayOfObjects = [];
      for (let i = 1; settings[`FieldType${i}`] !== undefined; i++) {
        const newObj = {
          [`Title${i}`]: settings[`Title${i}`]?.trim() || null,
          [`Code${i}`]: settings[`Code${i}`]?.trim() || null,
          [`Minlen${i}`]: settings[`Minlen${i}`] || null,
          [`MaxLen${i}`]: settings[`MaxLen${i}`] || null,
          [`FieldType${i}`]: settings[`FieldType${i}`] || null,
          [`Default${i}`]: settings[`Default${i}`]?.trim() || null,
          [`Visible${i}`]: settings[`Visible${i}`]?.trim() || null,
          [`PerPerson${i}`]: settings[`PerPerson${i}`] || null,
          [`PerLeg${i}`]: settings[`PerLeg${i}`] || null,
        };
        arrayOfObjects.push(newObj);
      }

      const filteredArray = arrayOfObjects.filter(
        (obj, index) =>
          obj[`Code${index + 1}`] !== null &&
          obj[`Visible${index + 1}`] !== null &&
          obj[`Visible${index + 1}`] !== "0" &&
          obj[`FieldType${index + 1}`] !== "2"
      );

      setFinalExtractValues(arrayOfObjects);
      props.setPaymentDataBackend(filteredArray);
    }
  }, [settings?.ID]);

  useEffect(() => {
    const defaultValueArr: any[] = finalExtractValues;
    const newDefaultValue: { [key: string]: string | boolean } = {};
    const newGlobalDefaultValue: { [key: string]: string } = {};
    const newPropertyValueArray: any = [];

    defaultValueArr.forEach((name, index) => {
      const propertyName: string = name[`Code${index + 1}`];
      const propertyLeg: string = name[`PerLeg${index + 1}`];

      if (checkDuplicationCode().includes(propertyName) && propertyName != null && name[`PerPerson${index + 1}`] !== null) {
        newPropertyValueArray.push(name[`Default${index + 1}`]);
        if (propertyName !== undefined && name[`FieldType${index + 1}`] !== "2") {
          newDefaultValue[propertyName] = newPropertyValueArray == null ? "" : newPropertyValueArray;
          if (propertyLeg != null) {
            newDefaultValue['isperLeg'] = propertyLeg == null ? false : propertyLeg;
          }
        }
      }

      if (!checkDuplicationCode().includes(propertyName) && propertyName != null && name[`PerPerson${index + 1}`] !== null) {
        const propertyValue: string = name[`Default${index + 1}`];
        if (propertyName !== undefined && name[`FieldType${index + 1}`] !== "2") {
          newDefaultValue[propertyName] = propertyValue == null ? "" : propertyValue;
        }
        if (propertyLeg != null) {
          newDefaultValue['isperLeg'] = propertyLeg == null ? false : propertyLeg;
        }
        newPropertyValueArray.push(null);
      }
    });

    defaultValueArr.forEach((name, index) => {
      const propertyName: string = name[`Code${index + 1}`];
      const propertyLeg: string = name[`PerLeg${index + 1}`];
      if (checkDuplicationCode().includes(propertyName) && propertyName != null && name[`PerPerson${index + 1}`] == null) {
        newPropertyValueArray.push(name[`Default${index + 1}`]);
        if (propertyName !== undefined && name[`FieldType${index + 1}`] !== "2") {
          newGlobalDefaultValue[propertyName] = newPropertyValueArray == null ? "" : newPropertyValueArray;
        }
        if (propertyLeg != null) {
          newDefaultValue['isperLeg'] = propertyLeg == null ? false : propertyLeg;
        }
      }
      if (!checkDuplicationCode().includes(propertyName) && propertyName != null && name[`PerPerson${index + 1}`] == null) {
        const propertyValue: string = name[`Default${index + 1}`];
        if (propertyName !== undefined && name[`FieldType${index + 1}`] !== "2") {
          newGlobalDefaultValue[propertyName] = propertyValue == null ? "" : propertyValue;
        }
        if (propertyLeg != null) {
          newDefaultValue['isperLeg'] = propertyLeg == null ? false : propertyLeg;
        }
        newPropertyValueArray.push(null);
      }
    });

    //include passenger number also
    const finalDefault: { [key: string]: string | number | boolean } = { ...newDefaultValue, passengerSelection: props.paymentDataIndex + 1 };
    const globalDefault: { [key: string]: string | number | boolean | null } = { ...newGlobalDefaultValue, passengerSelection: null };

    setDefaultValue(finalDefault);
    setGlobalDefaultValue(globalDefault);
  }, [finalExtractValues]);

  // console.log("defaultBO-------------", props.paymentBOFDetails);

  useEffect(() => {
    const tempArray = props.paymentBOFDetails;
    tempArray[props.paymentDataIndex] = defaultValue;
    props.setPaymentBOFDetails(tempArray);
  }, [defaultValue]);

  useEffect(() => {
    const tempArray = props.paymentBOFDetails;
    tempArray[props.PassengersAmount] = globalDefaultValue;
    props.setPaymentBOFDetails(tempArray);
  }, [globalDefaultValue]);

  const checkDuplicationCode = () => {
    // Extract the codes into an array
    const codes = finalExtractValues.map(obj => {
      // Get all the code fields dynamically
      return Object.keys(obj).filter(key => key.startsWith('Code')).map(key => obj[key]);
    }).flat();
    // Count occurrences of each code
    const codeCounts = _.countBy(codes);
    // Filter to get only repeating codes
    const repeatingCodes = _.keys(_.pickBy(codeCounts, count => count > 1));
    return repeatingCodes;
  }

  const handleInputChange = (
    key: any,
    value: any,
    max: any,
    min: any,
    type: number,
    index: number,
  ) => {
    console.log(key, value, type, index);

    if (key?.toString().length >= 2 && type === 2) {

      const trimmedValue = value.trim();
      const trimmedKey = key.toString().trim();
      const arr: string[] = [];

      if (
        trimmedValue.length >= 2 &&
        trimmedValue.length <= max &&
        trimmedValue.length >= min
      ) {
        ;

        finalExtractValues.forEach((row, index) => {
          if (row[`FieldType${index + 1}`] === "2") {
            const rowWithRC = Object.entries(row).find(([key, values]) =>
              key.startsWith("Default") &&
              (values as string).includes(trimmedValue)
            );
            const rowWithRC1: string = rowWithRC ? (rowWithRC[1] as string) : "";
            const splitValues = rowWithRC1.split(",").map((val) => {
              const valuePart = val.split("=")[0].trim();
              if (valuePart !== value.trim()) {
                arr.push(valuePart);
              }
            });
            if (!splitValues.length) {
              console.log("rowWithRC1 is null or undefined.");
            }
          }
        });

        if (props.isPerPerson !== null) {
          const newVar: any = {
            ...props.paymentBOFDetails[props.paymentDataIndex],
            [trimmedKey]: trimmedValue,
            passengerSelection: props.paymentDataIndex + 1,
          };

          const filteredObj = Object.fromEntries(Object.entries(newVar).filter(([_, value]) => value !== "" && value !== null));
          const tempArray = props.paymentBOFDetails;
          tempArray[props.paymentDataIndex] = filteredObj;
          props.setPaymentBOFDetails(tempArray);
        } else {

          const newVar: any = {
            ...props.paymentBOFDetails[props.PassengersAmount],
            [trimmedKey]: trimmedValue,
            passengerSelection: null,
          };

          const filteredObj = Object.fromEntries(Object.entries(newVar).filter(([_, value]) => value !== "" && value !== null));
          // console.log('new Arr - ',key,value,type,newVar,arr,filteredObj,props.paymentBOFDetails)
          const tempArray = props.paymentBOFDetails;
          tempArray[props.PassengersAmount] = filteredObj;
          props.setPaymentBOFDetails(tempArray);
        }
      }
    } else {

      if (props.isPerPerson !== null) {

        if (value?.length <= max && type !== 2) {
          const repeatingCodeArray = checkDuplicationCode()
          const tempArray = [...props.paymentBOFDetails]; //Create a shallow copy of the array
          // console.log('key - ',key,value,index,props.paymentBOFDetails,tempArray[props.paymentDataIndex].hasOwnProperty(key))
          if (!tempArray[props.paymentDataIndex].hasOwnProperty(key)) {
            tempArray[props.paymentDataIndex][key] = [];
          }
          if (repeatingCodeArray?.includes(key)) {
            if (tempArray[props.paymentDataIndex][key] == "") {
              tempArray[props.paymentDataIndex][key] = [];
            }
            if (Array.isArray(tempArray[props.paymentDataIndex][key])) {
              tempArray[props.paymentDataIndex][key][index] = value.trim()
              tempArray[props.paymentDataIndex] = { ...tempArray[props.paymentDataIndex], passengerSelection: props.paymentDataIndex + 1 };
            }
          }
          if (!repeatingCodeArray?.includes(key)) {
            tempArray[props.paymentDataIndex] = { ...tempArray[props.paymentDataIndex], [key]: value.trim(), passengerSelection: props.paymentDataIndex + 1 };
          }
          props.setPaymentBOFDetails(tempArray);
        }
      } else {
        if (value?.length <= max && type !== 2) {
          const repeatingCodeArray = checkDuplicationCode()
          const tempArray = [...props.paymentBOFDetails]; //Create a shallow copy of the array
          // console.log('key - ',key,value,index,props.paymentBOFDetails,tempArray[props.paymentDataIndex].hasOwnProperty(key))

          if (!tempArray[props.PassengersAmount].hasOwnProperty(key)) {
            tempArray[props.PassengersAmount][key] = [];
          }

          if (repeatingCodeArray?.includes(key)) {
            console.log("yes", tempArray[props.PassengersAmount][key]);

            if (tempArray[props.PassengersAmount][key] == "") {
              tempArray[props.PassengersAmount][key] = [];
            }

            const tempData = tempArray.find(item => item.hasOwnProperty(key));

            if (tempData && Array.isArray(tempData[key])) {
              tempData[key] = tempData[key].filter((value: any) => value !== null);
            }
            console.log("hhh", tempData[key]);
            if (Array.isArray(tempData[key])) {
              console.log("helloo", props.PassengersAmount, tempArray[props.PassengersAmount][key][index]);
              tempArray[props.PassengersAmount][key][index] = value.trim()
              tempArray[props.PassengersAmount] = { ...tempArray[props.PassengersAmount], passengerSelection: null };
            }
          }

          if (!repeatingCodeArray?.includes(key)) {
            tempArray[props.PassengersAmount] = { ...tempArray[props.PassengersAmount], [key]: value.trim(), passengerSelection: null };
            console.log("heloo", tempArray, tempArray[props.paymentDataIndex], props.paymentDataIndex, key);
          }
          props.setPaymentBOFDetails(tempArray);
        }
      }
    }
  };


  const getAllCode = () => {
    const paymentData = finalExtractValues;

    const filteredArray = paymentData.filter(
      (obj, index) =>
        obj[`Code${index + 1}`] !== null &&
        obj[`Visible${index + 1}`] !== "1" &&
        obj[`PerPerson${index + 1}`] == props.isPerPerson &&
        obj[`FieldType${index + 1}`] !== "2"
    );

    filteredArray.forEach((item) => {
      const key: any = Object.keys(item).find((prop) =>
        prop.startsWith("Code")
      );
      if (item[`Visible${key.slice(-1)}`] !== "0" && item[`PerPerson${key.slice(-1)}`] == props.isPerPerson && key) {
        const obj = { [item[key]]: "" };
        const tempArray = props.paymentBOFDetails;
        tempArray[props.paymentDataIndex] = obj;
        props.setPaymentBOFDetails(tempArray);
      }
    });
  };



  useEffect(() => {
    getAllCode();
  }, [finalExtractValues]);

  function checkDataValidity(finalExtractValues: any[]) {
    return finalExtractValues?.some((row, index) => {
      const code = row[`Code${index + 1}`];
      // console.log("row[`PerPerson${index + 1}`]", row[`PerPerson${index + 1}`]);

      const isVisible = row[`Visible${index + 1}`] !== null && row[`Visible${index + 1}`] !== "0" && row[`PerPerson${index + 1}`] == props.isPerPerson;
      // Return true if there's a code and it's visible
      return code !== null && isVisible;
    });
  }

  const isValid = checkDataValidity(finalExtractValues);
  const allValuesNull = finalExtractValues.every((obj: any) => Object.values(obj).every((value) => value === null || value === ""));
  return !allValuesNull && isValid ? (
    <div className="row mb-3">
      <div className="col">
        <Button
          color="secondary"
          block
          onClick={props.Toggle}
          className="text-black"
        >
          <h6>
            <span className="float-start">
              <ValidIndicatorIcon
                IsValid={props.paymentValid}
                Icon={faMoneyBill}
                NoValueSet={false}
              />
              {" Payment data"}
            </span>
            <span className="float-end">
              <FontAwesomeIcon
                icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </span>
          </h6>
        </Button>

        <Collapse isOpen={props.IsOpen}>
          <div className="card">
            <div className="card-body">
              {finalExtractValues?.map((row, index) => {
                const code = row[`Code${index + 1}`];
                const isPerPerson = row[`PerPerson${index + 1}`] == props.isPerPerson;
                const isVisible = row[`Visible${index + 1}`] !== null && row[`Visible${index + 1}`] !== "0" && isPerPerson;
                if (code !== null && isVisible) {
                  const personKey = `PerPerson${index + 1}`;
                  const minLengthKey = `Minlen${index + 1}`;
                  const maxLengthKey = `MaxLen${index + 1}`;
                  const fieldTypeKey = `FieldType${index + 1}`;
                  const isValidField = row[minLengthKey] !== null || row[minLengthKey] !== undefined;
                  // Determine which passenger index to use
                  const passengerIndex = row[personKey] !== null ? props.paymentDataIndex : props.PassengersAmount;
                  // Safely access the value for the input
                  const paymentDetails = props?.paymentBOFDetails?.[passengerIndex]?.[code];
                  const isCodeInDuplicationList = checkDuplicationCode().includes(code);
                  // Calculate the final value based on conditions
                  const inputValue = isCodeInDuplicationList && Array.isArray(paymentDetails) ? paymentDetails.filter((item): item is string => item !== null)[index] ?? "" : paymentDetails ?? "";
                  const valid = props?.paymentBOFDetails[passengerIndex][code]?.length >= row[`Minlen${index + 1}`] && props?.paymentBOFDetails[passengerIndex][code]?.length <= row[`MaxLen${index + 1}`];

                  return (
                    <div key={index}
                      className="row align-items-center mb-2"
                      style={{ display: "flex", marginBottom: "8px" }}>
                      <div className="col-12 col-md-5 align-middle">
                        {row[`FieldType${index + 1}`] === "2" ? (
                          <SelectFiled
                            row={row}
                            handleInputChange={handleInputChange}
                            index={index}
                            isPerPerson={isPerPerson}
                          />
                        ) : (
                          <div className="d-flex">
                            <label
                              className="col-12 col-md-5 align-middle"
                              htmlFor={`ProjectNumber-${index}`}
                            >
                              {row[`Title${index + 1}`]} ({code})
                            </label>
                            <div className=" col-12 col-md-7 align-middle">
                              <input
                                className={`form-control ${isValidField ? (valid ? "is-valid" : "is-invalid") : ""}`}
                                type={row[fieldTypeKey] === "1" ? "number" : "text"}
                                placeholder=""
                                minLength={row[minLengthKey] || undefined} // Use `undefined` if `minLength` is null to avoid warning
                                maxLength={row[maxLengthKey] || undefined} // Use `undefined` if `maxLength` is null to avoid warning
                                value={inputValue} // Assign the calculated value
                                onChange={(e) =>
                                  handleInputChange(
                                    code,
                                    e.target.value,
                                    row[maxLengthKey],
                                    row[minLengthKey],
                                    row[fieldTypeKey] == null ? 1 : parseInt(row[fieldTypeKey]),
                                    index
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  ) : null;
};
export default PaymentDataTab;

interface SelectFiled {
  row: any;
  handleInputChange: any;
  index: number;
  isPerPerson: boolean;
}

export const SelectFiled: React.FC<SelectFiled> = ({
  row,
  index,
  handleInputChange,
  isPerPerson
}) => {
  const [DP, setDP] = useState("None");
  return (
    <div className="d-flex">
      <label
        className="col-12 col-md-5 align-middle"
        htmlFor={`ProjectNumber-${index}`}>
        {row[`Title${index + 1}`]} (
        {row[`Code${index + 1}`]?.length == 0 ? "" : row[`Code${index + 1}`]})
      </label>
      <div className="col-12 col-md-7 align-middle">
        <FormControl
          variant="outlined"
          className="d-dropdown"
          sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            id={`demo-simple-select-standard-label-${index}`}
          ></InputLabel>
          <select
            // className={`form-control ${ valid ? "is-valid" : "is-invalid" }`}
            className="form-select form-select-lg"
            id={`demo-simple-select-standard`}
            value={DP != "None" ? DP : ""}
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleInputChange(
                row[`Code${index + 1}`],
                selectedValue,
                row[`MaxLen${index + 1}`],
                row[`Minlen${index + 1}`],
                parseInt(row[`FieldType${index + 1}`]),
                index,
                row[`PerPerson${index + 1}`] == null ? null : row[`PerPerson${index + 1}`]
              );
              // setCode(selectedValue);
              setDP(selectedValue);
            }}
          >
            <option>Select</option>
            {row[`Default${index + 1}`].split(",").map((option: any, optionIndex: number) => {
              const [value, label] = option.split("=");
              return (
                <option key={optionIndex} value={value}>
                  <li>
                    {value} - {label}
                  </li>
                </option>
              );
            })}
          </select>
        </FormControl>
      </div>
    </div >
  );
};
