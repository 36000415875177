import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCircle,
  faConciergeBell,
  faCouch,
  faFrown,
  faRandom,
  faStar,
  faSuitcase,
  faUtensils,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { ServiceBookingTypeEnumDisplay } from "components/Shared/ServiceBookingTypeEnumDisplay";
import React, { Fragment, useState } from "react";
import { Alert, Collapse } from "reactstrap";
import {
  BookingAvailableServiceModel,
  BookingAvailableServiceReferenceIDModel,
  BookingItemServicePassengerModel,
  PnrResponseSegmentModel,
  ServiceBookingTypeEnumModel,
  ServiceGroupTypeEnumModel,
  ServicePriceModel,
} from "WebApiClient";
import { BookingGroupedService } from "./AddBookingServices";

interface AncillaryGroupProps {
  Group: BookingGroupedService;
  Passengers: BookingItemServicePassengerModel[];
  Segments: PnrResponseSegmentModel[];
  Prices: ServicePriceModel[];
  SelectedServices: string[];
  OnRemoveSelectionRange: (selectionRange: string[]) => void;
  OnAddSelectionRange: (selectionRange: string[]) => void;
  OnToggleSelection: (selection: string) => void;
}
export const AncillaryGroup: React.FC<AncillaryGroupProps> = ({
  Group,
  Passengers,
  Prices,
  Segments,
  OnAddSelectionRange,
  OnRemoveSelectionRange,
  OnToggleSelection,
  SelectedServices,
}) => {
  const { Services, Type } = Group;
  const [open, setOpen] = useState(false);
  const [toggleAllServices, setToggleAllServices] = useState<boolean>(false);
  // const filteredServices = ShowIncluded ? Services : Services.filter(e => e.bookingCode.length > 0)
  const filteredServices = Services;
  // useEffect(() => {
  //     setOpen(ToggleAll);
  // }, [ToggleAll])
  function Toggle() {
    setOpen(!open);
  }
  return (
    <div className="card">
      <AncillaryGroupHeaderComponent OnClick={Toggle} Open={open} Type={Type} />
      <Collapse isOpen={open}>
        <div className="card-body">
          <div className="row">
            {/* <div className="col-12 mb-2">
                        <button className="btn btn-primary btn-sm float-end" onClick={() => { setToggleAllServices(!toggleAllServices) }}>Expand/Collapse all services</button>
                    </div> */}
            {filteredServices.map((service, index) => (
              <div key={"filSer_" + index} className="col-12 mb-2">
                <ServiceDisplay
                  Passengers={Passengers}
                  segments={Segments}
                  service={service}
                  Prices={Prices}
                  SelectedServices={SelectedServices}
                  OnAddSelectionRange={OnAddSelectionRange}
                  OnRemoveSelectionRange={OnRemoveSelectionRange}
                  OnToggleSelection={OnToggleSelection}
                />
              </div>
            ))}
            {filteredServices.length === 0 && (
              <div className="col-12 mb-2">
                <Alert color="info">
                  <h4 className="alert-heading">
                    <FontAwesomeIcon icon={faFrown} /> No services to display.
                    Please change filter to show services
                  </h4>
                </Alert>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

interface AncillaryGroupHeaderComponentProps {
  Type: ServiceGroupTypeEnumModel;
  OnClick: () => void;
  Open: boolean;
}

const AncillaryGroupHeaderComponent: React.FC<
  AncillaryGroupHeaderComponentProps
> = (props) => {
  function GetIconAndLabel(): AncillaryHeaderInfo {
    let icon = faCircle;
    let label = "Other";
    switch (props.Type) {
      case ServiceGroupTypeEnumModel.Baggage:
        label = "Baggage";
        icon = faSuitcase;
        break;
      case ServiceGroupTypeEnumModel.Meal:
        label = "Meal";
        icon = faUtensils;
        break;
      case ServiceGroupTypeEnumModel.Seat:
        label = "Seat";
        icon = faCouch;
        break;
      case ServiceGroupTypeEnumModel.Transfer:
        label = "Transfer";
        icon = faRandom;
        break;
    }
    return { Icon: icon, Label: label };
  }
  const Data = GetIconAndLabel();

  return (
    <div
      className="card-header card-header-primary bg-secondary px-3 py-2 d-flex clickme"
      onClick={props.OnClick}
    >
      <h6 className="w-100 text-center">
        <span>
          <FontAwesomeIcon icon={Data.Icon} /> {Data.Label}
        </span>{" "}
        <span className="float-end mr-0">
          <FontAwesomeIcon
            icon={props.Open ? faAngleDoubleUp : faAngleDoubleDown}
          />
        </span>
      </h6>
    </div>
  );
};

interface AncillaryHeaderInfo {
  Icon: IconProp;
  Label: string;
}

interface ServiceDisplayProps {
  service: BookingAvailableServiceModel;
  Passengers: BookingItemServicePassengerModel[];
  segments: PnrResponseSegmentModel[];
  Prices: ServicePriceModel[];
  SelectedServices: string[];
  OnRemoveSelectionRange: (selectionRange: string[]) => void;
  OnAddSelectionRange: (selectionRange: string[]) => void;
  OnToggleSelection: (selection: string) => void;
}
const ServiceDisplay: React.FC<ServiceDisplayProps> = ({
  service,
  Passengers,
  segments,
  SelectedServices,
  OnAddSelectionRange,
  OnRemoveSelectionRange,
  OnToggleSelection,
  Prices,
}) => {
  const [open, setOpen] = useState(false);

  function GetFilteredReferenceID(): string[] {
    return service.referenceIDs
      .filter((e) => e.priceID.length > 0)
      .map((e) => e.referenceID);
  }

  function _AddAll() {
    OnAddSelectionRange(GetFilteredReferenceID());
  }
  function _RemoveAll() {
    OnRemoveSelectionRange(GetFilteredReferenceID());
  }

  return (
    <div className="col-12 mb-2">
      <div className="card">
        <div
          className="card-header clickme text-center card-header-primary bg-secondary"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="row">
            <div className="col-12">
              <span className="font-weight-bold float-start">
                <span>
                  <FontAwesomeIcon
                    icon={
                      service.bookingType === ServiceBookingTypeEnumModel.None
                        ? faConciergeBell
                        : faStar
                    }
                    title="Ancillary"
                  />
                </span>
                <span>
                  {" "}
                  {service.bookingCode.length > 0
                    ? service.bookingCode
                    : service.code}{" "}
                  <ServiceBookingTypeEnumDisplay type={service.bookingType} />
                </span>
              </span>
              <span>{service.text}</span>
              <span className="float-end">
                <FontAwesomeIcon
                  icon={open ? faAngleDoubleUp : faAngleDoubleDown}
                />
              </span>
            </div>
            {/* <div className="col-12">
                <span className="font-weight-bold float-start">
                    {PriceDisplay()}
                </span>


            </div> */}
          </div>
        </div>
        <Collapse isOpen={open}>
          <div className="card-body">
            <div className="row">
              <div className="col-12 mb-2 serviceData description">
                <p>
                  {service.serviceDescription.length > 0
                    ? service.serviceDescription
                    : service.text}
                </p>
                {service.refundable !== undefined && (
                  <p>Refundable: {service.refundable ? "Yes" : "No "}</p>
                )}
                {service.emdRequired !== undefined && (
                  <p>EMD required: {service.emdRequired ? "Yes" : "No "}</p>
                )}
                {service.lastEMDDate && (
                  <p>
                    Last EMD date:{" "}
                    <DateTimeDisplay date={service.lastEMDDate} />
                  </p>
                )}
                {service.serviceCarrier.length > 0 && (
                  <p>Service carrier: {service.serviceCarrier}</p>
                )}
              </div>
              <div className="col-12 mb-2">
                <div className="btn-group btn-group-sm" role="group">
                  <button className="btn btn-secondary" onClick={_AddAll}>
                    Select all
                  </button>
                  <button className="btn btn-secondary" onClick={_RemoveAll}>
                    Remove all
                  </button>
                </div>
              </div>
              {service.referenceIDs.map((ref, refIndex) => (
                <div key={"refIde_" + refIndex} className="col-12 mb-2">
                  <ServiceReferenceDisplay
                    OnToggleSelection={OnToggleSelection}
                    Passengers={Passengers}
                    Prices={Prices}
                    Reference={ref}
                    Segments={segments}
                    SelectedServices={SelectedServices}
                    Service={service}
                  />
                  {/* {ref.referenceID}
                                {ref.priceID.length !== 0 &&
                                    <input type="checkbox" checked={SelectedServices.findIndex(e => e === ref.referenceID) !== -1} onChange={() => { OnToggleSelection(ref.referenceID) }} />
                                }
                                <button className="btn btn-primary" onClick={() => { OnSubmitAdd(service, ref) }}>Passenger:{ref.passengerID} | Price: {ref.priceID} |  Segments: {ref.segmentIDs.join(', ')}</button> */}
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

interface ServiceReferenceDisplayProps {
  Service: BookingAvailableServiceModel;
  Reference: BookingAvailableServiceReferenceIDModel;
  Passengers: BookingItemServicePassengerModel[];
  Segments: PnrResponseSegmentModel[];
  Prices: ServicePriceModel[];
  SelectedServices: string[];
  OnToggleSelection: (selection: string) => void;
}
const ServiceReferenceDisplay: React.FC<ServiceReferenceDisplayProps> = ({
  Reference,
  Service,
  Passengers,
  Segments,
  SelectedServices,
  OnToggleSelection,
  Prices,
}) => {
  const passenger: BookingItemServicePassengerModel | undefined =
    Passengers.filter((e) => e.id === Reference.passengerID)[0];
  const price: ServicePriceModel | undefined = Prices.filter(
    (e) => e.priceID === Reference.priceID
  )[0];
  const segments: PnrResponseSegmentModel[] = Segments.filter((e) =>
    Reference.segmentIDs.includes(e.segmentNumber)
  );

  const [expand, setExpand] = useState(false);
  function GetSegmentShortDesc(): string {
    let result: string[] = [];
    segments.forEach((e) => {
      result.push(`${e.departure.iata} - ${e.arrival.iata}`);
    });
    return result.join(", ");
  }
  

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {price && (
            <Fragment>
              <div className="col-auto">
                <input
                  type="checkbox"
                  checked={
                    SelectedServices.findIndex(
                      (e) => e === Reference.referenceID
                    ) !== -1
                  }
                  onChange={() => {
                    OnToggleSelection(Reference.referenceID);
                  }}
                />
              </div>
              <div className="col-auto">
                <SharedPriceDisplay price={price.equivalentPrice} />
              </div>
            </Fragment>
          )}
          {!price && <div className="col-auto">(Included)</div>}
          <div className="col-auto">
            {passenger && (
              <Fragment>
                {passenger.typeAbbr} {passenger.lastname}, {passenger.firstname}
              </Fragment>
            )}
          </div>
          {segments.length > 0 && (
            <div className="col-auto">
              {segments.length.toString()}/{Segments.length.toString()} segments
              ({GetSegmentShortDesc()})
            </div>
          )}
          {/* <div className="col">
                    <div className="float-end mr-0">

                        <button className="btn btn-primary btn-sm" onClick={() => { setExpand(!expand) }}><span className=""><FontAwesomeIcon icon={expand ? faAngleDoubleUp : faAngleDoubleDown} /></span> details</button>
                    </div>
                </div> */}
          {/* <div className="col-12">

                    <Collapse isOpen={expand}>

                    </Collapse>
                </div> */}
        </div>
      </div>
    </div>
  );
};
