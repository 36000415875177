import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { FareHeaderView } from 'WebApiClient';
import IncServices, { GetIcon } from "components/Shared/IncServices"
import Tooltip from '@mui/material/Tooltip';
interface IncludedServicesProps {
    readonly Data: FareHeaderView;
    readonly FareIndex: number;
}

const FareHeaderIncludedServices: React.FC<IncludedServicesProps> = (props) => {

    // Extract unique hints
    const combinedHints = Array.from(
        new Map(
            props?.Data?.includedServices!
                .flatMap((item) => item.services!.map((service) => ({ hint: service.hint, subType: service.subType, type: service.type })))
                .map((obj) => [obj.hint, obj]) // Use the hint as the key
        ).values()
    );

    return (
        <React.Fragment>
            <div className="ancillaries align-bottom">
                {/* Free Baggage Indicator */}
                <FreeBaggeIndicator HasFreeBaggage={props.Data.fareHasFreeBaggage} />
                {props.Data.includedServices!.length > 0 && <span> | </span>}
                {/* Tooltip */}
                <Tooltip
                    title={
                        <React.Fragment>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                {combinedHints.length > 0 &&
                                    combinedHints.map((sub: any, subIndex: any) => (
                                        <li key={`mainSer_${subIndex}`} style={{ marginBottom: '5px' }}>
                                            <FontAwesomeIcon icon={GetIcon(sub)} /> {sub.hint}
                                        </li>
                                    ))}
                            </ul>
                        </React.Fragment>
                    }
                >
                    <span>
                        {props.Data.includedServices!.map((mainGroup, mainIndex) => (
                            <IncServices
                                key={`IncServices_${mainIndex}`}
                                data={mainGroup}
                                combinedHints={combinedHints}
                            />
                        ))}
                    </span>
                </Tooltip>
            </div>
        </React.Fragment>
    );
};

export default FareHeaderIncludedServices

interface FreeBaggeIndicatorProps {
    HasFreeBaggage: boolean;
}

const FreeBaggeIndicator: React.FC<FreeBaggeIndicatorProps> = ({ HasFreeBaggage }) => {
    const title = HasFreeBaggage ? "This fare has free baggage allowance for at least one connection" : "This fare has no free baggage allowance."
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);
    return (
        <React.Fragment>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>
                    {title}
                </PopoverBody>
            </Popover>
            <span
                className="fa-layers fa-fw "
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => { e.stopPropagation(); setOpen(true) }}
                onTouchEnd={() => setOpen(false)}
                ref={elemRef} >
                <FontAwesomeIcon style={{ opacity: HasFreeBaggage ? 1 : 0.5 }} title={title} icon={faLuggageCart} />
                <FontAwesomeIcon
                    style={{ "--fa-secondary-opacity": "0.7", "--fa-primary-color": "black", "color": `${HasFreeBaggage ? "green" : "red"}` } as CSSProperties}
                    icon={HasFreeBaggage ? faCheckCircle : faExclamationTriangle}
                    // color={HasFreeBaggage ? "green" : "red"}
                    transform="shrink-7 down-6 right-5" />
            </span>
        </React.Fragment>
    );
};

