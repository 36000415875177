import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FareAPISRequirements, FareBookingPassengerView } from "WebApiClient";
import { BookingPassenger } from "./FareBooking.Passengers.Passenger";
import { BookingValidResult } from "./FareBooking.Validate";
import PaymentDataTab from "./FareBooking.Passengers.Passenger.Payment";

interface OwnProps {
  Passengers: FareBookingPassengerView[];
  Apisrequirements: FareAPISRequirements;
  OnPaymentDetails: (data: any) => void;
  ValidationResult: BookingValidResult;
  OnPassengerUpdate: (index: number, data: FareBookingPassengerView) => void;
  Icon: JSX.Element;
  setPaymentBOFDetails: any;
  paymentBOFDetails: any;
  setPaymentDataBackend: any;
  paymentValid: any;
}
const FareBookingPassengers: React.FC<OwnProps> = (props) => {
  const [allPassengerTabsOpen, setAllPassengerTabsOpen] = useState(false);

  const [passengerTabsOpen, setPassengerTabsOpen] = useState(
    GetDefaultPassengerTabsOpenState(props.Passengers)
  );
  const [PaymentTabOpen, setPaymentTabOpen] = useState(true);

  function TogglePassengerTab(index: number) {
    let _passengerTabs = [...passengerTabsOpen];
    _passengerTabs[index] = !_passengerTabs[index];
    setPassengerTabsOpen(_passengerTabs);
  }
  function SelectNextPassengerTab(index: number) {
    let _passengerTabs = [...passengerTabsOpen];
    _passengerTabs[index] = false;
    if (_passengerTabs[index + 1] !== null) {
      _passengerTabs[index + 1] = true;
    }
    setPassengerTabsOpen(_passengerTabs);
  }

  useEffect(() => {
    ToggleAllPassengerTabs();
  }, []);

  function ToggleAllPassengerTabs() {
    const invertedSetAll = !allPassengerTabsOpen;
    let newOpenState: boolean[] = [];
    passengerTabsOpen.forEach(() => {
      newOpenState.push(invertedSetAll);
    });

    setAllPassengerTabsOpen(invertedSetAll);
    setPassengerTabsOpen(newOpenState);
  }

  function HasNextPassenger(index: number): boolean {
    if (passengerTabsOpen[index + 1] !== null) return true;
    else return false;
  }

  function GetDefaultPassengerTabsOpenState(
    passengers: FareBookingPassengerView[] | undefined
  ): boolean[] {
    let result: boolean[] = [];
    if (passengers) {
      passengers.forEach((_passenger, index) => {
        if (index === 0) {
          result.push(true);
        } else {
          result.push(false);
        }
      });
    }
    return result;
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-header card-header-primary">
              <span className="float-start">
                {props.Icon}
                {" CUSTOMER DETAILS"}
              </span>
            </div>
            <div className="card-body card-body-primary">
              {props.Passengers && (
                <React.Fragment>
                  <div className="row mb-2">
                    <div className="col-12">
                      <span className="float-end">
                        <button
                          onClick={() => ToggleAllPassengerTabs()}
                          type="button"
                          className="btn btn-sm btn-noborder"
                          title="Show/Hide all"
                        >
                          <span className="text-muted">
                            {!allPassengerTabsOpen ? "Show" : "Hide"} all
                          </span>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon
                            icon={allPassengerTabsOpen ? faEyeSlash : faEye}
                          />
                        </button>
                      </span>
                    </div>
                  </div>

                  <PaymentDataTab
                    setPaymentBOFDetails={props.setPaymentBOFDetails}
                    IsOpen={PaymentTabOpen}
                    Toggle={() => setPaymentTabOpen(!PaymentTabOpen)}
                    paymentBOFDetails={props.paymentBOFDetails}
                    setPaymentDataBackend={props.setPaymentDataBackend}
                    paymentValid={props.paymentValid.general}
                    paymentDataIndex={0}
                    isPerPerson={null}
                    PassengersAmount={props.Passengers.length}
                  />

                  <div className="row">
                    <div className="col-12 mb-2">
                      {props.Passengers.map((passenger, index) => (
                        <BookingPassenger
                          setPaymentBOFDetails={props.setPaymentBOFDetails}
                          paymentBOFDetails={props.paymentBOFDetails}
                          Passenger={passenger}
                          key={`booking_p_${index}`}
                          OnUpdatePassenger={props.OnPassengerUpdate}
                          // OnUseAdressForAll={props.OnUseAdressForAll}
                          Index={index}
                          Apisrequirements={props.Apisrequirements}
                          // CanInputAdress={PassengerCanInputAdress(index, props.Fare.bookingPassengers!)}
                          PassengersAmount={props.Passengers.length}
                          Valid={props.ValidationResult.Passengers[index]}
                          IsOpen={passengerTabsOpen[index]}
                          Toggle={TogglePassengerTab}
                          HasNext={HasNextPassenger(index)}
                          ExpandNext={SelectNextPassengerTab}
                          setPaymentDataBackend={props.setPaymentDataBackend}
                          paymentValid={props.paymentValid}
                        />
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FareBookingPassengers;
