import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { AvailableFareConnectionModel, AvailableFareModel, FareBookingPassengerView } from "WebApiClient";
import { AncillariesModal, ServiceAddObj } from "./AncillariesModal";
import { CalculationDebugModal } from "./CalculationDebugModal";
import FareButtons from "./Fare.Buttons";
import FareCalculation from "./Fare.Calculation";
import FareHeader from "./Fare.Header";
import FareInfoBox from "./Fare.InfoBox";
import FareLeg from "./Fare.Leg";
import FarePriceDetailsModal from "./Fare.PriceDetailsModal";
import { FareFilterApplicability } from "./FareFilter.Applicability";
import FareRuleModal from "./FareRulesModal";
import { FlightDetailsModal } from "./FlightDetailsModal";
import _ from "lodash";
import { AvailableFaresFare } from "components/AvailableFares/component/AvailableFares.Fares";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface IFareProps {
  ShowAllLegsActive: boolean;
  ShowAllFlightTimesActive: boolean;
  ShowAllCalculationActive: boolean;
  FareData: AvailableFareModel;

  //available fares display properties
  Applicability?: FareFilterApplicability;
  IsFareBooking: boolean;
  BookFare?: (payload: SetBookingPayload) => void;

  // fare booking properties
  SelectedConnections?: number[];
  SelectConnection?: (legIndex: number, ConnectionIndex: number) => void;
  AddedServices?: ServiceAddObj[];
  SetAddedServices?(services: ServiceAddObj[]): void;
  BookPassengers?: AssignablePassengerOption[];
  ShowCalcDebugger: boolean;
  myRefs?: any;
  key?: any;
  isActivate?: boolean;
  TotalFare: AvailableFaresFare[];
  fareLegBoolArray:any;
  setFareLegBoolArray:any;
  isScrollF:string;
};
const Fare: React.FC<IFareProps> = (props) => {
  const OriginalFareIndex: number = props.FareData.fareIndex;
  const [flightDetailsModalOpen, setFlightDetailsModalOpen] = useState(false);
  const [renderError, setRenderError] = useState(false);
  const [Status, setStatus] = useState(false);

  //upsell flight number storer
  const [searchFlightNumber, setSearchFlightNumber] = useState<string[][]>([])

  function ToggleflightDetailsModal() {
    setFlightDetailsModalOpen(!flightDetailsModalOpen);
  }
  const [calculationDebugModalOpen, setCalculationDebugOpen] = useState(false);
  function ToggleCalculationDebugModal() {
    setCalculationDebugOpen(!calculationDebugModalOpen);
  }

  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  function ToggleRulesModal() {
    setRulesModalOpen(!rulesModalOpen);
  }

  const [AncillariesModalOpen, setAncillariesModalOpen] = useState(false);
  function ToggleAncillariesModal() {
    setAncillariesModalOpen(!AncillariesModalOpen);
  }

  const [PriceDetailsModalOpen, setPriceDetailsOpen] = useState(false);
  function TogglePriceDetailsModal() {
    setPriceDetailsOpen(!PriceDetailsModalOpen);
  }
  const PlatingCarriers = props.FareData.legs.map((e) => e.platingCarrier!);
  const DistinctPlatingCarriers = GetDistinctPlatingCarriers();
  const [showLegs, setShowLegs] = useState(false);
  const [showFlightTimes, setShowFlightTimes] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const ShowFare = props.Applicability ? props.Applicability.Applicable : true;
  const [internalSelectedConnections, setSelectedConnection] = useState<
    number[]
  >([]);
  const [internaladdedServices, setInternalAddedServices] = useState<
    ServiceAddObj[]
  >([]);

  const _addedServices =
    props.AddedServices !== undefined
      ? props.AddedServices
      : internaladdedServices;

  const _setAddedServices =
    props.SetAddedServices !== undefined
      ? props.SetAddedServices
      : (services: ServiceAddObj[]) => {
        setInternalAddedServices(services);
      };

  useEffect(() => {
    // console.log('check triggered',internalSelectedConnections)
    try {
      if (internalSelectedConnections.length === 0) {
        let initiSelection: number[] = [];
        props.FareData.legs.forEach(() => {
          initiSelection.push(0);
        });
        setSelectedConnection(initiSelection);
      }

      internalSelectedConnections.forEach((val, index) => {
        if (
          props.Applicability !== undefined &&
          props.Applicability.ApplicableConnections !== undefined
        ) {
          if (props.Applicability.ApplicableConnections[index]) {
            // console.log('app1- ',props.Applicability.ApplicableConnections[index],!props.Applicability.ApplicableConnections[index].includes(val))
            if (
              !props.Applicability.ApplicableConnections[index].includes(val)
            ) {
              SetSelectedConnection(
                index,
                props.Applicability.ApplicableConnections[index][0]
              );
            }
          }
        }
      });
    } catch {
      console.error(
        `An error occured in fare ${props.FareData.identifier.fareIndex} during connection selection in the useEffect hook.`
      );
      setRenderError(true);
    }
  }, [
    internalSelectedConnections,
    props.Applicability,
    props.FareData.legs,
    SetSelectedConnection,
  ]);

  //admin collapse apply
  useEffect(() => {
    setShowLegs(props.ShowAllLegsActive);
  }, [props.ShowAllLegsActive]);

  // Effect to expand / collapse flight times if expanded externally
  useEffect(() => {
    setShowFlightTimes(props.ShowAllFlightTimesActive);
  }, [props.ShowAllFlightTimesActive]);

  // Effect to expand / collapse calculation if expanded externally
  useEffect(() => {
    setShowCalculation(props.ShowAllCalculationActive);
  }, [props.ShowAllCalculationActive]);



  function GetDistinctPlatingCarriers() {
    const result = [];
    const map = new Map();
    for (const item of PlatingCarriers) {
      if (!map.has(item.code!)) {
        map.set(item.code!, true); // set any value to Map
        result.push(item);
      }
    }
    return result;
  }

  function SetSelectedConnection(legindex: number, connectionIndex: number) {
    if (_addedServices.length > 0) {
      _setAddedServices([]);
    }
    if (props.SelectedConnections === undefined) {
      let selectedConns = [...internalSelectedConnections];
      selectedConns[legindex] = connectionIndex;
      setSelectedConnection(selectedConns);
    } else {
      if (props.SelectConnection) {
        props.SelectConnection(legindex, connectionIndex);
      }
    }
  }

  function ToggleLegs(fareIndexSingle:number,legBoolString:string) {
    if (Array.isArray(props.fareLegBoolArray) && props.fareLegBoolArray.length > 0) {
      const updatedFareLegBoolArray = props.fareLegBoolArray.map(
        (item: { fareIndex: number; fareLeg: boolean }, index: any) => 
          index === fareIndexSingle
            ? { ...item, fareLeg: legBoolString == "showLeg" ? !item.fareLeg : true }
            : item
      );
      props.setFareLegBoolArray(updatedFareLegBoolArray);
    }
    if (props.fareLegBoolArray.length == 0){
      setShowLegs(!showLegs)
    }
  }

  function ToggleFlightTimes() {
    setShowFlightTimes(!showFlightTimes);
  }
  function ToggleCalculation() {
    setShowCalculation(!showCalculation);
  }

  function BookFare() {
    if (props.BookFare && !props.IsFareBooking) {
      const payload: SetBookingPayload = {
        Fare: props.FareData,
        SelectedConnections: internalSelectedConnections,
        AddedServices: _addedServices,
        OnSelectConnection: SetSelectedConnection,
      };
      props.BookFare(payload);
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the screen size (e.g., above 1600px)
      breakpoint: { max: 4000, min: 1600 },
      // number of items to show
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 4, // show 4 items for desktop
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2, // show 2 items for tablet
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // show 1 item for mobile
    },
  };


  //calculating connection index for selected upsell
  const findConnectionIndices = (fare: AvailableFareModel, flightNumbers: string[][]) => {
    return flightNumbers.map(legFlightNumbers => {
      // Initialize to -1; if a matching connection is found, we'll update it
      let foundIndex: any = -1;

      // Iterate over each leg to look for a matching connection
      for (const leg of fare.legs) {
        for (const connection of leg.connections) {
          // Check if this connection contains all flight numbers in the current group
          const allMatch = legFlightNumbers.every(flightNumber =>
            connection.segments.some(segment => segment.flightNumber === flightNumber)
          );

          // If a full match is found, set the connectionIndex and break out of the loop
          if (allMatch) {
            foundIndex = connection?.connectionIdentifier?.connectionIndex;
            break; // Stop further checking within this leg if a match is found
          }
        }

        if (foundIndex !== -1) break; // Stop further legs if a match was found
      }

      // Return the connectionIndex if found, or -1 if no full match was found for this group
      return foundIndex;
    });
  };

  function scrollToBook(fareIndex: number) {
    props.myRefs.current[fareIndex]?.scrollIntoView({ behavior: 'smooth' });
    ToggleLegs(fareIndex,"scrollLeg");
  }

  function GetSelectedConnectionIndexByLegIndex(legIndex: number): number {
    if (props.SelectedConnections === undefined) {
      return internalSelectedConnections[legIndex];
    } else {
      return props.SelectedConnections[legIndex];
    }
  }

  function GetSelectedConnectionIndexByLegIndexUpsell(faredata: any, legIndex: number): number {
    if (props.SelectedConnections === undefined) {
      return findConnectionIndices(faredata.Fare, searchFlightNumber)[legIndex];
    } else {
      return props.SelectedConnections[legIndex];
    }
  }

  const SelectedLegConnections =
    props.SelectedConnections !== undefined
      ? props.SelectedConnections
      : internalSelectedConnections;

  function GetAssignablePassengers(
    p: FareBookingPassengerView[]
  ): AssignablePassengerOption[] {
    let result: AssignablePassengerOption[] = [];
    p.forEach((element, index) => {
      const pData = element.bookingData;
      const useCustomLabel =
        (pData.firstName !== undefined && pData.firstName.length > 0) ||
        (pData.lastName !== undefined && pData.lastName.length > 0);
      const label = useCustomLabel
        ? `${pData.title ? pData.title : ""} ${pData.firstName ? pData.firstName : ""
        } ${pData.lastName ? pData.lastName : ""} (${pData.passengerType})`
        : `${pData.passengerType} ${index + 1}`;
      const option: AssignablePassengerOption = {
        Index: index,
        Label: label,
        PassengerType: pData.passengerType,
      };
      result.push(option);
    });
    return result;
  }
  const AssignablePassengers = props.BookPassengers
    ? props.BookPassengers
    : GetAssignablePassengers(props.FareData.bookingPassengers!);
  //upscale calculation
  const [upscaleFilterData, setUpscaleFilterData] = useState<AvailableFaresFare[]>([])
  const [upSaleToggle, setupSaleToggle] = useState(false)

  const UpscaleCalculation = (upscalePropertyArray: any, propertyString: string) => {
    const filterObjs = upscalePropertyArray;
    // Extract flight numbers grouped by leg number as a nested array
    const groupedFlightNumbers: any = Object.values(
      filterObjs.reduce((acc: any, filterObj: any) => {
        filterObj.codeNumber.forEach((codeEntry: any) => {
          const { legNumber, flightNumber } = codeEntry;

          if (!acc[legNumber]) {
            acc[legNumber] = [];
          }
          acc[legNumber].push(flightNumber);
        });
        return acc;
      }, {})
    );
    setSearchFlightNumber(groupedFlightNumbers);
    const newArr = props.TotalFare;
    const filteredData: AvailableFaresFare[] = newArr.filter(item =>
      item.Fare.legs.length === filterObjs.length &&
      item.Fare.fareIndex !== filterObjs[0].fareIndex &&

      Object.entries(groupedFlightNumbers).every(([legIndex, flightNumbers]: [any, any]) => {
        const leg = item.Fare.legs[Number(legIndex)];

        if (!leg) return false;

        // Check if all flight numbers for the leg are present in segments
        return flightNumbers.every((flightNumber: any) =>
          leg.connections.some(connection =>
            connection.segments.some(segment => segment.flightNumber === flightNumber)
          )
        );
      })
    );

    if (propertyString != "connectionTriggeredLeg") {
      if (filteredData?.length === 0) {
        toast.error("No upsell found", { autoClose: 1500 });
        if (propertyString == 'connectionTriggered') {
          setupSaleToggle(true);
        } else {
          setupSaleToggle(false);
        }
      } else {
        if (!showFlightTimes) {
          ToggleFlightTimes();
        }
        if (propertyString == 'connectionTriggered') {
          setupSaleToggle(true);
        } else {
          setupSaleToggle(prev => !prev);
        }
      }
    }
    if (propertyString == "connectionTriggeredLeg") {
      if (!showFlightTimes) {
        ToggleFlightTimes();
      }
    }

    // filtering if relevant con is not find
    const newFArr: any = filteredData?.filter((singleUpscaleFare: any) =>
      singleUpscaleFare?.Fare?.legs.some((leg: any) => {
        const con: AvailableFareConnectionModel = _.find(leg.connections, {
          index: findConnectionIndices(singleUpscaleFare.Fare, groupedFlightNumbers)[leg.index],
        });
        return con !== undefined;
      })
    );

    setUpscaleFilterData(newFArr);
  };


  return (
    <React.Fragment>
      <div className={`row singlefare ${!ShowFare ? "d-none" : ""}`}>
        {!renderError && (
          <Fragment>
            <div className={`col-12 col-md-9 p-0 ${props.FareData.fareIndex}`}>
              <div
                className={`fare__single_fare mb-0 mb-md-2 ${props.isScrollF == "isScrollState" ? props?.fareLegBoolArray[props?.FareData?.fareIndex]?.fareLeg : showLegs ? "boxShadow-light mb-md-3" : ""
                  }`}
              >
                {/* Logo / Text / Price */}
                <FareHeader
                  ToggleFare={ToggleLegs}
                  OriginalFareIndex={OriginalFareIndex}
                  Data={props.FareData.fareHeader!}
                  PlatingCarriers={DistinctPlatingCarriers}
                  IsExpanded={props.isScrollF == "isScrollState" ? props?.fareLegBoolArray[props?.FareData?.fareIndex]?.fareLeg : showLegs}
                  TotalPrice={props.FareData.totalPrice}
                  TotalTax={props.FareData.totalTax}
                  Currency={props.FareData.currency!}
                  Services={_addedServices}
                  myRefs={props.myRefs!}
                  FareIndex={props.FareData.fareIndex!}
                  fareData={props.FareData}
                />
                {(props.isScrollF == "isScrollState" ? props?.fareLegBoolArray[props?.FareData?.fareIndex]?.fareLeg : showLegs) && (
                  <React.Fragment>
                    {props.FareData.legs.map(
                      (leg, index) =>
                        internalSelectedConnections[index] !== undefined && (
                          <FareLeg
                            LegIndex={leg.index}
                            SelectedConnection={GetSelectedConnectionIndexByLegIndex(
                              leg.index
                            )}
                            GetSelectedConnectionIndexByLegIndex={GetSelectedConnectionIndexByLegIndex!}
                            Data={leg}
                            isActivate={props.isActivate}
                            key={"FareLeg" + index}
                            ShowFlightTimes={showFlightTimes}
                            SelectConnection={SetSelectedConnection}
                            ShowLegPlatingCarrier={
                              DistinctPlatingCarriers.length > 1
                            }
                            ApplicableConnections={
                              props.Applicability &&
                                props.Applicability.ApplicableConnections
                                ? props.Applicability.ApplicableConnections[
                                leg.index
                                ]
                                : undefined
                            }
                            Fare={props.FareData}
                            ToggleFlightTimes={ToggleFlightTimes}
                            setStatus={setStatus}
                            headerOffVisible={true}
                            upscaleLength={1000}
                          />
                        )
                    )}
                    <FareCalculation
                      OriginalFareIndex={OriginalFareIndex}
                      Show={showCalculation}
                      Data={props.FareData.calculationResult!}
                      Currency={props.FareData.currency!}
                      AddedServices={_addedServices}
                      AssignalbePassengers={AssignablePassengers}
                    />
                    <FareButtons
                      ToggleFlightTimes={ToggleFlightTimes}
                      ToggleCalculation={ToggleCalculation}
                      ToggleRulesModal={ToggleRulesModal}
                      ToggleAncillariesModal={ToggleAncillariesModal}
                      ToggleFlightDetailsModal={ToggleflightDetailsModal}
                      BookFare={BookFare}
                      ToggleCalculationDebug={ToggleCalculationDebugModal}
                      ShowCalcDebug={props.ShowCalcDebugger}
                      SelectedConnections={internalSelectedConnections}
                      IsFareBooking={props.IsFareBooking}
                      Identifier={props.FareData.identifier!}
                      Fare={props.FareData}
                      UpscaleCalculation={UpscaleCalculation}
                      upSaleToggle={upSaleToggle}
                      showLegs={props.isScrollF == "isScrollState" ? props?.fareLegBoolArray[props?.FareData?.fareIndex]?.fareLeg: showLegs}
                      upscaleFilterData={upscaleFilterData}
                      setupSaleToggle={setupSaleToggle}
                    />
                    {
                      (upSaleToggle && upscaleFilterData?.length) ? (
                        <div className="upSellCardParent">
                          <Carousel responsive={responsive}
                            slidesToSlide={5}
                            swipeable={true}
                            className="carouselUpsell">
                            {upscaleFilterData?.map((singleUpscaleFare: any, fareIndex: number) =>
                              singleUpscaleFare?.Fare?.legs.map((leg: any, index: number) =>
                                internalSelectedConnections[index] !== undefined && (
                                  <div className="upSellContainer" onClick={() => scrollToBook(singleUpscaleFare.Fare.fareIndex)}>
                                    <FareLeg
                                      LegIndex={leg.index}
                                      SelectedConnection={GetSelectedConnectionIndexByLegIndexUpsell(singleUpscaleFare, leg.index)}
                                      GetSelectedConnectionIndexByLegIndex={GetSelectedConnectionIndexByLegIndex!}
                                      Data={leg}
                                      isActivate={props.isActivate}
                                      key={`FareLeg${fareIndex}-${index}`}
                                      ShowFlightTimes={showFlightTimes}
                                      SelectConnection={SetSelectedConnection}
                                      ShowLegPlatingCarrier={DistinctPlatingCarriers.length > 1}
                                      ApplicableConnections={props.Applicability?.ApplicableConnections?.[leg.index]}
                                      Fare={singleUpscaleFare?.Fare}
                                      ToggleFlightTimes={ToggleFlightTimes}
                                      setStatus={setStatus}
                                      headerOffVisible={false}
                                      upscaleLength={upscaleFilterData?.length}
                                    />
                                  </div>
                                )
                              )
                            )}
                          </Carousel>
                        </div>
                      ) : null
                    }

                  </React.Fragment>
                )}
              </div>
            </div>
            <FareInfoBox
              Info={props.FareData.fareInfoBox!}
              ShowDetails={props.isScrollF == "isScrollState" ? props?.fareLegBoolArray[props?.FareData?.fareIndex]?.fareLeg : showLegs}
              ToggleFare={ToggleLegs}
              FareIndex={props.FareData.fareIndex}
              SelectedConnections={SelectedLegConnections}
              Legs={props.FareData.legs}
              Status={Status}
            />
            <FareRuleModal
              IsOpen={rulesModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleRulesModal}
            />
            <FlightDetailsModal
              IsOpen={flightDetailsModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleflightDetailsModal}
              testConnection={GetSelectedConnectionIndexByLegIndex}
              GetSelectedConnectionIndexByLegIndex={
                GetSelectedConnectionIndexByLegIndex
              }
              setStatus={setStatus}
            />
            <CalculationDebugModal
              IsOpen={calculationDebugModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleCalculationDebugModal}
              testConnection={GetSelectedConnectionIndexByLegIndex}
              GetSelectedConnectionIndexByLegIndex={
                GetSelectedConnectionIndexByLegIndex
              }
              setStatus={setStatus}
            />
            <AncillariesModal
              IsOpen={AncillariesModalOpen}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleAncillariesModal}
              AddedServices={_addedServices}
              SetAddedServices={_setAddedServices}
              Passengers={AssignablePassengers}
            />
            <FarePriceDetailsModal
              IsOpen={PriceDetailsModalOpen}
              Toggle={TogglePriceDetailsModal}
            />
          </Fragment>
        )}
        {renderError && (
          <div className="col-12">
            <Alert color="danger">
              An error occured. This fare cannot be displayed.
            </Alert>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Fare;