import { faLock, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvariantDateDisplay } from "components/Shared/DateDisplay";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { BookingMangerFilterRequestData, WebAccessEnum } from "WebApiClient";

interface LastSentInfoProps {
  LastSentFilter: BookingMangerFilterRequestData;
  SetFilterOpen: (open: boolean) => void;
  OnSubmit: (data: BookingMangerFilterRequestData) => void
}

const RemoveFilterIcon: React.FC<{ OnClick: () => void }> = (props) => {
  return <span onClick={props.OnClick} className="clickme">{" "}<FontAwesomeIcon icon={faTimesCircle} color="red" />{` `}</span>
}

const LockedFilterIcon: React.FC<{}> = (props) => {
  return <span>{" "}<FontAwesomeIcon title="Restricted by Administator" icon={faLock} /></span>
}

const LastSentInfo: React.FC<LastSentInfoProps> = ({ LastSentFilter, OnSubmit }) => {
  const Data = LastSentFilter;

  function UpdateFilter(filter: BookingMangerFilterRequestData) {
    OnSubmit(filter);
  }

  const bookingswebaccess = useSelector((state: State) => state.Session.FrontendSettings.bookingManagerWebAccess);
  const branchgroupSelectionLocked = bookingswebaccess !== WebAccessEnum.Web;
  const branchSelectionLocked = !(bookingswebaccess === WebAccessEnum.Branchgroup || bookingswebaccess === WebAccessEnum.Web)
  const userSelectionLocked = bookingswebaccess === WebAccessEnum.User;



  return (
    <div className="row mb-2">
      <div className="col">
        {/* <button className="btn btn-primary me-1" onClick={() => SetFilterOpen(true)}> <FontAwesomeIcon icon={faSlidersH} rotation={90} /> Filter </button> */}
        <span className="font-italic">
          {(Data.branchGroup && Data.branchGroup.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Branchgroup: </b> {Data.branchGroup}
              {branchgroupSelectionLocked ?
                <LockedFilterIcon /> :
                <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, branchGroup: "" }) }} />
              }
            </span>
          }
          {(Data.branch && Data.branch.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Branch: </b> {Data.branch}
              {branchSelectionLocked ?
                <LockedFilterIcon /> :
                <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, branch: "" }) }} />
              }
            </span>
          }
          {(Data.responsibleAgent && Data.responsibleAgent.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Resp. Agent: </b> {Data.responsibleAgent}
              {userSelectionLocked ?
                <LockedFilterIcon /> :
                <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, responsibleAgent: "" }) }} />
              }
            </span>
          }
          {(Data.membershipMembers && Data.membershipMembers.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Member: </b> {Data.membershipMembers}
              {userSelectionLocked ?
                <LockedFilterIcon /> :
                <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, membershipMembers: "" }) }} />
              }
            </span>
          }
          {(Data.freeField && Data.freeField.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Freefield: </b> {Data.pcc} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, freeField: "" }) }} /></span>
          }
          {(Data.pcc && Data.pcc.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">PCC: </b> {Data.pcc} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, pcc: "" }) }} /></span>
          }
          {(Data.recordLocator && Data.recordLocator.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Filekey: </b> {Data.recordLocator} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, recordLocator: "" }) }} /></span>
          }
          {(Data.airlineCodes && Data.airlineCodes.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Airline codes: </b> {Data.airlineCodes} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, airlineCodes: "" }) }} /></span>
          }
          {(Data.bookingDateFrom) &&
            <span className="border p-1"><b className="font-weight-bold">Booking date from: </b> <InvariantDateDisplay date={Data.bookingDateFrom} />  <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, bookingDateFrom: undefined }) }} /></span>
          }
          {(Data.bookingDateTo) &&
            <span className="border p-1"><b className="font-weight-bold">Booking date to: </b> <InvariantDateDisplay date={Data.bookingDateTo} />  <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, bookingDateTo: undefined }) }} /></span>
          }
          {(Data.firstDepartureDate) &&
            <span className="border p-1"><b className="font-weight-bold">Departure date from: </b>  <InvariantDateDisplay date={Data.firstDepartureDate} />  <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, firstDepartureDate: undefined }) }} /></span>
          }
          {(Data.firstDepartureDateTo) &&
            <span className="border p-1"><b className="font-weight-bold">Departure date to: </b> <InvariantDateDisplay date={Data.firstDepartureDateTo} /> <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, firstDepartureDateTo: undefined }) }} /></span>
          }
          {(Data.lastArrvialDate) &&
            <span className="border p-1"><b className="font-weight-bold">Arrival date from: </b> <InvariantDateDisplay date={Data.lastArrvialDate} /> <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, lastArrvialDate: undefined }) }} /></span>
          }
          {(Data.lastArrvialDateTo) &&
            <span className="border p-1"><b className="font-weight-bold">Arrival date to: </b> <InvariantDateDisplay date={Data.lastArrvialDateTo} /> <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, lastArrvialDateTo: undefined }) }} /></span>
          }
          {(Data.gds && Data.gds.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">GDS: </b> {Data.gds} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, gds: "" }) }} /></span>
          }
          {(Data.mainTravellerLastName && Data.mainTravellerLastName.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Main traveller last name: </b> {Data.mainTravellerLastName} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, mainTravellerLastName: "" }) }} /></span>
          }
          {(Data.origin && Data.origin.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Origin </b> {Data.origin} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, origin: "" }) }} /></span>
          }
          {(Data.destination && Data.destination.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Destination: </b> {Data.destination} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, destination: "" }) }} /></span>
          }
          {(Data.statusCode && Data.statusCode.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Status code: </b> {Data.statusCode} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, statusCode: "" }) }} /></span>
          }
          {(Data.ticketTimeLimitFrom) &&
            <span className="border p-1"><b className="font-weight-bold">TTL from: </b> <InvariantDateDisplay date={Data.ticketTimeLimitFrom} /> <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, ticketTimeLimitFrom: undefined }) }} /></span>
          }
          {(Data.ticketTimeLimitTo) &&
            <span className="border p-1"><b className="font-weight-bold">TTL to: </b> <InvariantDateDisplay date={Data.ticketTimeLimitTo} /> <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, ticketTimeLimitTo: undefined }) }} /></span>
          }
          {(Data.ticketingDateFrom) &&
            <span className="border p-1"><b className="font-weight-bold">Ticketing date from: </b> <InvariantDateDisplay date={Data.ticketingDateFrom} />  <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, ticketingDateFrom: undefined }) }} /></span>
          }
          {(Data.ticketingDateTo) &&
            <span className="border p-1"><b className="font-weight-bold">Ticketing date to: </b> <InvariantDateDisplay date={Data.ticketingDateTo} />  <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, ticketingDateTo: undefined }) }} /></span>
          }
          {(Data.transactionID && Data.transactionID.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Transaction ID: </b> {Data.transactionID} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, transactionID: "" }) }} /></span>
          }
          {(Data.shoppingCartID && Data.shoppingCartID.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Shopping cart ID: </b> {Data.shoppingCartID} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, shoppingCartID: "" }) }} /></span>
          }
          {(Data.bookingItemID && Data.bookingItemID.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Booking item ID: </b> {Data.bookingItemID} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, bookingItemID: "" }) }} /></span>
          }
          {(Data.externalReference && Data.externalReference.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">External reference: </b> {Data.externalReference} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, externalReference: "" }) }} /></span>
          }
          {(Data.distributionChannel && Data.distributionChannel.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Distribution channel: </b> {Data.distributionChannel} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, distributionChannel: "" }) }} /></span>
          }
          {(Data.web && Data.web.length > 0) &&
            <span className="border p-1"><b className="font-weight-bold">Web: </b> {Data.web} <RemoveFilterIcon OnClick={() => { UpdateFilter({ ...Data, web: "" }) }} /></span>
          }
        </span>
      </div>
    </div>
  );
}

export default LastSentInfo;